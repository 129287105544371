import Swal from "sweetalert2";
import React from "react";
import { createRoot } from "react-dom/client";
import CustomButton from "../CustomButton";
import "../custom-swal.css";

// Componente SweetAlert para guardar cambios
const SweetAlertSave = {
  confirmSave: async () => {
    const result = await Swal.fire({
      title: "¿Deseas guardar los cambios?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Guardar",
      denyButtonText: "No guardar",
      customClass: {
        container: "custom-swal-container",
        confirmButton: "custom-swal-button", // clase personalizada
        denyButton: "custom-swal-button", // clase personalizada
        cancelButton: "custom-swal-button", // clase personalizada
      },
      buttonsStyling: false,
      // Añadir el siguiente estilo para centrar
      backdrop: `
        rgba(0, 0, 0, 0.7)
      `,
      didRender: () => {
        // Renderizar botones de Material-UI
        createRoot(document.querySelector(".swal2-confirm")).render(
          <CustomButton variant="contained" color="success">Guardar</CustomButton>
        );
        createRoot(document.querySelector(".swal2-deny")).render(
          <CustomButton variant="contained" color="warning">No guardar</CustomButton>
        );
        createRoot(document.querySelector(".swal2-cancel")).render(
          <CustomButton variant="contained" color="error">Cancelar</CustomButton>
        );
      },
      // Posición del cuadro de diálogo
      position: 'center',
    });
    
    return result;
  },

  confirmClose: async () => {
    const result = await Swal.fire({
      title: "Confirmación",
      text: "¿Estás seguro de que deseas cerrar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, cerrar",
      cancelButtonText: "No, seguir editando",
      customClass: {
        container: "custom-swal-container",
        confirmButton: "custom-swal-button",
        cancelButton: "custom-swal-button",
      },
      buttonsStyling: false,
      backdrop: `
        rgba(0, 0, 0, 0.7)
      `,
      position: 'center',
    });

    return result; 
  },

  showSuccess: () => {
    Swal.fire({
      icon: "success",
      title: "Registro guardado con éxito",
      showConfirmButton: false,
      timer: 2500
    });
  },

  showError: () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "¡Algo salió mal!",
    });
  },
};

export default SweetAlertSave;
