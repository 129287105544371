import axios from 'axios';

// Función para añadir promocion
export const getReporteMovimiento = async (token, uuid) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/movimientos/${uuid}`, {
          responseType: 'arraybuffer',
          headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/pdf",
          },
        });
        return response.data;
    } catch (error) {
        console.error('Error en Generación de Listas de Salida: ', error);
        throw error;
    }
};

