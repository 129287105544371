
import React, { useEffect, useState, useContext } from 'react';
import { findAll, remove } from './SedeService';
import KeycloakConf from '../../api/keycloakConf';
import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ToastAlertDelete from '../../components/Alert/ToastAlertDelete/ToastAlertDelete';
import ConfirmationDialog from '../../components/Alert/ConfirmationDialog';
import ButtonAdd from '../../components/Button/ButtonAdd';
import SedeTable from './Grid/SedeTable';
import RefreshButton from '../../components/Button/RefreshButton';

const Sede = () => {
  const [sedes, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const { getToken } = useContext(KeycloakConf);

  const navigate = useNavigate();
  const loadList = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const data = await findAll(token, page, rowsPerPage);
      setData(data.content);
      setPageable(data.page);
    } catch (error) {
      console.error('Error cargando la lista:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadList();
  }, [getToken, page, rowsPerPage]);


  const handleDelete = (id) => {
    setDialogMessage(`El registro seleccionado será permanentemente eliminado del sistema.`);
    setItemToDelete(id);
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const token = getToken();
    try {
      await remove(token, itemToDelete);
      setData(sedes.filter(sede => sede.id !== itemToDelete));
      setToastMessage('Registro eliminado con éxito');
      setToastType('success');
    } catch (error) {
      console.error('Error eliminando registro:', error);
      setToastMessage('El registro no fue eliminado');
      setToastType('error');
    }
    setToastOpen(true);
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleRefresh = () => {
    loadList();
  };

  const handleEdit = (item) => {
    navigate('/api/core/sedes/edit', { state: { item } });
  };

  return (
    <div>
      <Box mb={2}>
        <Link to="/api/core/sedes/add">
          <ButtonAdd />
        </Link>
        &nbsp;
        <RefreshButton onClick={handleRefresh} />
      </Box>
      <Box>
        {loading ? <p>Cargando...</p> : (
          <SedeTable
            sedes={sedes}
            onDelete={handleDelete}
            onEdit={handleEdit}
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={handleCloseToast}
        message={toastMessage}
        type={toastType}
      />
    </div>
  );
};

export default Sede;

