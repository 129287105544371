import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Acciones = ({ accion1, textoBoton1 = "Guardar", accion2, textoAccion2 = "Cerrar" }) => {
    const [fechaHoraActual, setFechaHoraActual] = useState({ fecha: '', hora: '' });
    
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            setFechaHoraActual({ fecha: now.toLocaleDateString(), hora: now.toLocaleTimeString() });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            {/* Sección de botones */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button type="submit" variant="contained"  className="button-save" onClick={accion1}>
                    <Typography variant="button">{textoBoton1}</Typography> 
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    className="button-close"
                    onClick={accion2}
                    style={{ marginLeft: '10px' }}
                >
                    <Typography variant="button">{textoAccion2}</Typography> 
                </Button>
            </Box>

            {/* Sección de fecha y hora en una sola línea */}
            <Typography variant="body1">
                Fecha: {fechaHoraActual.fecha} | Hora: {fechaHoraActual.hora}
            </Typography>
        </Box>
    );
};

export default Acciones;
