import axios from 'axios';

export const fetchTipoOficialia = async (token) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipooficialia`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    const data = response.data;
    if (Array.isArray(data)) {
      return data;
    } else if (data && data.content && Array.isArray(data.content)) {
      return data.content;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error al listar los tipos de oficialias', error);
    throw error;
  }
};