import { Box, Button, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { useState, useRef  } from "react";
import SweetAlertDelete from "../../../../components/Alert/SweetAlertDelete/SweetAlertDelete";
import { createDocument } from "./DigitalizacionService";
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Digitalizacion = ({ registro, token, onClose }) => { // Asegúrate de recibir las props correctamente
  const [formData, setFormData] = useState({
    id: registro?.id || '',
    folio: registro?.folio || '',
    expediente: registro?.expediente || '',
    tipo: registro?.tipoEntrada || '',
    file: "",
  });

  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
  const fileInputRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.file.name) {
      showSnackbar('Por favor, cargue un archivo PDF antes de guardar.', 'error');
      return;
    }

    const dataSend = new FormData();
    dataSend.append("file", formData.file);

    try {
      await createDocument(token, dataSend, formData.id);
      showSnackbar('Registro guardado con éxito', 'success');
      onClose(); // Cierra el componente al guardar
    } catch (error) {
      console.error("Error al guardar el documento:", error);
      showSnackbar('¡Algo salió mal!', 'error');
    }
  };

  const handleRemoveFile = (event) => {
    SweetAlertDelete.confirmDeletion().then((response) => {
      if (response) {
        setFormData((prevState) => ({
          ...prevState,
          file: "",
        }));
        if (fileInputRef.current) {
          fileInputRef.current.value = null; 
        }
        SweetAlertDelete.showSuccess();
      }
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > 50 * 1024 * 1024) {
      showSnackbar('El archivo debe ser menor a 50 MB', 'error');
      return;
    }
    if (file.type !== "application/pdf") {
      showSnackbar("Solo se permiten archivos PDF.", "error");
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      file: file,
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Button type="submit" variant="contained" className="button-save">
              Guardar
            </Button>
            <Button
              type="button"
              variant="contained"
              className="button-close"
              onClick={onClose} 
              style={{ marginLeft: "10px" }}
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </form>

      <Box sx={{ marginTop: 2 }}>
        <Divider />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">{`Folio: ${formData.folio}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">{`Expediente: ${formData.expediente}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">{`Tipo: ${formData.tipo}`}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 3 }}>
        <Divider />
      </Box>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          De clic en el botón "Cargar Archivo", posteriormente de clic en el botón de guardar.
        </Typography>

        <Box mt={2}>
          <Button variant="contained" component="label">
            Cargar Archivo
            <input type="file" accept="application/pdf" hidden onChange={handleFileChange} ref={fileInputRef} />
          </Button>
        </Box>

        {formData.file.name && (
          <Grid container spacing={3} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={6} md={2}>
              <Card sx={{ maxWidth: 245, margin: "0 auto", position: "relative" }}>
                <Typography
                  variant="body4"
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "red",
                    zIndex: 1,
                  }}
                  onClick={handleRemoveFile}
                >
                  X
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 100 }}>
                  <PictureAsPdfIcon sx={{ fontSize: 80, color: "black" }} />
                </Box>

                <CardContent>
                  <Typography variant="h6">{formData.file.name}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Digitalizacion;
