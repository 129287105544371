
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import keycloakConf from '../../api/keycloakConf';

const ProtectedRoute = ({ children, requiredRoles = [] }) => {
  const { authenticated, getToken } = useContext(keycloakConf);

  if (!authenticated) {
    return <Navigate to="/home" replace />;
  }

  const token = getToken();
  const hasRequiredRoles = requiredRoles.every(role => token?.hasRealmRole(role));

  if (!hasRequiredRoles) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

export default ProtectedRoute;

