import React, { useEffect, useState, useContext } from 'react';
import { Modal, Box, Typography, TextField, Select, MenuItem, Checkbox, FormControlLabel, Button, FormControl, FormHelperText } from "@mui/material";
import { getAllConceptos } from '../../shared/services/ConceptosService';
import { fetchPersonalTurnado } from '../../shared/services/PersonasService';
import { turnadoPersonalJuzgado } from '../../shared/services/DocumentoService';
import keycloakConf from '../../api/keycloakConf';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave'

const ModalTurnado = ({ open, onClose, data, handleRefresh }) => {
  const [selectedConcept, setSelectedConcept] = useState("");
  const [selectedPersonal, setSelectedPersonal] = useState("");
  const [specifyTime, setSpecifyTime] = useState(false);
  const [urgent, setUrgent] = useState("NORMAL");
  const [time, setTime] = useState('');
  const [conceptos, setConceptos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getToken } = useContext(keycloakConf);
  const [personalTurnadoOptions, setPersonalTurnadoOptions] = useState([]);
  const { showSnackbar, showConfirmDialog } = useToastAlert();

  const token = getToken();

  useEffect(() => {
    if (open) {
      const fetchConceptos = async () => {
        setLoading(true);
        try {
          const data = await getAllConceptos(token);
          setConceptos(data.content);
        } catch (err) {
          setError('Error al cargar conceptos');
        } finally {
          setLoading(false);
        }
      };

      const fetchPersonalTurnadoOptions = async () => {
        try {
          const data = await fetchPersonalTurnado(token, { page: 0, size: 20 });
          setPersonalTurnadoOptions(data.content);
        } catch (err) {
          setError('Error al cargar personal turnado');
        }
      };

      fetchConceptos();
      fetchPersonalTurnadoOptions();
    } else {
      setSelectedConcept("");
      setSelectedPersonal("");
      setTime('');
      setSpecifyTime(false);
      setUrgent("NORMAL");
    }
  }, [open, token]);

  const handleChangeConcept = (event) => {
    const selectedConceptId = event.target.value;
    setSelectedConcept(selectedConceptId);
    const selectedConceptData = conceptos.find(concepto => concepto.id === selectedConceptId);
    if (selectedConceptData) {
      setTime(selectedConceptData.dias);
    }
  };

  const handleSpecifyTimeChange = (checked) => {
    setSpecifyTime(checked);
    if (!checked) {
      const selectedConceptData = conceptos.find(concepto => concepto.id === selectedConcept);
      if (selectedConceptData) {
        setTime(selectedConceptData.dias);
      }
    } else {
      setTime('');
    }
  };

  const handleTimeChange = (e) => {
    const newValue = e.target.value;
    if (newValue >= 0 || newValue === '') {
      setTime(newValue);
    }
  };

  const handleUrgentChange = (e) => {
    setUrgent(e.target.checked ? "URGENTE" : "NORMAL");
  };

  const handleTurnado = async () => {
    const payload = data.map((item) => ({
      idDocumentoAsignado: item.id,
      idPersonalJuzgado: selectedPersonal,
      idConcepto: selectedConcept,
      horas: specifyTime || time ? time : null,
      prioridad: urgent,
    }));

    try {
      await turnadoPersonalJuzgado(token, payload);
      showSnackbar('Documento turnado con éxito', 'success');
      handleRefresh();
      onClose();
    } catch (err) {
      console.error('Error al enviar el turnado:', err);
      setError('Error al enviar el turnado');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
      }}>
        <Typography variant="h7" align="left" sx={{ fontWeight: 'bold', mb: 2 }}>Turnado</Typography>

        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <Typography sx={{ minWidth: 70 }}>Para:</Typography>
          <FormControl fullWidth size="small">
            <Select
              value={selectedPersonal}
              onChange={(event) => setSelectedPersonal(event.target.value)}
              displayEmpty
              sx={{ width: '100%' }}
              renderValue={(selected) => {
                return selected ? personalTurnadoOptions.find(persona => persona.id === selected)?.nombre : <span style={{ color: "gray" }}>Selecciona uno</span>;
              }}
            >
              {loading ? (
                <MenuItem disabled>Cargando...</MenuItem>
              ) : (
                personalTurnadoOptions.map(persona => (
                  <MenuItem key={persona.id} value={persona.id}>
                    {persona.nombre}
                  </MenuItem>
                ))
              )}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        </Box>

        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <Typography sx={{ minWidth: 70 }}>Concepto:</Typography>
          <FormControl fullWidth size="small" error={!!error}>
            <Select
              value={selectedConcept}
              onChange={handleChangeConcept}
              displayEmpty
              renderValue={(selected) => {
                return selected ? conceptos.find(concepto => concepto.id === selected)?.nombre : <span style={{ color: "gray" }}>Selecciona uno</span>;
              }}
            >
              {loading ? (
                <MenuItem disabled>Cargando...</MenuItem>
              ) : (
                conceptos.map(concepto => (
                  <MenuItem key={concepto.id} value={concepto.id}>
                    {concepto.nombre}
                  </MenuItem>
                ))
              )}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        </Box>

        <Box mt={2} display="flex" alignItems="center">
          <Typography sx={{ minWidth: 120 }}>Tiempo de término:</Typography>
          <TextField
            type="number"
            value={time}
            onChange={handleTimeChange}
            disabled={!specifyTime}
            size="small"
            sx={{ width: 100, mx: 1 }}
          />
          <Typography sx={{ ml: 1 }}>hrs.</Typography>
          <FormControlLabel
            control={<Checkbox checked={specifyTime} onChange={(e) => handleSpecifyTimeChange(e.target.checked)} />}
            label="Especificar tiempo"
            sx={{ ml: 'auto' }}
          />
        </Box>

        <FormControlLabel
          control={<Checkbox checked={urgent === "URGENTE"} onChange={handleUrgentChange} />}
          label="Marcar como urgente"
          sx={{ mt: 3 }}
        />

        <Typography mt={3}>Total de elementos seleccionados a turnar: {data.length}</Typography>

        <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
          <Button variant="contained" className="button-save" onClick={handleTurnado}>
            Turnar
          </Button>
          <Button variant="outlined" className="button-close" onClick={onClose}>
            Cerrar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalTurnado;
