import {
  Box,
  Divider,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../api/keycloakConf";
import BandejaSalidaTable from "./Grid/BandejaSalidaTable";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import Carrito from "./Carrito/Carrito";
import ToastAlertInfo from "../../components/Alert/ToastAlertInfo/ToastAlertInfo";
import { useToastAlert } from '../../components/Alert/ToastAlertSave/ToastAlertSave';
import { fetchSalidas, turnadoDocumento } from "../../shared/services/DocumentoService";
import { getReporteMovimiento } from "../../shared/services/MovimientoService";
import { useNavigate } from "react-router-dom";
import HeaderBandejaSalida from "./Grid/HeaderBandejaSalida";

const Bandeja = () => {
  const [bandejas, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { getToken } = useContext(keycloakConf);
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Ningun registro seleccionado."
  );
  const [toastTitle, setToastTitle] = useState("Seleccione Registros");
  const [errorMessages, setErrorMessages] = useState({});
  const [encargadosCarrito, setEncargadosCarrito] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({ respEntrega: null });
  const { showSnackbar, showConfirmDialog } = useToastAlert();

  useEffect(() => {
    loadBandejaSalida();
  }, [getToken, page, rowsPerPage]);

  const loadBandejaSalida = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const bandejaData = await fetchSalidas(
        token,
        page,
        rowsPerPage,
        "",
        searchQuery
      );
      const filteredData = bandejaData.content.filter(
        (item) => !cartItems.some((cartItem) => cartItem.movid === item.movid)
      );
      setData(filteredData);
      setPageable(bandejaData.page);
    } catch (error) {
      console.error("Error cargando registros:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmarSalida = async () => {
      let body = {
        idList: cartItems.map(obj => obj.movid),
        personaCarrito: formData.respEntrega.id
      };
      const token = getToken();
      try {
        let response = await turnadoDocumento(token, body);
        
        
        let pdf = await getReporteMovimiento(token, response);
        var blob = new Blob([pdf], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "ReporteMovimientoSalida.pdf";
        link.click();

        setFormData({ respEntrega: null });
        setCartItems([]);
        setConfirmationModalOpen(false);
        loadBandejaSalida();
        showSnackbar('Envío de carrito exitoso', 'success');
      } catch (error) {
        console.error('Error al dar Salida a carrito:', error);
        showSnackbar('¡Algo salió mal!', 'error');
      }
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleRemoveFromCart = (movid) => {
    const updatedCartItems = cartItems.filter((item) => item.movid !== movid);
    setCartItems(updatedCartItems);
    const removedItem = cartItems.find((item) => item.movid === movid);
    if (
      removedItem &&
      !bandejas.some((item) => item.movid === removedItem.movid)
    ) {
      setData((prevBandejas) => [...prevBandejas, removedItem]);
    }
  };

  return (
    <>
      <Carrito
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        cartItems={cartItems}
        handleRemoveFromCart={handleRemoveFromCart}
      />

      <HeaderBandejaSalida
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
        loadBandejas={loadBandejaSalida}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        encargadosCarrito={encargadosCarrito}
        setEncargadosCarrito={setEncargadosCarrito}
        setDrawerOpen={setModalOpen}
        setDialogOpen={setConfirmationModalOpen}
        formData={formData}
        setFormData={setFormData}
      />

      <Box sx={{ marginTop: 2, marginBottom: 3 }}>
        <Divider />
      </Box>

      <BandejaSalidaTable
        bandejas={bandejas}
        pageable={pageable}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        loadBandejaSalida={loadBandejaSalida}
        toastOpen={toastOpen}
        setToastOpen={setToastOpen}
        loading={loading}
        cartItems={cartItems}
        setCartItems={setCartItems}
        setToastMessage={setToastMessage}
        setToastTitle={setToastTitle}
      />

      <ConfirmationModal
        isModalOpen={confirmationModalOpen}
        setModalOpen={setConfirmationModalOpen}
        cartItems={cartItems}
        handleConfirmarSalida={handleConfirmarSalida}
        formData={formData}
      />

      <ToastAlertInfo
        open={toastOpen}
        onClose={handleCloseToast}
        title={toastTitle}
        message={toastMessage}
        type={"info"}
      />
    </>
  );
};

export default Bandeja;
