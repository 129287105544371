import React, { useEffect, useState, useContext } from 'react';
import { fetchIndicadores } from '../../shared/services/DocumentoService';
import keycloakConf from '../../api/keycloakConf';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
    borderRadius: 0,
    boxShadow: 'none',
    width: '33%'
  }));

const IndicadoresComponent = ({ isRecepcion }) => {
    const [indicadores, setIndicadores] = useState(null);
    const { getToken } = useContext(keycloakConf);

    const loadIndicadores = async () => {
        try {
            const token = getToken(); 
            const data = await fetchIndicadores(token, isRecepcion);
            setIndicadores(data);
        } catch (error) {
            console.error('Error al cargar indicadores:', error);
        }
    };

    useEffect(() => {
        loadIndicadores();
    }, []);

    if (!indicadores) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
                <Stack direction="row" spacing={1}>
                    <Item>{isRecepcion ? 'Pendientes de recepción:' : 'Expedientes asignados:'}<br/>{indicadores.indicador1}</Item>
                    <Item>{isRecepcion ? 'Enviados hoy:' : 'Término rebasado:'}<br/>{indicadores.indicador2}</Item>
                    <Item>{isRecepcion ? 'Enviados ayer:' : 'Término en 24 horas:'}<br/>{indicadores.indicador3}</Item>
                    <Item>{isRecepcion ? 'Enviados hace más de 3 días:' : 'Término en 3 días:'}<br/>{indicadores.indicador4}</Item>
                </Stack>
            </Grid>
        </Grid>
    );

};

export default IndicadoresComponent;
