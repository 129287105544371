import axios from 'axios';

const urlCarpeta = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documento/promocion`;

// Función para añadir promocion
export const addPromocion = async (token, data) => {
    try {
        const response = await axios.post(urlCarpeta, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
        });
        return response.data;
    } catch (error) {
        console.error('Error al guardar promoción: ', error);
        throw error;
    }
};

// Función para obtener todas las Salidas
export const fetchSalidas = async (token, page = 0, size = 10, sort = '', key = '') => {
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/salida`, { 
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          sort,
          key
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al obtener Salidas:', error);
      throw error;
    }
  };

// Función para dar Salida a TURNADO
export const turnadoDocumento = async (token, body) => {
  try {
    const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/salida`, body, { 
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al cambiar estatus de items del carrito:', error);
    throw error;
  }
};


export const fetchIndicadores = async (token, isRecepcion) => {
  try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/indicadores`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
          params: {
            isRecepcion,
          },
      });
      
      return response.data;
  } catch (error) {
      console.error('Error al obtener indicadores:', error);
      throw error;
  }
};

// Cancelar Oficio
export const cancelOficio = async(token, documentoId) => {
  try {
     const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/oficio/${documentoId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
     );

     return response.data;
  } catch (error) {
    console.log('Error al cancelar el oficio: ', error);
    throw error;
  }
}

// Función para obtener todos los oficios
export const fetchOficios = async (token, page = 0, size = 10, sort = '', key = '') => {
  try {
    const params = new URLSearchParams();
        params.append("sort", encodeURI('estatus'));
        params.append("sort", encodeURI('dd.fechaEmision,desc'));
        params.append("page", page);
        params.append("size", size);
        params.append("key", key);

    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/oficios`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener los oficios:', error);
    throw error;
  }
};

export const movimientoPersonalJuzgado = async (token, record) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/recepcion/movimiento`, record, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      return response.data;
  } catch (error) {
    console.error('Error al realizar movimiento personal en juzgado: ', error);
    throw error;
  }
};

export const turnadoPersonalJuzgado = async (token, records) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/asignados/movimiento`, records, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      return response.data;
  } catch (error) {
    console.error('Error al realizar el turnado del personal juzgado: ', error);
    throw error;
  }
};