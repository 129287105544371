const breadcrumbConfig = {
    '/': 'Inicio',
    '/home': 'Inicio',
    '/api/core/juzgados': 'Órgano jurisdiccional',
    '/api/core/oficialias': 'Oficialías',
    '/api/core/personas': 'Personal',
    '/api/core/instituciones': 'Instituciones',
    '/api/core/personal/edit': 'Editar personal',
    '/api/core/materias': 'Materias',
    '/api/core/oficialias/crear_oficialia': 'Agregar oficialía',
    '/api/core/oficialias/editar_oficialia': 'Editar oficialía',
    '/api/core/instituciones/crear_institucion': 'Agregar institución',
    '/api/core/instituciones/editar_institucion': 'Editar institución',
    '/api/core/juzgados/crear_juzgado': 'Agregar órgano jurisdiccional',
    '/api/core/juzgados/editar_juzgado': 'Editar juzgado',
    '/api/core/sedes': 'Sedes',
    '/api/core/sedes/add': 'Agregar sede',
    '/api/core/sedes/edit': 'Editar sede',
    '/api/workflow/demanda': 'Demanda',
    '/api/workflow/exhorto': 'Exhorto',
    '/api/core/salas': 'Salas',
    '/api/core/salas/crear_salas': 'Agregar sala',
    '/api/core/salas/editar_salas': 'Editar sala',
    '/api/core/bloques': 'Bloques',
    '/api/core/bloques/add': 'Agregar bloque',
    '/api/core/bloques/edit': 'Editar bloque',
    '/api/workflow/promocion': 'Promoción',
    '/api/bandeja/historial': 'Historial',
    '/api/bandeja/entrada': 'Entrada',
    '/api/bandeja/salida': 'Salida',
    '/api/bandeja/oficios': 'Oficios',
    '/api/workflow/apelacion': 'Apelación',
    '/api/bandeja/recepcion': 'Recepción',
    '/api/bandeja/asignados': 'Asignado',
    '/api/bandeja/audiencias': 'Audiencias'
};
  
export default breadcrumbConfig;