import axios from 'axios';

export const createDocument = async (token, documentoNuevo, documentoId) => {
    
    try {
      
      const response = await axios.post(`${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/documentos/digitalizacion/${documentoId}`,
        documentoNuevo, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
  
      return response.data; 
    } catch (error) {
      console.error('Error al agregar el documento: ', error);
      throw error; 
    }
  };