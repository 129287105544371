import axios from 'axios';

export const getAllConceptos = async (token, page = 0, size = 25, sort = '') => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/conceptos`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                page,
                size,
                sort
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al listar los conceptos:', error);
        throw error;
    }
};
