import React, { useContext } from 'react';
import FormSala from './Formulario/FormSala';
import KeycloakConf from '../../../api/keycloakConf';

const CreateSala = () => {
  const { getToken } = useContext(KeycloakConf);

  const handleAddSala = async () => {
  };

  return (
    <>
      <FormSala
        token={getToken()}
        onAddSala={handleAddSala}
      />
    </>
  );
};

export default CreateSala;

