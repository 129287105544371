import React, { useEffect, useState, useContext } from 'react';
import { Modal, Box, Button, FormControl, Select, MenuItem, Typography, Grid, FormHelperText } from '@mui/material';
import { getAllConceptos } from '../../shared/services/ConceptosService';
import { movimientoPersonalJuzgado } from '../../shared/services/DocumentoService'; 
import keycloakConf from '../../api/keycloakConf';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave'

const RecepcionModal = ({ open, onClose, item, handleRefresh }) => {
    const [conceptos, setConceptos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedConcepto, setSelectedConcepto] = useState("");
    const { getToken } = useContext(keycloakConf);
    const { showSnackbar, showConfirmDialog } = useToastAlert();

    const token = getToken();

    useEffect(() => {
        if (open) {
            const fetchConceptos = async () => {
                setLoading(true);
                try {
                    const data = await getAllConceptos(token);
                    setConceptos(data.content);
                } catch (err) {
                    setError('Error al cargar conceptos');
                } finally {
                    setLoading(false);
                }
            };

            fetchConceptos();
        } else {
            setSelectedConcepto("");
        }
    }, [open, token]);

    const handleChange = (event) => {
        setSelectedConcepto(event.target.value);
    };

    const handleReceive = async () => {
        const record = {
            idDocumentoRecepcion: item.id,
            idConcepto: selectedConcepto,
        };

        try {
            const result = await movimientoPersonalJuzgado(token, record);
            showSnackbar('Documento asignado con éxito', 'success');
            onClose();
            handleRefresh();
        } catch (error) {
            showSnackbar('Hubo un error al asignar el documento', 'error');
        }
    };

    if (!item) {
        return null;
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                setSelectedConcepto("");
                onClose();
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                borderRadius: 2,
            }}>
                <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                    {item.prioridad === 'URGENTE' ? 'URGENTE' : 'Recepción'}
                </Typography>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="body1">De:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1" sx={{ marginLeft: .5 }}>{item.origen}</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <Typography variant="body1">Concepto:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl fullWidth variant="outlined" error={!!error}>
                            <Select
                                value={selectedConcepto || item.concepto}
                                onChange={handleChange}
                                displayEmpty
                                size="small"
                                sx={{ minWidth: 120 }}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return <span style={{ color: 'gray' }}>Selecciona uno</span>;
                                    }
                                    const conceptoSeleccionado = conceptos.find(concepto => concepto.id === selected);
                                    return conceptoSeleccionado ? conceptoSeleccionado.nombre : item.concepto;
                                }}
                            >
                                {loading ? (
                                    <MenuItem disabled>Cargando...</MenuItem>
                                ) : (
                                    conceptos.map(concepto => (
                                        <MenuItem key={concepto.id} value={concepto.id}>
                                            {concepto.nombre}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                            {error && <FormHelperText>{error}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    {item.horas !== null && (
                        <Grid item xs={12} sx={{ marginTop: 3 }}>
                            <Typography variant="body1">
                                Tiempo de término:
                                <Box component="span" sx={{ marginLeft: 3, fontWeight: 'bold' }}>
                                    {item.horas} hrs
                                </Box>
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'right', gap: 2}}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        className="button-close"
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant="contained"
                        className="button-save"
                        onClick={handleReceive}
                    >
                        Recibir
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RecepcionModal;
