import { useState, useEffect } from "react";
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import { useNavigate } from "react-router-dom";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import RefreshButton from "../../../components/Button/RefreshButton";
import SendButton from "../../../components/Button/SendButton";
import text from "../../../assets/Glosario";
import ButtonsAccionRapida from "./ButtonsAccionRapida";
import ModalTurnado from "../../../components/ModalTurnarPersonalJuzgado/ModalTurnar";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableBandejaAsignados = ({
  bandeja,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loadBandejaAsignados,
  setToastOpen,
  loading,
  cartItems,
  setCartItems,
  setSearchQuery,
}) => {
  const { showSnackbar } = useToastAlert();
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleOpenModal = (items) => {
    setModalData(items);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCartItems([]);
  };

  const handleSelectRow = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  const handleSingleTurnar = (id) => {
    const itemToAdd = bandeja.find((item) => item.id === id);
    const isItemInCart = cartItems.some((cartItem) => cartItem.id === id);

    if (!isItemInCart && itemToAdd) {
      setCartItems((prevItems) => [...prevItems, itemToAdd]);
      handleOpenModal([itemToAdd]);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = bandeja.map((item) => item.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleRefresh = () => {
    setSearchQuery('');
    setPage(0);
    loadBandejaAsignados();
  };

  const addToTurnado = () => {
    const selectedItems = bandeja.filter((item) => selected.includes(item.id));
    const newItemsToAdd = selectedItems.filter(
      (item) => !cartItems.some((cartItem) => cartItem.id === item.id)
    );

    setCartItems([...cartItems, ...newItemsToAdd]);
    setSelected([]);
    if (newItemsToAdd.length > 0) {
      handleOpenModal(newItemsToAdd);
    }
  };

  const verExpediente = (item) =>{
    navigate('/api/bandeja/expediente', { state: { item } });
  }

  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <RefreshButton onClick={handleRefresh} />
        </Box>

        <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
          <SendButton onClick={addToTurnado} />
          <ModalTurnado 
            open={openModal}
            onClose={handleCloseModal}
            data={modalData}
            handleRefresh={handleRefresh}
          />
        </Box>
      </Box>

      <Box>
        {loading ? (
          <p>Cargando ... </p>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
            <PaginationGeneric
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
              <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
                <Table stickyHeader sx={{ minWidth: 750 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-header" padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < bandeja.length
                          }
                          checked={
                            bandeja.length > 0 &&
                            selected.length === bandeja.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.NoExpediente}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.TipoEntrada}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Concepto}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.FechaTurnado}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.FechaTermino}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Estatus}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Acciones}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bandeja.map((item) => {
                      const isItemSelected = isSelected(item.id);
                      return (
                        <StyledTableRow
                          key={item.id}
                          hover
                          style={{ cursor: "pointer" }}
                          selected={isItemSelected}
                          onClick={()=>{
                            verExpediente(item);
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              disabled={
                                item.estatus === "TURNADO"
                              }
                              onChange={() => handleSelectRow(item.id)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {item.expediente}
                          </TableCell>
                          <TableCell align="center">
                            {item.tipoEntrada}
                          </TableCell>
                          <TableCell align="center">{item.concepto}</TableCell>
                          <TableCell align="center">
                            {new Date(item.fechaTurnado).toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(item.fechaTermino).toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            {item.estatus}
                          </TableCell>
                          <TableCell align="center">
                            <ButtonsAccionRapida
                              item={item}
                              handleSingleTurnar={handleSingleTurnar}
                            />
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                    {bandeja.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No hay registros disponibles.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationGeneric
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TableBandejaAsignados;
