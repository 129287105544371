
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Divider,
  Tab,
  Tabs,
  ListItemButton,
  List,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Paper,
  Autocomplete
} from '@mui/material';
import { addPersona, fetchPersonaById, updatePersona, fetchAllCentroTrabajo } from '../../PersonasService';
import { useNavigate } from 'react-router-dom';
import { validateFields } from '../../../../components/Alert/ValidationRequired/validationRequired';
import { fetchEscolaridades } from '../../../../shared/services/EscolaridadesService';
import { fetchEstadoCivil } from '../../../../shared/services/EstadoCivilService';
import { fetchJuzgados } from '../../../../shared/services/JuzgadosService';
import { fetchEstados, fetchMunicipiosByEstadoId } from '../../../../shared/services/EstadosService';
import { fetchRoles, fetchRolesByUserId } from '../../../../shared/services/RolesService';
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { validateCurp } from '../../../../components/CURP/validationCurp'
import { onlyText, onlyNames, onlyNumbers, onlyAlphaNumeric, onlyEmail, isValidEmail } from '../../../../components/Caracteres/caracteresEspeciales';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const FormPersona = ({ token, onEdit }) => {
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert(); 
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [escolaridades, setEscolaridades] = useState([]);
  const [juzgados, setJuzgados] = useState([]);
  const [estadoCivilList, setEstadoCivil] = useState([]);
  const [infoTab, setInfoTab] = useState(0);
  const [errorMessages, setErrorMessages] = useState({});
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = React.useState([]);
  const [centrosTrabajo, setCentrosTrabajo] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const customList = (items, header) => (

    <Paper sx={{ width: 300, height: 270, overflow: 'auto', mt:2 }}>
      <p style={{ textAlign: 'center' }}>{header}</p>
      <Divider />
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );
  const [persona, setPersona] = useState({
    id: '',
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    curp: '',
    rfc: '',
    fechaNacimiento: '',
    sexo: '',
    ocupacion: '',
    correoElectronico: '',
    telefono: '',
    celular: '',
    estado: '',
    estadoCivilId: '',
    escolaridadId: '',
    juzgadoId: '',
    oficialiaId: '',
    centroTrabajo: '',
    domicilio: {
      calle: '',
      interior: '',
      exterior: '',
      colonia: '',
      localidad: '',
      codigoPostal: '',
      municipio: '',
      estadoRepublica: '',
      referencia: ''
    }
  });

  useEffect(() => {
    loadEstados();
    loadEscolaridades();
    loadEstadoCivil();
    loadJuzgados();
    loadCentrosTrabajo();
    if (onEdit.id) {
      loadbyId();
    } else {
      loadRoles();
      persona.curp = onEdit;
      setPersona((prevData) => ({
        ...prevData,
        estado: 'ACTIVE',
      }))
    }
  }, [onEdit]);

  const loadEstados = async () => {
    try {
      const estadosData = await fetchEstados(token);
      setEstados(estadosData);
    } catch (error) {
      console.error('Error al obtener estados:', error);
    }
  };

  const loadJuzgados = async () => {
    try {
      const juzgadosData = await fetchJuzgados(token);
      setJuzgados(juzgadosData.content); 
    } catch (error) {
      console.error('Error al obtener registros:', error);
    }
  };

  const loadRoles = async () => {
    try {
      const roles = await fetchRoles(token);
      setLeft(roles);
    } catch (error) {
      console.error('Error al obtener roles:', error);
    }
  };

  const loadEscolaridades = async () => {
    try {
      const escolaridadesData = await fetchEscolaridades(token);
      setEscolaridades(escolaridadesData);
    } catch (error) {
      console.error('Error al obtener registros:', error);
    }
  };

  const loadEstadoCivil = async () => {
    try {
      const estadoCivilData = await fetchEstadoCivil(token);
      setEstadoCivil(estadoCivilData);
    } catch (error) {
      console.error('Error al obtener registros:', error);
    }
  };

  const loadCentrosTrabajo = async () => {
    try{
      const centrosTrabajoData = await fetchAllCentroTrabajo(token);
      setCentrosTrabajo(centrosTrabajoData);
    } catch (error){
      console.error('Error al obtener Centros de Trabajo', error);
    }
  };

  const loadbyId = function () {
    const loadbyId = async () => {
      try {
        const personaData = await fetchPersonaById(token, onEdit.id);
        personaData.domicilio = { ...personaData.domicilio, audit: {} }

        let centroTrabajo='';

        if(personaData.juzgadoId){
          centroTrabajo = {
            id: personaData.juzgadoId,
            tipo: 'JUZGADO'
          }
        }

        if(personaData.oficialiaId){
          centroTrabajo = {
            id: personaData.oficialiaId,
            tipo : 'OFICIALIA_COMUN'
          }
        }

        if (centroTrabajo!='')
          centroTrabajo = JSON.stringify(centroTrabajo);

        personaData.centroTrabajo = centroTrabajo;

        const roles = await fetchRolesByUserId(token, personaData.usuario);
        setLeft(roles);

        setRight(personaData.permisos);
        setPersona(personaData);
        try {
          const estadosData = await fetchEstados(token);
          setEstados(estadosData);
          try {
            const estadoData = estadosData.filter(estado => estado.name === personaData.domicilio.estadoRepublica);
            const municipiosData = await fetchMunicipiosByEstadoId(token, estadoData[0].id);
            setMunicipios(municipiosData);
          } catch (error) {
            console.error('Error al obtener municipios:', error);
          }
        } catch (error) {
          console.error('Error al obtener estados:', error);
        }
      } catch (error) {
        console.error('Error al obtener persona:', error);
      }
    };
    loadbyId();
  };

  const formatPhoneCelNumbers = (value) => {
    const numericValue = value.replace(/\D/g, '');
    
    if (numericValue.length === 0) {
        return '';
    } else if (numericValue.length <= 3) {
        return `(${numericValue}`;
    } else if (numericValue.length <= 6) {
        return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
    } else {
        return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)} ${numericValue.slice(6, 10)}`;
    }
};

  const fieldsMap = {
    'ocupacion': onlyText,
    'domicilio.colonia': onlyText,
    'domicilio.localidad': onlyText,
    'domicilio.referencia': onlyText,
    'nombre': onlyNames,
    'apellidoPaterno': onlyNames,
    'apellidoMaterno': onlyNames,
    'domicilio.interior': onlyAlphaNumeric,
    'domicilio.exterior': onlyAlphaNumeric,
    'domicilio.codigoPostal': onlyNumbers,
    'curp': onlyAlphaNumeric,
    'rfc': onlyAlphaNumeric,
    'correoElectronico': onlyEmail
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const caracterValue = (fieldsMap[name] || ((val) => val))(value);

    if (name === 'celular') {
      const formattedValue = formatPhoneCelNumbers(value);
      const numericValue = value.replace(/\D/g, '');

      setPersona((prevData) => ({
        ...prevData,
        celular: numericValue
      }));
      e.target.value = formattedValue;
    } else if (name === 'telefono') {
      const formattedValue = formatPhoneCelNumbers(value);
      const numericValue = value.replace(/\D/g, '');

      setPersona((prevData) => ({
        ...prevData,
      telefono: numericValue
      }));
      e.target.value = formattedValue;
    } else if (name.startsWith('domicilio.')) {
      const fieldName = name.split('.')[1];
      setPersona((prevData) => ({
        ...prevData,
        domicilio: {
          ...prevData.domicilio,
          [fieldName]: caracterValue 
        }
      }));
    } else {
      setPersona((prevData) => ({
        ...prevData,
        [name]: caracterValue
      }));
    }

    if (name === 'centroTrabajo'){
      let juzgadoId = null;
      let oficialiaId = null;
      
      if (value) {
        try {
          const data = JSON.parse(value);
  
          if (data.tipo === 'JUZGADO') {
            juzgadoId = data.id;
          }
  
          if (data.tipo === 'OFICIALIA_COMUN') {
            oficialiaId = data.id;
          }
  
        } catch (error) {
          console.error("Error al parsear centroTrabajo:", error);
        }
      }

      setPersona((prevData) => ({
        ...prevData,
        juzgadoId,
        oficialiaId,
      }));
    }

    setErrorMessages({
      ...errorMessages,
      [name]: '',
    });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };


  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleTabChange = (event, newValue) => {
    setInfoTab(newValue);
  };

  const handleCancel = () => {
    navigate('/api/core/personas');
  };

  const handleSubmit = async (e) => {
    const rules = {
      nombre: { required: true },
      apellidoPaterno: { required: true },
      curp: { required: true },
      fechaNacimiento: { required: true },
      sexo: { required: true },
      ocupacion: { required: true },
      estado: { required: true },
      correoElectronico: { required: true },
      celular: { required: true, pattern: /^\(\d{3}\) \d{3} \d{4}$/ },
      escolaridadId: { required: true },
      estadoCivilId: { required: true },
      telefono: { pattern: /^\(\d{3}\) \d{3} \d{4}$/ },
      'domicilio.calle': { required: true },
      'domicilio.exterior': { required: true },
      'domicilio.estadoRepublica': { required: true },
      'domicilio.municipio': { required: true }
    };
    const errors = validateFields(persona, rules, showAlertValidate);

    const tabFieldsMap = {
    
      0: ['nombre', 'apellidoPaterno', 'curp', 'fechaNacimiento', 'sexo','ocupacion',
           'estado', 'escolaridadId',
           'estadoCivilId','telefono'
      ],
      1: ['domicilio.calle', 'domicilio.exterior', 'domicilio.estadoRepublica', 'domicilio.municipio', 'correoElectronico', 'celular',]
    };

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);

      // Cambiar a la tab
      const firstErrorField = Object.keys(errors)[0];
      const tabIndex = Object.keys(tabFieldsMap).find(index => 
        tabFieldsMap[index].includes(firstErrorField)
      );
  
      if (tabIndex !== undefined) {
        setInfoTab(parseInt(tabIndex, 10));
      }
      return;
    }

    if (!validateCurp(persona.curp)) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        curp: 'CURP con formato incorrecto.'
      }));
      showSnackbar('CURP con formato incorrecto.', 'error');
      return;
    }

    if (!isValidEmail(persona.correoElectronico)) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        correoElectronico: 'Correo electrónico inválido.'
      }));
      showSnackbar('Correo electrónico inválido.', 'error');
      return;
    }

    if (persona.celular.replace(/\D/g, '').length !== 10) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        celular: 'El número de celular debe tener 10 dígitos.'
      }));
      showSnackbar('El número de celular debe tener 10 dígitos.', 'error');
      return;
    }

    if (persona.telefono.replace(/\D/g, '').length !== 10 && persona.telefono.replace(/\D/g, '').length > 0) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        telefono: 'El número de telefono debe tener 10 dígitos.'
      }));
      showSnackbar('El número de telefono debe tener 10 dígitos.', 'error');
      return;
    }

    let formData = {
      persona: {
        ...persona,
        juzgado: { id: persona.juzgadoId },
        oficialia: {id: persona.oficialiaId},
        escolaridad: { id: persona.escolaridadId },
        estadoCivil: { id: persona.estadoCivilId },
        audit: {}
      },
      roles: right
    };

    try {
      if (persona.id) {
        await updatePersona(token, formData);
        showUpdateSnackbar('Registro actualizado con éxito', 'success');
      } else {
        await addPersona(token, formData);
        showSnackbar('Registro guardado con éxito', 'success');
      }
      navigate('/api/core/personas');
    } catch (error) {
      console.error('Error al guardar persona:', error);
      showSnackbar('¡Algo salió mal!', 'error');
    }
  };

  const fetchMunicipios = function (event) {
    persona.domicilio.municipio = "";
    const loadMunicipios = async () => {
      try {
        const estadoData = estados.filter(estado => estado.name === event.target.value);
        const municipiosData = await fetchMunicipiosByEstadoId(token, estadoData[0].id);
        setMunicipios(municipiosData);
      } catch (error) {
        console.error('Error al obtener municipios:', error);
      }
    };
    loadMunicipios();
    handleChange(event);
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button type="button" onClick={handleSubmit} variant="contained" className="button-save">
            Guardar
          </Button>
          <Button
            variant="contained"
            className="button-close"
            onClick={handleCancel}
            style={{ marginLeft: '10px' }}
          >
            Cerrar
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <Divider />
      </Box>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12}>
          <Tabs value={infoTab} onChange={handleTabChange} >
            <Tab label="Datos generales" />
            <Tab label="Datos de contacto" />
            <Tab label="Permisos" />
          </Tabs>
        </Grid>

        {infoTab === 0 && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="nombre"
                label="Nombre(s)"
                variant="outlined"
                fullWidth
                value={persona.nombre}
                onChange={handleChange}
                error={!!errorMessages.nombre}
              />
              {errorMessages.nombre && <FormHelperText error>{errorMessages.nombre}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="apellidoPaterno"
                label="Apellido paterno"
                variant="outlined"
                fullWidth
                value={persona.apellidoPaterno}
                onChange={handleChange}
                error={!!errorMessages.apellidoPaterno}
              />
              {errorMessages.apellidoPaterno && <FormHelperText error>{errorMessages.apellidoPaterno}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="apellidoMaterno"
                label="Apellido materno (Opcional)"
                variant="outlined"
                fullWidth
                value={persona.apellidoMaterno}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="curp"
                label="CURP"
                variant="outlined"
                fullWidth
                value={persona.curp}
                onChange={handleChange}
                error={!!errorMessages.curp}
              />
              {errorMessages.curp && <FormHelperText error>{errorMessages.curp}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="rfc"
                label="RFC (Opcional)"
                variant="outlined"
                fullWidth
                value={persona.rfc}
                onChange={handleChange}
                inputProps={{ maxLength: 13 }} // Limita la entrada a 13 caracteres
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="fechaNacimiento"
                label="Fecha de nacimiento"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={persona.fechaNacimiento}
                onChange={handleChange}
                error={!!errorMessages.fechaNacimiento}
              />
              {errorMessages.fechaNacimiento && <FormHelperText error>{errorMessages.fechaNacimiento}</FormHelperText>}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined" error={!!errorMessages.sexo}>
                <InputLabel id="sexo-label">Sexo</InputLabel>
                <Select
                  labelId="sexo-label"
                  name="sexo"
                  label="Sexo"
                  value={persona.sexo}
                  onChange={handleChange}
                  error={!!errorMessages.sexo}
                >
                  <MenuItem value="FEMENINO">Femenino</MenuItem>
                  <MenuItem value="MASCULINO">Masculino</MenuItem>
                </Select>
                {errorMessages.sexo && <FormHelperText error>{errorMessages.sexo}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined" error={!!errorMessages.estadoCivilId}>
                <InputLabel id="estado-civil-label">Estado civil</InputLabel>
                <Select
                  labelId="estado-civil-label"
                  name="estadoCivilId"
                  value={persona.estadoCivilId}
                  onChange={handleChange}
                  label="Estado civil"
                  error={!!errorMessages.estadoCivilId}
                >
                  {estadoCivilList.map((estadoCivil) => (
                    <MenuItem key={estadoCivil.id} value={estadoCivil.id}>{estadoCivil.nombre}</MenuItem>
                  ))}
                </Select>
                {errorMessages.estadoCivilId && <FormHelperText>{errorMessages.estadoCivilId}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined" error={!!errorMessages.escolaridadId}>
                <InputLabel id="escolaridad-label">Escolaridad</InputLabel>
                <Select
                  labelId="escolaridad-label"
                  name="escolaridadId"
                  value={persona.escolaridadId}
                  onChange={handleChange}
                  label="Escolaridad"
                  error={!!errorMessages.escolaridadId}
                >
                  {escolaridades.map((escolaridad) => (
                    <MenuItem key={escolaridad.id} value={escolaridad.id}>{escolaridad.nombre}</MenuItem>
                  ))}
                </Select>
                {errorMessages.escolaridadId && <FormHelperText>{errorMessages.escolaridadId}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="ocupacion"
                label="Ocupación"
                variant="outlined"
                fullWidth
                value={persona.ocupacion}
                onChange={handleChange}
                error={!!errorMessages.ocupacion}
              />
              {errorMessages.ocupacion && <FormHelperText error>{errorMessages.ocupacion}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  options={centrosTrabajo}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: 'centroTrabajo',
                        value: newValue ? JSON.stringify({ id: newValue.id, tipo: newValue.tipo }) : '',
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Centro de trabajo"
                      variant="outlined"
                    />
                  )}
                  value={centrosTrabajo.find((centro) => 
                    JSON.stringify({ id: centro.id, tipo: centro.tipo }) === persona.centroTrabajo) || null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={persona.estado === "ACTIVE"}
                      onChange={(event) => handleChange({
                        target: {
                          name: 'estado',
                          value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                        }
                      })}
                      color="primary"
                    />
                  }
                  label="Activar persona"
                />
                {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
              </FormControl>
            </Grid>
          </>
        )}

        {infoTab === 1 && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="domicilio.calle"
                inputProps={{ minLength: 3, maxLength: 100 }}
                label="Calle"
                variant="outlined"
                fullWidth
                value={persona.domicilio.calle}
                onChange={handleChange}
                error={!!errorMessages['domicilio.calle']}
              />
              {errorMessages['domicilio.calle'] && <FormHelperText error>{errorMessages['domicilio.calle']}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="domicilio.exterior"
                inputProps={{ minLength: 1, maxLength: 20 }}
                label="Número exterior"
                variant="outlined"
                fullWidth
                value={persona.domicilio.exterior}
                onChange={handleChange}
                error={!!errorMessages['domicilio.exterior']}
              />
              {errorMessages['domicilio.exterior'] && <FormHelperText error>{errorMessages['domicilio.exterior']}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="domicilio.interior"
                inputProps={{ minLength: 1, maxLength: 20 }}
                label="Número interior (Opcional)"
                variant="outlined"
                fullWidth
                value={persona.domicilio.interior}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined" error={!!errorMessages['domicilio.estadoRepublica']}>
                <InputLabel id="estado-label">Estado</InputLabel>
                <Select
                  labelId="estado-label"
                  name="domicilio.estadoRepublica"
                  value={persona.domicilio.estadoRepublica}
                  onChange={fetchMunicipios}
                  label="Estado"
                  error={!!errorMessages['domicilio.estadoRepublica']}
                >
                  {estados.map((estado) => (
                    <MenuItem key={estado.id} value={estado.name}>{estado.name}</MenuItem>
                  ))}
                </Select>
                {errorMessages['domicilio.estadoRepublica'] && <FormHelperText>{errorMessages['domicilio.estadoRepublica']}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined" error={!!errorMessages['domicilio.municipio']}>
                <InputLabel id="municipio-label">Delegación o municipio</InputLabel>
                <Select
                  labelId="municipio-label"
                  name="domicilio.municipio"
                  value={persona.domicilio.municipio}
                  onChange={handleChange}
                  label="Delegación o municipio"
                  error={!!errorMessages['domicilio.municipio']}
                >
                  {municipios.map((municipio) => (
                    <MenuItem key={municipio.id} value={municipio.name}>{municipio.name}</MenuItem>
                  ))}
                </Select>
                {errorMessages['domicilio.municipio'] && <FormHelperText>{errorMessages['domicilio.municipio']}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="domicilio.localidad"
                inputProps={{ maxLength: 100 }}
                label="Localidad (Opcional)"
                variant="outlined"
                fullWidth
                value={persona.domicilio.localidad}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="domicilio.colonia"
                inputProps={{ maxLength: 100 }}
                label="Colonia (Opcional)"
                variant="outlined"
                fullWidth
                value={persona.domicilio.colonia}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="domicilio.codigoPostal"
                inputProps={{ maxLength: 5 }}
                label="Código postal (Opcional) "
                variant="outlined"
                fullWidth
                value={persona.domicilio.codigoPostal}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="domicilio.referencia"
                inputProps={{ maxLength: 100 }}
                label="Referencia (Opcional)"
                variant="outlined"
                fullWidth
                value={persona.domicilio.referencia}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="correoElectronico"
                label="Correo electrónico"
                variant="outlined"
                fullWidth
                value={persona.correoElectronico}
                onChange={handleChange}
                error={!!errorMessages.correoElectronico}
              />
              {errorMessages.correoElectronico && <FormHelperText error>{errorMessages.correoElectronico}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="telefono"
                inputProps={{ maxLength: 14 }}
                label="Teléfono (Opcional)"
                variant="outlined"
                fullWidth
                value={formatPhoneCelNumbers(persona.telefono)}
                onChange={handleChange}
                error={!!errorMessages.telefono}
              />
              {errorMessages.telefono && <FormHelperText error>{errorMessages.telefono}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="celular"
                inputProps={{ maxLength: 14 }}
                label="Celular"
                variant="outlined"
                fullWidth
                value={formatPhoneCelNumbers(persona.celular)}
                onChange={handleChange}
                error={!!errorMessages.celular}
              />
              {errorMessages.celular && <FormHelperText error>{errorMessages.celular}</FormHelperText>}
            </Grid>
          </>
        )}

        {infoTab === 2 && (
          <>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid item>{customList(left, "Permisos disponibles")}</Grid>
              <Grid item>
                <Grid container direction="column" sx={{ alignItems: 'center' }}>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList(right, "Permisos del usuario")}</Grid>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default FormPersona;
