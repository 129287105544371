import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import RefreshButton from "../../../../components/Button/RefreshButton";
import { ReduceCapacityOutlined } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import PaginationGeneric from "../../../../components/Pagination/PaginationGeneric";

const Documentos = () => {
    const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState();
    const [open, setOpen] = useState(false);
    //variables de documentos
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [documentosData, setDocumentos] = useState([]);
    const [tipoDocumentoData, setTipoDocumentos] = useState([
        { id: 1, nombre: "General" },
        { id: 2, nombre: "Promoción" },
        { id: 3, nombre: "Amparo" },
        { id: 4, nombre: "Pieza" },
        { id: 5, nombre: "Oficio" },
        { id: 6, nombre: "pieza" },
        { id: 7, nombre: "Presentación de Demanda" }
    ]);

    useEffect(() => {
        loadDocumentos(); 
    }, []);

    const loadDocumentos = async () => {
        //aqui se tiene que remplazaer estos valores estaticos por los que vengan del service que se tiene que crear.

        try {
            const documentosData = {
                content: [
                    { id: 1, origen: 'Oficialia Común', tipo: 'Promoción', identificador: '123456', fechaRecepcion: new Date().toISOString(), observaciones: 'Requerimiento' },
                    { id: 2, origen: 'Secretario', tipo: 'Amparo', identificador: '22456', fechaRecepcion: new Date().toISOString(), observaciones: 'Urgente' },
                    { id: 3, origen: 'Secretario', tipo: 'Pieza', identificador: '000122/2024/AM01', fechaRecepcion: new Date().toISOString(), observaciones: '' },
                    { id: 4, origen: 'Oficial Mayor', tipo: 'Oficio', identificador: '22156', fechaRecepcion: new Date().toISOString(), observaciones: 'Entrega directa' },
                    { id: 5, origen: 'Oficial Mayor', tipo: 'Pieza', identificador: '000122/2024/AC01', fechaRecepcion: new Date().toISOString(), observaciones: '' },
                    { id: 6, origen: 'Oficialia Común', tipo: 'Presentación de Demanda', identificador: '1123545', fechaRecepcion: new Date().toISOString(), observaciones: '' },
                ],
                page: {
                    number: 0,
                    size: 25,
                    totalElements: 4,
                    totalPages: 1
                }
            }
            setDocumentos(documentosData.content);
            setPageable(documentosData.page)
        } catch (error) {

        } finally {

        }
    }


    const handleTipoDocumentoChange = () => {

    }

    const handlePieza = () => {

    }

    const handleAmparo = () => {

    }

    const handleOficio = () => {

    }

    const handleExhorto = () => {

    }

    const handleOpen = () => {
        setOpen(true);
    };

    // Función para cerrar el modal
    const handleClose = () => {
        setOpen(false);
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (<>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={2}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-juzgado-label">Seleccione una opción</InputLabel>
                    <Select
                        labelId="select-juzgado-label"
                        value={tipoDocumentoSelected?.id || ''}
                        onChange={handleTipoDocumentoChange}
                        label="Tipo de documento"
                        required
                    >
                        {tipoDocumentoData.map((tipoDoc) => (
                            <MenuItem key={tipoDoc.id} value={tipoDoc.id}>
                                {tipoDoc.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={2}></Grid>

            <Grid item xs={2}>
                <Button variant="contained" className="button-save" onClick={handlePieza}>
                    Pieza
                </Button>
            </Grid>

            <Grid item xs={2}>
                <Button variant="contained" className="button-save" onClick={handleAmparo}>
                    Amparo
                </Button>
            </Grid>

            <Grid item xs={2}>
                <Button variant="contained" className="button-save" onClick={handleOficio}>
                    Oficio
                </Button>
            </Grid>

            <Grid item xs={2}>
                <Button variant="contained" className="button-save" onClick={handleExhorto}>
                    Exhorto
                </Button>
            </Grid>
        </Grid>

        <Box mb={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <RefreshButton onClick={loadDocumentos} />
            </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>Origen</TableCell>
                                <TableCell className='table-header'>Tipo</TableCell>
                                <TableCell className='table-header'>Identificador</TableCell>
                                <TableCell className='table-header'>Fecha de recepción</TableCell>
                                <TableCell className='table-header'>Observaciones</TableCell>
                                <TableCell className='table-header'>Acciones rapidas</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(documentosData ?? [])
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((documento) => (
                                    <StyledTableRow key={documento.id} hover style={{ cursor: 'pointer' }}>
                                        <TableCell>{documento.origen}</TableCell>
                                        <TableCell>{documento.tipo}</TableCell>
                                        <TableCell>{documento.identificador}</TableCell>
                                        <TableCell>{documento.fechaRecepcion}</TableCell>
                                        <TableCell>{documento.observaciones}</TableCell>
                                        <TableCell>

                                            {(documento.tipo != 'Pieza') && (
                                                <PictureAsPdfIcon />
                                            )}

                                            {(documento.tipo == 'Pieza') && (
                                                <DriveFolderUploadIcon style={{ transform: 'rotate(180deg)' }} />
                                            )}

                                            {(documento.tipo != 'Pieza') && (
                                                <DriveFolderUploadIcon onClick={handleOpen} />
                                            )}

                                            {(documento.tipo != 'Promoción' && documento.tipo != 'Amparo' && documento.tipo != 'Presentación de Demanda') && (
                                                <DoNotDisturbAltIcon />
                                            )}

                                        </TableCell>


                                    </StyledTableRow>
                                ))}
                            {documentosData.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay Documentos.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modal</DialogTitle>
                <DialogContent>
                    CONTENIDO POR CONFIRMAR
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>)
}

export default Documentos;