import React, { useContext } from 'react';
import KeycloakConf from '../../../api/keycloakConf';
import FormListaValor from './FormListaValor';

const CreateListaValor = () => {
  const { getToken } = useContext(KeycloakConf);

  const handleAdd = async () => {

  };

  return (
    <div>
      <h1>Nueva Lista Valor</h1>
      <FormListaValor
        token={getToken()}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default CreateListaValor;

