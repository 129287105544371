import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import FormSala from './Formulario/FormSala'; 
import KeycloakConf from '../../../api/keycloakConf';

const EditSala = () => {
  const location = useLocation();
  console.log(location);
  
  const { sala } = location.state || {};
  const { getToken } = useContext(KeycloakConf);
  
  const navigate = useNavigate(); 

  return (
    <>
      {sala ? (
        <FormSala
        token={getToken()}
        onAdd={() => navigate('/api/core/salas')}
        onEdit={sala}
      />
      ) : (
        <p>No se encontró el registro.</p>
      )}
    </>
  );
};

export default EditSala;
