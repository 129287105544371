import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import ButtonAdd from "../../../../components/Button/ButtonAdd";
import RefreshButton from "../../../../components/Button/RefreshButton";
import { useEffect, useState } from "react";
import PaginationGeneric from "../../../../components/Pagination/PaginationGeneric";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
const Audiencias = () => {
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [audiencias, setAudiencias] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        loadAudiencias(); 
    }, []); 

    const loadAudiencias = async () => {
        //aqui se tiene que remplazaer estos valores estaticos por los que vengan del service que se tiene que crear.
        try {
           const audienciasData = {
               content: [
                   {  id:1, tipo: "Presentación de Pruebas", juez: "Raul Santos Lopez", lugar:"Privada del Juez", fecha:"28 de abril 2024", horario:"11:30:00", estatus:"Programada" },
                   {  id:2, tipo: "Presentación de Pruebas", juez: "Raul Santos Lopez", lugar:"Privada del Juez", fecha:"28 de abril 2024", horario:"11:30:00", estatus:"Programada"},
                   {  id:3, tipo: "Presentación de Pruebas", juez: "Raul Santos Lopez", lugar:"Privada del Juez", fecha:"27 de abril 2024", horario:"11:30:00", estatus:"Incomparecencia"},
                   {  id:4, tipo: "Audiencia Inicial", juez: "Raul Santos Lopez", lugar:"Sala 1", fecha:"26 de abril 2024", horario:"11:30:00", estatus:"Desahogada"},
                   {  id:5, tipo: "Audiencia Inicial", juez: "Raul Santos Lopez",  lugar:"Sala 1", fecha:"25 de abril 2024", horario:"11:30:00", estatus:"Desahogada"},
                   {  id:6, tipo: "Audiencia Inicial", juez: "Raul Santos Lopez",  lugar:"Sala 1", fecha:"24 de abril 2024", horario:"11:30:00", estatus:"Desahogada"},
               ],
               page: {
                   number: 0,
                   size: 25,
                   totalElements: 4,
                   totalPages: 1
               }
           }
           setAudiencias(audienciasData.content);
           setPageable(audienciasData.page);
       } catch (error) {

       } finally {

       }
   }

    const handleOpen = () => {
        setOpen(true);
    };

    // Función para cerrar el modal
    const handleClose = () => {
        setOpen(false);
    };
    const handleAcuerdo = () => {

    }

    const handleNotificacion = () => {

    }
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return (<>
        <Box mb={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <Link to="">
                    <ButtonAdd />
                </Link>
                &nbsp;
                <RefreshButton onClick={loadAudiencias} />
            </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>Tipo</TableCell>
                                <TableCell className='table-header'>Juez</TableCell>
                                <TableCell className='table-header'>Lugar</TableCell>
                                <TableCell className='table-header'>Fecha</TableCell>
                                <TableCell className='table-header'>Horario</TableCell>
                                <TableCell className='table-header'>Estatus</TableCell>
                                <TableCell className='table-header'>Acciones rápidas</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(audiencias ?? [])
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((acuerdo) => (
                                    <StyledTableRow key={acuerdo.id} hover style={{ cursor: 'pointer' }}>
                                        <TableCell>{acuerdo.tipo}</TableCell>
                                        <TableCell>{acuerdo.juez}</TableCell>
                                        <TableCell>{acuerdo.lugar}</TableCell>
                                        <TableCell>{acuerdo.fecha}</TableCell>
                                        <TableCell>{acuerdo.horario}</TableCell>
                                        <TableCell>{acuerdo.estatus}</TableCell>

                                        <TableCell>

                                            {(acuerdo.estatus == 'Programada') && (
                                                <IconButton onClick={handleOpen}>
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            )}
                                            {(acuerdo.estatus == 'Programada') && (
                                                <IconButton>
                                                    <DoNotDisturbAltIcon />
                                                </IconButton>
                                            )}




                                        </TableCell>


                                    </StyledTableRow>
                                ))}
                            {audiencias.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay Documentos.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modal</DialogTitle>
                <DialogContent>
                    CONTENIDO POR CONFIRMAR
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>)
}

export default Audiencias;