import { useNavigate, useLocation } from "react-router-dom";
import Acciones from "../../../../../components/Acciones/Acciones";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState, useContext } from "react";
import { fetchTipoPartesAcuerdo } from "./AcuerdoService";
import keycloakConf from "../../../../../api/keycloakConf";
import texto from '../../../../../assets/Glosario';

const AcuerdoNotificacion = () => {
    const location = useLocation();
    const { item } = location.state || {};
    
    const [notificarA, setNotificarA] = useState("");
    const [especificar, setEspecificar] = useState("");
    const [partes, setPartes] = useState([]);
    const [notiicados, setNotificados] = useState([]);
    const { getToken } = useContext(keycloakConf);

    const accion1 = () => {

    }
    const accion2 = () => {

    }

    const handleNotificarA = async (e) => {
       setNotificarA(e.target.value);
        const response = await fetchTipoPartesAcuerdo(getToken(), item.carpetaId, e.target.value);
        setPartes(response);
    }

    const handleEspecificar = (e) => {
        setEspecificar(e.target.checked)
    }

    const handleCheckParte = () => {

    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return <>
        <Acciones accion1={accion1} accion2={accion2} textoAccion2="CERRAR" />
        <Box sx={{ marginTop: 2, marginBottom: 1 }}>
            <Divider />
        </Box>
        <Box display="flex" ml={2} mr={2} justifyContent="space-between" alignItems="center">
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="select-tamanio-papel-label">Notificar a: </InputLabel>
                        <Select
                            name="notificarAID"
                            labelId="select-notificarA-label"
                            value={notificarA || ""}
                            onChange={handleNotificarA}
                            label="Tamaño de papel"
                        >
                            <MenuItem value="actor">Parte actora</MenuItem>
                            <MenuItem value="demandado">Parte demandada</MenuItem>
                            <MenuItem value="ambos">Ambos</MenuItem>
                            <MenuItem value="otros">Otros</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>


                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={especificar}
                                onChange={(e) => { handleEspecificar(e) }}
                            />
                        }
                        label="Especificar"
                    />

                    <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                        <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'></TableCell>
                                <TableCell className='table-header'>{texto.Nombre}</TableCell>
                                <TableCell className='table-header'>{texto.TipoAcuerdo}</TableCell>
                                <TableCell className='table-header'>{texto.Metodo}</TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                {partes.map((parte) => (
                                    <StyledTableRow
                                        key={parte.id}
                                        hover
                                        style={{ cursor: 'pointer' }}>
                                        <TableCell> <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={notiicados.some(
                                                        item => item.id === parte.id
                                                    )}
                                                    onChange={(e) => {
                                                        handleCheckParte(parte, e.target.checked)
                                                    }}
                                                />
                                            }
                                        /></TableCell>
                                        <TableCell>{parte.nombre}</TableCell>
                                        <TableCell>{parte.tipo}</TableCell>
                                        <TableCell>{parte.metodo}</TableCell>
                                    </StyledTableRow>
                                ))}
                                {partes.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No hay registros disponibles.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </Box>
    </>
}

export default AcuerdoNotificacion;