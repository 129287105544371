import React from "react";
import {
  Box,
  Modal,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const ConfirmationModal = ({
  isModalOpen,
  setModalOpen,
  cartItems,
  handleConfirmarSalida,
  formData,
}) => {
  const buttonStyle = { textTransform: "none" };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflowY: "auto",
  };

  const tiposEntrada = cartItems.reduce((acc, item) => {
    acc[item.tipoEntrada] = (acc[item.tipoEntrada] || 0) + 1;
    return acc;
  }, {});

  const destinosUnicos = Array.from(
    new Set(cartItems.map((item) => item.juzgado))
  );

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-confirmar-salida-title"
      aria-describedby="modal-confirmar-salida-description"
    >
      <Box sx={modalStyle}>
        <h3 id="modal-confirmar-salida-title">¿Confirmar salida?</h3>

        {cartItems.length === 0 ? (
          <Typography id="modal-confirmar-salida-description">
            No hay elementos en el carrito.
            </Typography>
        ) : (
          <>
            <Typography id="modal-confirmar-salida-description">
              {"Responsable: "}
              <span style={{ fontWeight: "bold" }}>
                {formData?.respEntrega?.nombreCompleto || ""}
              </span>
            </Typography>
            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(tiposEntrada).map(([tipo, cantidad]) => (
                    <TableRow key={tipo}>
                      <TableCell align="center">{tipo}</TableCell>
                      <TableCell align="center">{cantidad}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Destinos</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {destinosUnicos.map((destino, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{destino}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                marginTop: 2,
              }}
            >
              <Button
                variant="outlined"
                className="button-close"
                onClick={() => setModalOpen(false)}
                style={{
                  ...buttonStyle,
                  borderColor: "#636569",
                  color: "#fff",
                }}
              >
                {"No, cancelar"}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  handleConfirmarSalida();
                }}
                style={{
                  ...buttonStyle,
                  borderColor: "#8c92bc",
                  color: "#fff",
                }}
                className="button-save"
              >
                {"Sí, dar salida"}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
