import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const StyledButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: '#8c92bc', // Color de fondo del botón
    color: 'white', // Color del ícono
    borderRadius: '50%', // Hace que sea circular
    '&:hover': {
        backgroundColor: '#afb3d0', // Color al pasar el mouse
    },
}));

const RefreshButton = ({ onClick, ariaLabel }) => {
    return (
        <Tooltip title="Actualizar">
            <StyledButton aria-label={ariaLabel} onClick={onClick}>
                <RefreshIcon />
            </StyledButton>
        </Tooltip>
    );
};

export default RefreshButton;
