import { Autocomplete, Box, Button, Divider, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Acciones from "../../components/Acciones/Acciones";
import { useEffect, useState, useCallback, useContext, useRef } from "react";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useToastAlert } from "../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertValidation } from "../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { validateFields } from "../../components/Alert/ValidationRequired/validationRequired";
import { onlyAlphaNumeric, onlyAlphaNumericSpace, onlyNumbers } from "../../components/Caracteres/caracteresEspeciales";
import { fetchInstitucionesAutoComplete } from "../Instituciones/institucionService";
import keycloakConf from "../../api/keycloakConf";
import { fetchCarpetaActorDemandado } from "../../shared/services/CarpetaService";
import ConfirmationDialog from "../../components/Alert/ConfirmationDialog/ConfirmationDialogGeneric";
import FormInstitucion from "../Instituciones/Form/FormInstitucion";
import { generarFolio } from "./RegistroOficiosService";
import { useNavigate } from "react-router-dom";

const RegistroOficios = () => {
    const [instituciones, setInstituciones] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenInstitucion, setModalOpenInstitucion] = useState(false);
    const [modalOpenOficioGenerado, setModalOpenOficioGenerado] = useState(false);
    const [errors, setErrors] = useState({});
    const { showSnackbar, showConfirmDialog } = useToastAlert();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const { getToken } = useContext(keycloakConf);
    const [carpeta, setCarpeta] = useState({});
    const references = useRef(); //refierencias hacia las importaciones
    const navigate = useNavigate();
    const [formdata, setFormData] = useState({
        tipoOficio: "jurisdiccional",
        numeroExpediente: "",
        anioExpediente: "",
        institucionId: "",
        asunto: "",
        fechaEmision: ""
    });

    const formatos = {
        'numeroExpediente': onlyNumbers,
        'anioExpediente': onlyNumbers,
        'asunto': onlyAlphaNumericSpace
    }

    useEffect(() => {
        loadInstituciones();
    }, []);

    const limpiarFormulario = () => {
        setFormData({
            tipoOficio: "jurisdiccional",
            numeroExpediente: "",
            anioExpediente: "",
            institucionId: "",
            asunto: "",
            fechaEmision: ""
        });

        setCarpeta({});

    }

    const loadInstituciones = async () => {
        try {
            const token = getToken();
            let instituciones = await fetchInstitucionesAutoComplete(token, 0, 20);
            setInstituciones(instituciones.content);

        } catch (error) {
            console.log("Error al obtener las instituciones");
        }

    };

    const handleToggleChange = useCallback((event, newAlignment) => {
        setFormData({
            ...formdata,
            tipoOficio: newAlignment,
            numeroExpediente: "",
            anioExpediente: "",
        });
    }, [formdata]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;


        if (name === "institucionId" && value === "otra") {
            setModalOpenInstitucion(true);
        }


        // Verificar si el campo es uno que necesita la función de limpieza
        const caracterValue = (formatos[name] || ((val) => val))(value);

        setFormData((prevState) => ({
            ...prevState,
            [name]: caracterValue,
        }));


    };

    const handlerSave = async () => {
        try {
            let dataSend = {
                institucionId: formdata.institucionId,
                fechaEmision: formdata.fechaEmision,
                asunto: formdata.asunto,
                carpetaId: carpeta.idCarpeta
            }

            let response = await generarFolio(getToken(), dataSend);
            showAlertValidate('Oficio generado con éxito con el número ' + response, 'success', '¡Excelente!');
            limpiarFormulario();
        } catch (error) {

        }
    };

    const handlerCancel = () => {
        // Implementar la lógica para cancelar
        navigate('/api/bandeja/oficios');
    };

    const handleValidate = async (imprimirMensaje = true) => {
        const token = getToken();
        let rulesExpediente = {
            numeroExpediente: { required: true },
            anioExpediente: { required: true }
        }

        const errores = validateFields(formdata, rulesExpediente, showAlertValidate);
        setErrors(errores);

        if (Object.keys(errores).length === 0) {
            const data = {
                "numExpediente": formdata.numeroExpediente,
                "year": formdata.anioExpediente,
                "idJuzgado": null
            }

            try {
                const response = await fetchCarpetaActorDemandado(token, data)
                setCarpeta({
                    idCarpeta: response.idCarpeta,
                    actor: response.actor,
                    demandado: response.demandado
                });
                if (imprimirMensaje) {
                    showAlertValidate("Expediente encontrado", "success", "Validación exitosa");
                }


            } catch (error) {
                setCarpeta({});
                showAlertValidate('Expediente inexistente.', 'error', 'Error en la validación');
            }
        }
    }

    const validacionCampos = () => {
        let rules = {
            tipoOficio: { required: true },
            numeroExpediente: { required: formdata.tipoOficio === "jurisdiccional" },
            anioExpediente: { required: formdata.tipoOficio === "jurisdiccional" },
            institucionId: { required: true },
            asunto: { required: true },
            fechaEmision: { required: true }
        }

        let validationErrors = validateFields(formdata, rules, showAlertValidate);

        if (formdata.institucionId === "otra") {
            validationErrors.institucionId = "Seleccione una opción correcta"
        }

        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            if (formdata.tipoOficio === "jurisdiccional") {
                handleValidate(false);
                handlerSave();
            } else {
                handlerSave();
            }

        }
    }

    return (
        <>
            <Acciones accion1={validacionCampos} accion2={() => {
                let informacionVacia = true;
                for(const key of Object.keys(formdata) ){
                    if(key != 'tipoOficio' && formdata[key] != ""){
                       informacionVacia = false;                      
                    }
                }

                if(informacionVacia){
                    navigate('/api/bandeja/oficios');
                }else{
                    setModalOpen(true);
                }

                

            } } />
            <Box sx={{ marginTop: 2, marginBottom: 4 }}>
                <Divider />
            </Box>

            <Grid container spacing={2}>

                <Grid item xs={12} sm={12} mb={2}>
                    <FormControl component="fieldset" fullWidth error={!!errors.tipoOficio}>
                        <FormLabel component="legend">Tipo de oficio:</FormLabel>
                        <ToggleButtonGroup
                            color="primary"
                            value={formdata.tipoOficio}
                            exclusive
                            onChange={handleToggleChange}
                            aria-label="Tipo de oficio"
                        >
                            <ToggleButton value="jurisdiccional">Jurisdiccional</ToggleButton>
                            <ToggleButton value="administrativo">Administrativo</ToggleButton>
                        </ToggleButtonGroup>
                        {errors.tipoOficio && <FormHelperText>{errors.tipoOficio}</FormHelperText>}
                    </FormControl>
                </Grid>

                {formdata.tipoOficio === "jurisdiccional" && (
                    <>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Número de expediente"
                                variant="outlined"
                                name="numeroExpediente"
                                value={formdata.numeroExpediente}
                                onChange={handleInputChange}
                                disabled={formdata.tipoOficio === "administrativo"}
                                fullWidth
                                error={!!errors.numeroExpediente}
                                helperText={errors.numeroExpediente}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Año de expediente"
                                variant="outlined"
                                name="anioExpediente"
                                inputProps={{
                                    maxLength: 4,
                                }}
                                value={formdata.anioExpediente}
                                onChange={handleInputChange}
                                disabled={formdata.tipoOficio === "administrativo"}
                                fullWidth
                                error={!!errors.anioExpediente}
                                helperText={errors.anioExpediente}
                            />
                        </Grid>


                        <Grid item xs={12} mb={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleValidate}
                            >
                                Validar
                            </Button>
                        </Grid>
                    </>
                )}

                {(formdata.tipoOficio === "administrativo" || (formdata.tipoOficio === "jurisdiccional" && Object.keys(carpeta).length > 0)) && (

                    <>
                        <Grid item xs={12} sm={8}>
                            <Autocomplete
                                options={
                                    [ ...instituciones, { id: 'otra', nombre: 'Registrar nueva' }].filter(
                                        (option, index, self) =>
                                            index === self.findIndex((o) => o.id === option.id) // Eliminar duplicados
                                    )
                                }
                                getOptionLabel={(option) => option.nombre}
                                value={
                                    instituciones.find((institucion) => institucion.id === formdata.institucionId) || null
                                }
                                onChange={(event, newValue) => {
                                    handleInputChange({
                                        target: {
                                            name: 'institucionId',
                                            value: newValue ? newValue.id : ''
                                        }
                                    });
                                }}
                                filterOptions={(options, state) => {
                                    const filtered = options.filter((option) =>
                                        option.nombre.toLowerCase().includes(state.inputValue.toLowerCase())
                                    );
                                    // Asegurarse de que "Registrar nueva" siempre esté visible
                                    if (!filtered.some((option) => option.id === 'otra')) {
                                        filtered.unshift({ id: 'otra', nombre: 'Registrar nueva' });
                                    }
                                    return filtered;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seleccione una dependencia"
                                        variant="outlined"
                                        error={!!errors.institucionId}
                                        helperText={errors.institucionId}
                                        required
                                    />
                                )}
                            />


                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Fecha de emisión"
                                type="date"
                                name="fechaEmision"
                                value={formdata.fechaEmision}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors.fechaEmision}
                                helperText={errors.fechaEmision}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                label="Asunto"
                                name="asunto"
                                fullWidth
                                variant="outlined"
                                value={formdata.asunto}
                                onChange={handleInputChange}
                                error={!!errors.asunto}
                                helperText={
                                    <Box display="flex" justifyContent="space-between">
                                        <span>{errors.asunto}</span>
                                        <span>{`${formdata.asunto.length}/150 caracteres`}</span>
                                    </Box>
                                }
                                inputProps={{ maxLength: 150 }}
                            />
                        </Grid>


                    </>
                )}

            </Grid>

            {  /*  Seccion de modales */}
            <ConfirmationDialog
                open={modalOpenInstitucion}
                onClose={() => setModalOpenInstitucion(false)}

                onConfirm={() => { references.current?.guardar() }}
                title="Agregar institución"
                message={
                    <FormInstitucion
                        token={getToken()}
                        onCancel={async () => {
                            await loadInstituciones();
                            setModalOpenInstitucion(false);

                        }
                        }
                        ref={(el) => { references.current = el; }}
                    />
                }
                btnLeft="Cerrar"
                btnRight="Guardar"
                widthGeneric="90%"
                autoClose={false}
            ></ConfirmationDialog>


            <ConfirmationModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handlerCancel}
                title="¿Salir sin generar oficio?"
                message="Se perderán los datos del oficio"
                confirmationButton="Sí, salir"
                closeButton="No, cancelar"
            />
        </>
    );
};

export default RegistroOficios;
