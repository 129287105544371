import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Divider, Grid, Typography, TextField } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TablaActores from "./TablaActores";
import KeycloakConf from '../../../../../api/keycloakConf';
import { fetchInfoExpediente } from '../../../../../shared/services/CarpetaService';
import { useExpediente } from '../../../../../components/Breadcrumb/ExpedienteContext';


const demandTypeConfig = {
    laboral: {
        tipo: "Laboral",
        fields: [
            { key: "tipoJuicio", label: "Tipo de juicio", editable: false },
            { key: "juezAsignado", label: "Juez Asignado", editable: false },
            { key: "fechaPresentacion", label: "Fecha de Presentación", editable: false },
            { key: "tipoProcedimiento", label: "Tipo de procedimiento", editable: false },
            { key: "rubros", label: "Rubros", editable: false },
            { key: "etapaProcesal", label: "Etapa procesal", editable: false },
        ],
    },
    penal: {
        tipo: "Penal",
        fields: [
            { key: "tipoCausa", label: "Tipo de causa", editable: false },
            { key: "juezAsignado", label: "Juez Asignado", editable: false },
            { key: "fechaPresentacion", label: "Fecha de Presentación", editable: false },
            { key: "asunto", label: "Asunto", editable: false },
            { key: "etapaProcesal", label: "Etapa procesal", editable: false },
        ],
    },
    exhorto: {
        tipo: "Exhorto",
        fields: [
            { key: "fechaPresentacion", label: "Fecha de Presentación", editable: false },
        ],
    },
    other: {
        tipo: "Mercantil, Civil, Familiar",
        fields: [
            { key: "tipoJuicio", label: "Tipo de Acciones", editable: false },
            { key: "juezAsignado", label: "Juez Asignado", editable: false },
            { key: "fechaPresentacion", label: "Fecha de Presentación", editable: false },
            { key: "etapaProcesal", label: "Etapa procesal", editable: false },
        ],
    }
};

const General = ({item}) => {
    const { getToken } = useContext(KeycloakConf);
    const { setExpedienteSeleccionado } = useExpediente();
    const [general, setGeneral] = useState({
        expediente: "",
        tipoJuicio: "",
        tipoCausa: "",
        juezAsignado: "",
        fechaPresentacion: "",
        asunto: "",
        tipoProcedimiento: "",
        rubros: "",
        etapaProcesal: "",
        participantes: [],
        razonDevolucion: ""
    });
    const [tipoDemanda, setTipoDemanda] = useState("");

    const getTipoDemanda = (tipoJuicio, numExpediente) => {
        if (!tipoJuicio) return "";
        const tipoJuicioLower = tipoJuicio.toLowerCase();

        const materias = ["laboral", "mercantil", "civil", "familiar", "penal", "exhorto"];
        const tipoJuicios = ["oral", "tradicional", "mixto"];

        const materia = materias.find(m => tipoJuicioLower.includes(m));
        const procedimiento = tipoJuicios.find(p => tipoJuicioLower.includes(p));
        
        let demandType = materia;
        let expedienteSeleccionado = "Expediente ";

        if(materia){
            if (materia === "mercantil" ||
                materia === "civil" ||
                materia === "familiar" ){
                    demandType = "other";
            }

            if(procedimiento === "oral"){
                expedienteSeleccionado = "Carpeta Digital ";
            }

            if(materia === "penal"){
                expedienteSeleccionado = "Causa ";
            }

            if(materia === "exhorto"){
                expedienteSeleccionado = "Exhorto ";
            }
        }
        
        //asigna expediente para breadcrums
        expedienteSeleccionado = expedienteSeleccionado + numExpediente;
        setExpedienteSeleccionado(expedienteSeleccionado);
        localStorage.setItem('expedienteSeleccionado', JSON.stringify(expedienteSeleccionado));

        return demandType;
    };
    
    const loadInfoExpediente = async () => {
        const token = getToken();
        try {
            const response = await fetchInfoExpediente(token, item.id);
            setGeneral(response);
            const tipoDemanda = getTipoDemanda(response.tipoJuicio, response.expediente);
            setTipoDemanda(tipoDemanda);
        } catch (error) {
            console.error('Error cargando información de expediente:', error);
        }
    };
    
    useEffect(() => {
        loadInfoExpediente();
    }, []);

    const handleDetalle = () => {

    }
    const handleDeleteParticipante = (id) => {
        setGeneral((prev) => ({
            ...prev,
            participantes: prev.participantes.map((participante) => ({
                ...participante,
                data: participante.data.filter((actor) => actor.id !== id),
            })),
        }));
    };
    
    
    const demandaConfig = demandTypeConfig[tipoDemanda] || {};

    const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                <Button variant="contained" className="button-save" onClick={handleDetalle}>
                    Detalle
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
            <Typography style={{ color: "Red" }}> {general.razonDevolucion || ""} </Typography>
            </Grid>
        </Grid>

        <form>
            <Grid container spacing={2} sx={{ mt: 2, mb: 5 }}>
                {demandaConfig.fields?.map(field => (
                    <Grid item xs={12} sm={12} md={4} sx={{ mb: 2 }} key={field.key}>
                        {field.editable ? (
                            <TextField
                                label={field.label}
                                name={`general.${field.key}`}
                                value={general[field.key] || ""}
                                onChange={(e) => setGeneral(prev => ({ ...prev, [field.key]: e.target.value }))}
                                fullWidth
                            />
                        ) : (
                            <>
                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                    {field.label}:
                                </Typography>
                                <Typography>{general[field.key] || ""}</Typography>
                            </>
                        )}
                    </Grid>
                ))}
            </Grid>
        </form>

        <Box sx={{ marginTop: 2 }}>
            <Divider />
        </Box>

        <Typography variant="h5">Participantes</Typography>

        {general.participantes.map((participante, index) => (

            <Accordion key={`accordion-header-${participante.tipo}-${index}`}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography variant="body1">{participante.tipo}</Typography>
                </AccordionSummary>
                    <AccordionDetails>
                        {/* Campos mostrados para expediente */}
                        {participante.tipo === "Actor"  && (
                                <TablaActores key={`${participante.tipo}-${index}`} data={ participante.data } tipo={"Actor"}  onDelete={handleDeleteParticipante} />
                        )}
                        {participante.tipo === "Demandado" && (
                                <TablaActores key={`${participante.tipo}-${index}`} data={ participante.data } tipo={"Demandado"} onDelete={handleDeleteParticipante} />
                        )}
                        {participante.tipo === "Ministerio Público" && (
                                <TablaActores key={`${participante.tipo}-${index}`} data={ participante.data } tipo={"Ministerio Público"} onDelete={handleDeleteParticipante} />
                        )}
                        {participante.tipo === "Tercero Involucrado" && (
                                <TablaActores key={`${participante.tipo}-${index}`} data={ participante.data } tipo={"Tercero Involucrado"} onDelete={handleDeleteParticipante} />
                        )}
                        
                        {/* Campos mostrados para penal */}
                        {participante.tipo === "Víctimas" && (
                                <TablaActores key={`${participante.tipo}-${index}`} data={ participante.data } tipo={"Víctimas"} onDelete={handleDeleteParticipante} />
                        )}
                        {participante.tipo === "Imputados" && (
                                <TablaActores key={`${participante.tipo}-${index}`} data={ participante.data } tipo={"Imputados"} onDelete={handleDeleteParticipante} />
                        )}
                        {participante.tipo === "Parte Ofendida" && (
                                <TablaActores key={`${participante.tipo}-${index}`} data={ participante.data } tipo={"Parte Ofendida"} onDelete={handleDeleteParticipante} />
                        )}
                    </AccordionDetails>
            </Accordion>

        ))}

    </>

}

export default General;