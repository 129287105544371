import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import KeycloakConf from '../../../api/keycloakConf';
import FormBloque from './FormBloque';

const EditBloque = () => {
  const location = useLocation();
  const { item } = location.state || {};
  const { getToken } = useContext(KeycloakConf);

  const navigate = useNavigate();

  return (
    <div>
      {item ? (
        <FormBloque
          token={getToken()}
          onAdd={async () => {
            navigate('/api/core/bloques');
          }}
          onEdit={item}
        />
      ) : (
        <p>No se encontró el registro.</p>
      )}
    </div>
  );
};

export default EditBloque;
