import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import FormPersona from './FormPersona';
import keycloakConf from '../../../../api/keycloakConf';

const EditPersona = () => {
  const location = useLocation();
  const { item } = location.state || {};
  const { getToken } = useContext(keycloakConf);
  const navigate = useNavigate();

  return (
    <div>
      {item ? (
        <FormPersona
          token={getToken()}
          onAdd={async () => {
            navigate('/api/core/personas');
          }}
          onEdit={item}
        />
      ) : (
        <p>No se encontró el registro.</p>
      )}
    </div>
  );
};

export default EditPersona;
