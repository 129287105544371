import React from 'react';
import {TablePagination} from '@mui/material';

export default function PaginationGeneric({ 
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  }) {
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    return ( 
        <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={pageable.totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) => `Registros ${from} – ${to} de ${count}`}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )
}
