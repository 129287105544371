import axios from 'axios';

export const fetchDistritos = async (token) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/distritos`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data.content;
    } catch (error) {
        console.error('Error al listar distritos:', error);
        throw error;
    }
};