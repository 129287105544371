import React from 'react';
// import { Button } from '@mui/material';

const CustomButton = ({ children, onClick, ...props }) => {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <span {...props}>{children}</span>
    </div>
  );
};
export default CustomButton;

