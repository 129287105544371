import axios from 'axios';

/**
 * Función para obtener todos los documentos asignados
 */
export const fetchAsignados = async (token, page = 0, size = 10, sort = '', key = '') => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/asignados`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
        key
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener los documentos asignados:', error);
    throw error;
  }
};


//Turnar
export const postTurnado = async (token, turnados, persona) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/asignados`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener los documentos asignados:', error);
    throw error;
  }
};


//Notificaciones
export const fetchNotificaciones = async (token, page = 0, size = 10, sort = '', key = '', expediente) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/asignados`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
        key
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener los documentos asignados:', error);
    throw error;
  }
};


export const deleteParticipante = async (token, id) => {
  try {
      const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/asignados/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      });
  
      return response.data;
  } catch (error) {
      throw error;
  }
};