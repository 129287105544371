import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../../../../api/keycloakConf";
import Acciones from "../../../../../components/Acciones/Acciones";
import { useToastAlertValidation } from "../../../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { validateFields } from "../../../../../components/Alert/ValidationRequired/validationRequired";
import { onlyAlphaNumericSpace, onlyNumbers } from "../../../../../components/Caracteres/caracteresEspeciales";
import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import General from "./Tabs/General";
import AcuerdoTab from "./Tabs/AcuerdoTab";
import { useNavigate, useLocation } from "react-router-dom";
import { crearAcuerdo, fetchEtapasProcesaes, fetchPromocionesCarpeta, fetchPublicarAcuerdo, fetchRubrosAutoComplete, fetchTipoAcuerdos } from "./AcuerdoService";
import { useExpediente } from "../../../../../components/Breadcrumb/ExpedienteContext";

const Acuerdo = () => {

    const { getToken } = useContext(keycloakConf);
    const location = useLocation();
    const { item } = location.state || {};

    
    const { setExpedienteSeleccionado } = useExpediente();
    const [errors, setErrors] = useState({});
    const tipoJuicio = 100; //TODO: debe de enviarse desde el grid, AUN NO SE HA VALIDADO COMO HACERLO.
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [etapasProcesales, setEtapasProcesales] = useState([]);
    const [tiposAcuerdos, setTiposAcuerdos] = useState([]);
    const [promociones, setPromociones] = useState([]);
    const [rubros, setRubros] = useState([]);
    const navigate = useNavigate();
    const [tabvalue, setTabValue] = useState(0);
    const formatos = {
        'numeroExpediente': onlyNumbers,
        'anioExpediente': onlyNumbers,
        'asunto': onlyAlphaNumericSpace
    }
    const [formdata, setFormData] = useState({
        general: {
            tipoAcuerdo: "",
            fechaResolucion: "",
            etapaProcesal: "",
            resumen: "",
            rubros: [],
            promocionesRelacionadas: []
        },
        acuerdo: {
            acuerdoId:"301",
            tamanioPapel: "",
            textoEditor: "",
            acuerdoPublicado: ""
        }
    });

    useEffect(() => {
        loadEtapasProcesales();
        loadRubros();
        loadTipoAcuerdos();
        loadPromociones();
       
    }, [])

    const loadEtapasProcesales = async () => {
        const token = getToken();
        try {
            let etapasProcesales = await fetchEtapasProcesaes(token, tipoJuicio);
            setEtapasProcesales(etapasProcesales);
        } catch (error) {
            console.log(error);
        }
    }

    const loadRubros = async (query = "") => {
        const token = getToken();
        try {
            let rubros = await fetchRubrosAutoComplete(token, 0, 200, item.id, query);
            setRubros(rubros.content);
        } catch (error) {
            console.log("Ha ocurrido un error: ", error);
        }
    }

    const loadTipoAcuerdos = async () => {
        const token = getToken();
        try {
            let rubros = await fetchTipoAcuerdos(token, item.id);
            setTiposAcuerdos(rubros);
        } catch (error) {
            console.log("Ha ocurrido un error: ", error);

        }
    }

    const loadPromociones = async () => {
        const token = getToken();
        try {
            let promociones = await fetchPromocionesCarpeta(token, item.carpetaId);
            setPromociones(promociones);
        } catch (error) {
            console.log("Ha ocurrido un error: ", error);

        }
    }

    const handlerSave = async (publicarAcuerdo=false) => {
        try {
            const dataSend = {
                acuerdoId: formdata.acuerdo.acuerdoId,
                carpetaId: item.carpetaId,
                documentoId: item.id,
                tipoAcuerdo: formdata.general.tipoAcuerdo,
                fechaPublicacion: formdata.general.fechaResolucion,
                etapaProcesal: formdata.general.etapaProcesal,
                rubros: formdata.general.rubros,
                promocionesRelacionadas: formdata.general.promocionesRelacionadas,
                tamanioPapel: formdata.acuerdo.tamanioPapel,
                textoEditor: formdata.acuerdo.textoEditor,
                resumen: formdata.general.resumen
            }

            if(publicarAcuerdo){
                let response = await fetchPublicarAcuerdo(getToken(), dataSend);
                navigate('/api/workflow/acuerdo/acuerdoNotificacion',{
                     state:{ item:response }
                })
                
                showAlertValidate('Acuerdo publicado con éxito ', 'success', '¡Excelente!');
            }else{
                if(formdata.acuerdo.acuerdoId){
               
                    showAlertValidate('Acuerdo actualizado con éxito ', 'success', '¡Excelente!');
                }else{
                   
                    let response = await crearAcuerdo(getToken(), dataSend);
                    setFormData((prevState) => ({
                        ...prevState,
                        acuerdo: {
                            ...prevState.acuerdo,
                            acuerdoId: response.id
                        }
                    }));
                       
    

                    loadPromociones();
                    showAlertValidate('Acuerdo generado con éxito ', 'success', '¡Excelente!');
                }
            }
           

        } catch (error) {

        }
    };

    const validacionCampos = (publicarAcuerdo) => {

        
        let rules = {
            "general.tipoAcuerdo": { required: true },
            "general.fechaResolucion": { required: true },
            "general.etapaProcesal": { required: true },
            "general.rubros": { required: true },
            "general.resumen": { required: true },
            "acuerdo.tamanioPapel": { required: true },
            "acuerdo.textoEditor": { required: true }
        }

        let validationErrorsGeneral = validateFields(formdata, rules, showAlertValidate);

        //valdiaciones de arreglos:
        if (formdata.general.rubros.length === 0) {
            validationErrorsGeneral['general.rubros'] = "Es necesario elegir al menos un rubro";
        }

        setErrors(validationErrorsGeneral);
        
        if (Object.keys(validationErrorsGeneral).length === 0) {
            handlerSave(publicarAcuerdo);
        }
      
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        
        // Verificar si el campo es uno que necesita la función de limpieza
        const caracterValue = (formatos[name] || ((val) => val))(value);

        // Dividir el name para saber en qué parte del objeto anidado debe ir el valor
        const nameParts = name.split('.');  // Dividimos por el punto: ["general", "acuerdo"]

        setFormData((prevState) => {
            let newFormData = { ...prevState };  // Clonamos el estado actual

            // Navegamos hasta el nivel correcto del objeto anidado
            let currentLevel = newFormData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel = currentLevel[nameParts[i]];
            }

            // Actualizamos el campo correcto
            currentLevel[nameParts[nameParts.length - 1]] = caracterValue;

            return newFormData;
        });

    };

    const handleTabChange = (event, tabnewValue) => {
        setTabValue(tabnewValue);
    }

    return (
        <>
            <Acciones accion1={ () => { validacionCampos(false) }} accion2={() => { navigate("/api/bandeja/expediente") }} textoAccion2="CERRAR" />
            <Box sx={{ marginTop: 2, marginBottom: 1 }}>
                <Divider />
            </Box>
            <Box display="flex" ml={2} mr={2} justifyContent="space-between" alignItems="center">
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={8}>
                        <Tabs value={tabvalue} onChange={handleTabChange}>
                            <Tab label="General" />
                            <Tab label="Acuerdo" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={4}></Grid>

                    {tabvalue === 0 && (
                        <General
                            formData={formdata}
                            setFormData={setFormData}
                            handleInputChange={handleInputChange}
                            errors={errors}
                            etapasProcesales={etapasProcesales}
                            tiposAcuerdos={tiposAcuerdos}
                            promociones={promociones}
                            rubros={rubros}
                        />
                    )}

                    {tabvalue === 1 && (
                        <AcuerdoTab
                            formData={formdata}
                            handleInputChange={handleInputChange}
                            setFormData={setFormData}
                            errors={errors}
                            validacionCampos={validacionCampos}
                        />
                    )}

                </Grid>
            </Box>



        </>
    )
}

export default Acuerdo;