import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Button } from '@mui/material';
import keycloakConf from '../../api/keycloakConf';
import { getAllTipoJuicio } from './DemandaService';
import DemandaTable from './Grid/DemandaTable';
import SearchInput from '../../components/Search/SearchInput';
import { useNavigate } from 'react-router-dom';
import { useJuicio } from '../../components/Breadcrumb/JuicioContext';

export default function Demanda() {
  const navigate = useNavigate();
  const { setJuicioSeleccionado } = useJuicio();
  const [datosAgrupados, setDatosAgrupados] = useState([]);
  const [datosTabla, setDatosTabla] = useState([]);
  const { getToken } = useContext(keycloakConf);
  const [openMaterias, setOpenMaterias] = useState({});

  const handleCancel = () => {
    navigate('/api/bandeja/entrada');
  };

  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        const token = getToken();
        if (token) {
          const dataJuicios = await getAllTipoJuicio(token);

          const agrupados = dataJuicios.content.reduce((acc, juicio) => {
            const materia = juicio.materiaRecord.nombre;
            if (!acc[materia]) {
              acc[materia] = [];
            }
            acc[materia].push(juicio);
            return acc;
          }, {});

          const datos = Object.entries(agrupados).map(([materia, juicios]) => ({
            materia,
            juicios,
          }));

          setDatosAgrupados(datos);
          setDatosTabla(datos);
         }
      } catch (error) {
        console.error('Error al cargar los juicios:', error);
      }
    };

    obtenerDatos();
  }, [getToken]);

  
  const onChange = (searchTerm) => {
    const filteredMaterias = datosAgrupados
    .map(({ materia, juicios }) => {
      const filteredItems = juicios.filter((item) =>
        item.nombre.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return {
        materia,
        juicios: filteredItems,
      };
    })
    .filter(({ juicios }) => juicios.length > 0);

    setDatosTabla(filteredMaterias);

    const nuevasMateriasAbiertas = filteredMaterias.reduce((acc, { materia }) => {
      acc[materia] = true;
      return acc;
    }, {});
    setOpenMaterias(nuevasMateriasAbiertas);
  };

  const handleJuicioSeleccionado = (juicio) => {
    setJuicioSeleccionado(juicio);
    localStorage.setItem('juicioSeleccionado', JSON.stringify(juicio));
  };

  return (
    <> 
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6} md={9} >
        <Button
          variant="contained"
          className="button-close"
          style={{ marginLeft: '10px' }}
          onClick={handleCancel}
        >
          Cerrar
        </Button>
      </Grid>

      <Grid item xs={12} sm={6} md={3} >
        <SearchInput
          onChange = {(e) => onChange(e.target.value)}
        />
      </Grid>
    </Grid>
    
    <Box   sx={{ padding: 3 }}>
      <DemandaTable
          datos={datosTabla}
          openMaterias={openMaterias}
          setOpenMaterias={setOpenMaterias}
          onJuicioSeleccionado={handleJuicioSeleccionado}
      />
    </Box>
    </>
  );
}
