import React from 'react';
import { TextField, InputAdornment, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
        '& fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.dark,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.dark,
        },
    },
}));

const SearchInput = ({ value, onChange, placeholder, ariaLabel, tooltipTitle, onKeyDown  }) => {
    return (
        <Tooltip title={tooltipTitle || "Buscar"}>
            <StyledTextField
                variant="outlined"
                type="search"
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder || "Buscar..."}
                aria-label={ariaLabel}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </Tooltip>
    );
};

export default SearchInput;
