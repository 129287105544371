import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { borderBottom, styled } from '@mui/system';

const StyledSnackbarContent = styled(SnackbarContent)(({ type }) => ({
  backgroundColor: 'white',
  color: type === 'success' ? 'green' : 'red',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  borderRadius: 4,
  borderBottom: type === 'success' ? '3px solid #66bb6a' : '3px solid #db5858',
  width: 'auto',
  maxWidth: 'calc(100% - 32px)',
}));

const ToastAlertDelete = ({ open, onClose, message, type }) => {
  const icon = type === 'success' ? <CheckCircleIcon /> : <ErrorIcon />;

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      <StyledSnackbarContent
        type={type}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 8 }}>
              {icon}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontWeight: 'bold' }}>
                {type === 'success' ? '¡Excelente!' : '¡Error!'}
              </div>
              <div style={{ color: 'black' }}>
                {message}
              </div>
            </div>
          </div>
        }
      />
    </Snackbar>
  );
};

export default ToastAlertDelete;
