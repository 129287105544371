import { Box, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import { useNavigate } from 'react-router-dom';
import SalidaIcon from '@mui/icons-material/Upload';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ApprovalIcon from '@mui/icons-material/Approval';
import EditNoteIcon from '@mui/icons-material/EditNote';
import text from "../../../assets/Glosario";

//Modulos acciones rapidas
import Digitalizacion from '../AccionesRapidas/Digitalizacion/Digitalizacion';
import { useState } from "react";
import { getDocumento } from "../BandejaService";
import { getCaratula, getSello, salidaDocumento } from "../../Demanda/DemandaService";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BandejaTable = ({
    bandejas,
    token,
    setIsDigitalizacion,
    loadBandejas,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
}

) => {
    const { showSnackbar } = useToastAlert();
    //States para cambiar a acciones rapidas.
    const [selectedDigitalizacion, setSelectedDigitalizacion] = useState(null);

    const navigate = useNavigate();

    //Metodos para renderizar componentes de acciones rapidas:
    const handleDigitalizacion = (documento) => {
        setSelectedDigitalizacion(documento); // Al hacer clic, actualiza el registro seleccionado
        setIsDigitalizacion(true);
    };

    const handleEditDemanda = (documentoId) => {
        const editarDemandaDocumento = '/api/workflow/demanda/edit';

        navigate(editarDemandaDocumento, {
            state: { documentoId }
        });

    }

    //Metodo para descargar el documento 
    const handleDownloadDocumento = async (item) => {

        try {
            const documento = await getDocumento(token, item.id);
            let blob = new Blob([documento], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob)
            link.target = item.ruta
            link.click()
        } catch (error) {
            console.error('Error al generar el documento:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }

    }

    //Metodo para descargar sello 
    const handleDownloadSello = async (item) => {

        try {
            const sello = await getSello(token, item.id);
            let blob = new Blob([sello], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob)
            link.target = item.ruta
            link.click()
        } catch (error) {
            console.error('Error al generar el documento:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }

    }

    //Metodo para descargar caratula 
    const handleDownloadCaratula = async (item) => {

        try {
            const sello = await getCaratula(token, item.id);
            let blob = new Blob([sello], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob)
            link.target = item.ruta
            link.click()
        } catch (error) {
            console.error('Error al generar el documento:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }

    }

    const handleSalida = async (item) => {
        try {
            const response = await salidaDocumento(token, item.id, 1);
            showSnackbar('Estatus actualizado con éxito', 'success');
            loadBandejas();

        } catch (error) {
            console.error('Error al cambiar status:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }

    }

    return (
        <Box sx={{ width: '100%' }}>
            {!selectedDigitalizacion ? (
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <PaginationGeneric
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                    <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                        <Table stickyHeader sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-header'>{text.Folio}</TableCell>
                                    <TableCell className='table-header'>{text.NoExpediente}</TableCell>
                                    <TableCell className='table-header'>{text.Mate}</TableCell>
                                    <TableCell className='table-header'>{text.TipoEntrada}</TableCell>
                                    <TableCell className='table-header'>{text.FechaHora}</TableCell>
                                    <TableCell className='table-header'>{text.Acciones}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bandejas.map((item) => (
                                    <StyledTableRow
                                        key={item.id}
                                        hover>
                                        <TableCell>{item.folio}</TableCell>
                                        <TableCell>{item.expediente}</TableCell>
                                        <TableCell>{item.materia}</TableCell>
                                        <TableCell>{item.tipoEntrada}</TableCell>
                                        <TableCell> {new Date(item.fechaRegistro).toLocaleString()} </TableCell>

                                        <TableCell align="center">
                                        <Stack direction="row">
                                            <Tooltip title="Digitalización">{!item.hasFile && <PictureAsPdfIcon className='icon-color' onClick={(event) => {event.stopPropagation(); handleDigitalizacion(item)}} />}</Tooltip>
                                            <Tooltip title="Vista previa">{item.hasFile && <FindInPageIcon className='icon-color' onClick={(event) => {event.stopPropagation(); handleDownloadDocumento(item)}} />}</Tooltip>
                                            <Tooltip title="Impresión de sello"><ApprovalIcon className='icon-color' onClick={(event) => {event.stopPropagation();handleDownloadSello(item)}} /></Tooltip>
                                            <Tooltip title="Impresión de carátula"><PrintIcon className='icon-color' onClick={(event) => {event.stopPropagation(); handleDownloadCaratula(item)}} /></Tooltip>
                                            <Tooltip title="Salida">{item.selloEstatus === 'VALIDO' && item.hasFile && <SalidaIcon className='icon-color' onClick={(event) => {event.stopPropagation();handleSalida(item)}} />}</Tooltip>
                                            <Tooltip title="Corrección" ><EditNoteIcon className='icon-color' onClick={() => handleEditDemanda(item.id)} /></Tooltip>
                                        </Stack>
                                        </TableCell>

                                    </StyledTableRow>
                                ))}
                                {bandejas.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No hay registros disponibles.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationGeneric
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Paper>
            ) : (
                // Si da clic en el botón digitalizacion, renderiza el componente Digitalizacion
                <Digitalizacion registro={selectedDigitalizacion} token={token} onClose={() => {
                    loadBandejas();
                    setSelectedDigitalizacion(null);
                    setIsDigitalizacion(false);

                }} />
            )}
        </Box>
    )
};

export default BandejaTable;