
import React from 'react';
import { Modal as MuiModal, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  p: 4,
  maxHeight: '80vh',
  overflowY: 'auto',
};

const GenericModal = ({ isOpen, handleClose, title, children }) => {
  return (
    <MuiModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          {children}
        </Box>
      </Box>
    </MuiModal>
  );
};

export default GenericModal;
