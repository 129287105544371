import React, { createContext, useContext, useState } from 'react';

const JuicioContext = createContext();

export const useJuicio = () => useContext(JuicioContext);

export const JuicioProvider = ({ children }) => {
  const [juicioSeleccionado, setJuicioSeleccionado] = useState(() => {
    const savedJuicio = localStorage.getItem('juicioSeleccionado');
    return savedJuicio ? JSON.parse(savedJuicio) : null;
  });

  const clearJuicioSeleccionado = () => {
    setJuicioSeleccionado(null);
    localStorage.removeItem('juicioSeleccionado');
  };

  return (
    <JuicioContext.Provider value={{ juicioSeleccionado, setJuicioSeleccionado, clearJuicioSeleccionado }}>
      {children}
    </JuicioContext.Provider>
  );
};
