import axios from 'axios';

const urlApelacion = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/apelacion`;

export const createApelacion = async (token, apelacionData) => {
    try {
        const response = await axios.post(urlApelacion, apelacionData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al crear la apelación:', error);
        throw error;
    }
};
