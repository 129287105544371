import axios from 'axios';

// Función para obtener todas las materias desde la API
export const fetchMaterias = async (token, page = 0, size = 10, sort = '') => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/materias`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                page,
                size,
                sort,
            },
        });

        return response.data.content; // Devuelve el contenido de las materias
    } catch (error) {
        console.error('Error al listar materias:', error);
        throw error;
    }
};

// Función para agregar una nueva materia a la API
export const addMateria = async (token, nuevaMateria) => {
    try {
        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/core/materias`, nuevaMateria, {
            headers: {
                Authorization: `Bearer ${token}`, // Agregar token a los encabezados
            },
        });

        return response.data; // Devuelve la respuesta al agregar una materia
    } catch (error) {
        console.error('Error al agregar materia:', error);
        throw error;
    }
};

// Función para actualizar una materia en la API
export const updateMateria = async (token, materia) => {
    // console.log('Datos enviando para actualizar: ', materia);
    try {
        const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/core/materias`, materia, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data; // Devuelve la respuesta de la API
    } catch (error) {
        console.error('Error al actualizar materia:', error);
        throw error;
    }
};

// Función para eliminar una materia usando la API
export const deleteMateria = async (token, id) => {
    try {
        const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/core/materias/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data; // Devuelve respuesta de eliminación
    } catch (error) {
        console.error('Error al eliminar materia:', error);
        throw error;
    }
};
