import React, { useContext } from 'react';
import FormOficialia from './Formulario/FormOficialia';
import KeycloakConf from '../../../api/keycloakConf';

const CreateOficialia = () => {
    const { getToken } = useContext(KeycloakConf);

    const handleAddOficialia = async () => {

    };

    return (
        <div>
            <FormOficialia
                token={getToken()}
                onAddOficialia={handleAddOficialia}
            />
        </div>
    );
};

export default CreateOficialia;