import { useContext } from "react";
import keycloakConf from "../../../api/keycloakConf";
import Forminstitucion from './FormInstitucion';

const CreateInstitucion = () => {
    const { getToken } = useContext(keycloakConf);

    const handleAddSala = async () => {
    };
  
    return (
      <>
        <Forminstitucion
          token={getToken()}
          onAddSala={handleAddSala}
        />
      </>
    );
};

export default CreateInstitucion;