import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import KeycloakConf from '../../../api/keycloakConf';
import FormListaValor from './FormListaValor';

const EditListaValor = () => {
  const location = useLocation();
  const { item } = location.state || {};
  const { getToken } = useContext(KeycloakConf);

  const navigate = useNavigate();

  return (
    <div>
      <h1>Editar Lista Valor</h1>
      {item ? (
        <FormListaValor
          token={getToken()}
          onAdd={async () => {
            navigate('/api/core/listavalor');
          }}
          onEdit={item}
        />
      ) : (
        <p>No se encontró el registro.</p>
      )}
    </div>
  );
};

export default EditListaValor;
