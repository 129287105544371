import axios from "axios"; 

const urlCarpeta = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/historial`;



export const findAllHistorialRegistroOficialias = async (token, page = 0, size = 10, searchQuery) => { 
    try {
        const sort = 'fechaAsignacion,desc';
        const params = { sort, page, size };

       
        if (searchQuery) {
            params.key  = searchQuery; 
        }

        const response = await axios.get(urlCarpeta, { 
            headers: { Authorization: `Bearer ${token}` },
            params
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener el historial de registros:', error);
        throw error;
    }
};

