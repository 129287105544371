import {
  Tooltip
} from "@mui/material";

import DevolverIcon from '@mui/icons-material/Replay';
import SendIcon from '@mui/icons-material/Send';
import PublishIcon from '@mui/icons-material/Publish';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ObservacionesIcon from '@mui/icons-material/Feedback';

const ButtonsAccionRapida = ({
    item,
    handleSingleTurnar
}) => {

    const devolverExpediente = (id) => {
        console.log(id);
    };

    const publicarAcuerdo = (id) =>{
        console.log(id);
    };

    const adjuntarPromocion = (id) => {
        console.log(id);
    };

    const verNotificaciones = (id) => {
        console.log(id);
    }

    const crearPieza = (id) =>{
        console.log(id);
    }

    return (
        <>
            {item.estatus === "TURNADO" &&
            <Tooltip title="Devolver">
                <DevolverIcon
                    className="icon-color"
                    onClick={() =>
                        devolverExpediente(item.id)
                    }
                />
                </Tooltip>
            }
            {item.estatus !== "TURNADO" &&
                <Tooltip title={item.observaciones}>
                <ObservacionesIcon
                    className="icon-color"
                    onClick={() =>
                    null 
                    }
                />
                </Tooltip>
            }
            {item.estatus !== "TURNADO" && item.tipoEntrada !== "PROMOCION" &&

                <Tooltip title="Publicar">
                <PublishIcon
                    className="icon-color"
                    onClick={() =>
                        publicarAcuerdo(item.id)
                    }
                />
                </Tooltip>
            }

            {item.estatus !== "TURNADO" && item.tipoEntrada === "PROMOCION" && 
                <Tooltip title="Adjuntar"> 
                <AttachFileIcon
                className="icon-color"
                onClick={() =>
                    adjuntarPromocion(item.id)
                }
                />
                </Tooltip>
            }
            {item.estatus !== "TURNADO" && item.tipoEntrada !== "PROMOCION" &&
                <Tooltip title="Notificaciones"> 
                <NotificationsIcon
                className="icon-color"
                onClick={() =>
                    verNotificaciones(item.id)
                }
                />
                </Tooltip>
            }
            {item.estatus !== "TURNADO" && item.tipoEntrada !== "PROMOCION" &&  
                <Tooltip title="Turnar">
                <SendIcon
                className="icon-color"
                onClick={() =>
                    handleSingleTurnar(item.id)
                }
                />
                </Tooltip>
            }
            {item.estatus !== "TURNADO" && item.tipoEntrada === "PROMOCION" &&
                <Tooltip title="Crear Pieza">
                <DriveFolderUploadIcon
                className="icon-color"
                onClick={() =>
                    crearPieza(item.id)
                }
                />
                </Tooltip>
            }
        </>
    );
};

export default ButtonsAccionRapida;