import axios from 'axios';

const urlMaterias = process.env.REACT_APP_TRIALS_API_URL + `/api/core/materias`;
const urlJuicios = process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipojuicio`;
const urlDemanda = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/demanda`;
const urlDocumentos = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos`;
const urlStatus = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja`;

export const getAllMaterias = async (token) => {
  try {
    const response = await axios.get(urlMaterias, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener las materias:', error);
    throw error;
  }
};

export const getAllTipoJuicio = async (token) => {
  try {
    const response = await axios.get(urlJuicios, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener las juicios', error);
    throw error;
  }
}


export const addDocumento = async (token, nuevoDocumento) => {
  try {
    const response = await axios.post(urlDemanda, nuevoDocumento, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al agregar documento:', error);
    throw error;
  }
};

export const getSello = async (token, documentoId) => {
  try {

    const response = await axios.get(urlDocumentos + `/${documentoId}/sello`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;

  } catch (error) {
    console.error('Error al generar el sello:', error);
    throw error;
  }
};


export const getCaratula = async (token, documentoId) => {
  try {
    const response = await axios.get(urlDocumentos + `/${documentoId}/caratula`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;

  } catch (error) {
    console.error('Error al generar la caratula:', error);
    throw error;
  }
};

export const salidaDocumento = async (token, documentoId, statusId) => {
  try {
    const response = await axios.patch(urlStatus + `/${documentoId}/status/${statusId}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    console.error('Error al cambiar estatus documento:', error);
    throw error;
  }
};

// Editar anenxos y el motivo edición

export const getDemandaById = async (token, id) => {
  try {
  
    const response = await axios.get(urlDemanda+`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la demanda y editarla por ID:', error);
    throw error;
  }
};

export const updateAnexos = async (token, id, anexos, motivoEdita) => {
  try {
    const data = {
      anexos,
      motivoEdita
    };
    const response = await axios.patch(`${urlDemanda}/${id}/anexos`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al actualizar anexos:', error);
    throw error;
  }
};
