import axios from 'axios';

const urlCarpeta = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/carpeta`;

export const fetchCarpetaActorDemandado = async (token, data) => {
    try {
        const response = await axios.get(urlCarpeta, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: data
        });
        return response.data;
    } catch (error) {
        console.error('Error al encontrar la carpeta:', error);
        throw error;
    }
};

export const fetchPersonasByCarpetaId = async (token, carpetaId) => {
    try {
        const response = await axios.get(`${urlCarpeta}/personas/${carpetaId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener las personas:', error);
        throw error;
    }
};

export const fetchInfoExpediente = async (token, carpetaId) => {
    try {
        const response = await axios.get(`${urlCarpeta}/recepcion/${carpetaId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener la información del expediente:', error);
        throw error;
    }
};