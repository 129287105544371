import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import KeycloakConf from '../../api/keycloakConf';

// ICONOS
import CachedIcon from '@mui/icons-material/Cached';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import DescriptionIcon from '@mui/icons-material/Description';
const iconMapping = {
    Catálogo: <AutoStoriesIcon style={{ color: '#636569' }} />,
    Bandeja: < MarkAsUnreadIcon  style={{ color: '#636569' }} />,
    Registro: < DescriptionIcon  style={{ color: '#636569' }} />

};

export default function useMenuItems() {
    const [items, setItems] = useState([]);
    const [cargando, setCargando] = useState(false);
    const { getToken } = useContext(KeycloakConf);

    useEffect(() => {
        const fetchMenuItems = async () => {
            setCargando(true);
            try {
                const token = getToken();
                const response = await axios.get(`${process.env.REACT_APP_TRIALS_API_URL}/api/core/menu`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const menuItems = response.data.map(section => ({
                    ...section,
                    displayName: section.name,
                    path: section.path,
                    icon: iconMapping[section.name] || <CachedIcon style={{ color: 'white' }} />,
                }));

                

                setItems(menuItems);
            } catch (error) {
                console.error('Error al obtener datos de ítems:', error);
            } finally {
                setCargando(false);
            }
        };

        fetchMenuItems();
    }, [getToken]);

    return { items, cargando };
}
