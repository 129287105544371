
import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@mui/material';
import MateriasTable from './CRUD/MateriasTable'; 
import { fetchMaterias, deleteMateria } from './MateriasService'; 
import KeycloakConf from '../../api/keycloakConf'; 
import FormMateria from './CRUD/Formulario/FormMaterias'; 

const Materias = () => {
  const [materias, setMaterias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [materiaToEdit, setMateriaToEdit] = useState(null); 
  const { getToken } = useContext(KeycloakConf);

  useEffect(() => {
    const loadMaterias = async () => {
      setLoading(true);
      const token = getToken();
      try {
        const data = await fetchMaterias(token);
        setMaterias(data);
      } catch (error) {
        console.error('Error cargando materias:', error);
      } finally {
        setLoading(false);
      }
    };
    loadMaterias();
  }, [getToken]);

  const handleAddMateria = async () => {
    const token = getToken();
    try {
      const data = await fetchMaterias(token);
      setMaterias(data); // Actualiza la lista de materias
    } catch (error) {
      console.error('Error recargando materias:', error);
    }
  };

  const handleDeleteMateria = async (id) => {
    const token = getToken();
    try {
      await deleteMateria(token, id);
      setMaterias(materias.filter(materia => materia.id !== id)); // Elimina localmente el elemento
    } catch (error) {
      console.error('Error eliminando materia:', error);
    }
  };

  const handleEditMateria = async (updatedMateria) => {
    const token = getToken();
    try {
      const data = await fetchMaterias(token);
      setMaterias(data); // Actualiza la lista de materias tras la edición
    } catch (error) {
      console.error('Error cargando materias después de la edición:', error);
    }
    setMateriaToEdit(null); // Limpia el estado de edición
  };

  return (
    <div>
      <h1>Materias</h1>
      <Box mb={2}>
        <FormMateria 
          token={getToken()} 
          onAddMateria={handleAddMateria} 
          materiaToEdit={materiaToEdit} 
          onEdit={handleEditMateria} 
        />
      </Box>
      <Box>
        {loading ? <p>Cargando...</p> : (
          <MateriasTable 
            materias={materias} 
            onDelete={handleDeleteMateria} 
            onEdit={(materia) => setMateriaToEdit(materia)} // Pasamos la función para editar
          />
        )}
      </Box>
    </div>
  );
};

export default Materias;
