import React, { useEffect, useState, useContext } from 'react';
import ListaValorTable from './CRUD/ListaValorTable';
import { findAll, remove } from './ListaValorService';
import KeycloakConf from '../../api/keycloakConf';
import { Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ToastAlertDelete from '../../components/Alert/ToastAlertDelete/ToastAlertDelete';
import ConfirmationDialog from '../../components/Alert/ConfirmationDialog';
import ButtonAdd from '../../components/Button/ButtonAdd';

const ListaValor = () => {
  const [elementList, setElementList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const { getToken } = useContext(KeycloakConf);
  const navigate = useNavigate();

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const token = getToken();
      try {
        const response = await findAll(token, page, rowsPerPage);
        setElementList(response.data.content);
        setPageable(response.data.page);
      } catch (error) {
        console.error('Error cargando la lista:', error);
      } finally {
        setLoading(false);
      }
    };
    loadList();
  }, [getToken, page, rowsPerPage]);

  const handleDelete = (id) => {
    setDialogMessage(`El registro seleccionado será permanentemente eliminado del sistema.`);
    setItemToDelete(id);
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const token = getToken();
    try {
      await remove(token, itemToDelete);
      setElementList(elementList.filter(list => list.id !== itemToDelete));
      setToastMessage('Registro eliminado con éxito');
      setToastType('success');
    } catch (error) {
      console.error('Error eliminando registro:', error);
      setToastMessage('El registro no fue eliminado');
      setToastType('error');
    }
    setToastOpen(true);
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleEdit = (item) => {
    navigate('/api/core/listavalor/edit', { state: { item } });
  };

  return (
    <div>
      <h1>Lista Valor</h1>
      <Box mb={2}>
        <Link to="/api/core/listavalor/add">
          <ButtonAdd ariaLabel="Añadir nuevo registro" />
        </Link>
      </Box>
      <Box>
        {loading ? <p>Cargando...</p> : (
          <ListaValorTable
            list = {elementList}
            onDelete = {handleDelete}
            onEdit = {handleEdit}
            pageable = {pageable}
            page = {page}
            setPage = {setPage}
            rowsPerPage = {rowsPerPage}
            setRowsPerPage = {setRowsPerPage}
          />
        )}
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={handleCloseToast}
        message={toastMessage}
        type={toastType}
      />
    </div>
  );
};

export default ListaValor;
