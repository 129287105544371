import React, { useEffect, useState, useContext} from "react";
import FormDemanda from "./FormDemanda";
import { useLocation } from "react-router-dom";
import { getDemandaById, updateAnexos  } from "../DemandaService";
import keycloakConf from "../../../api/keycloakConf";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';

export default function EditFormDemanda() { 

  
  const location = useLocation();
  const { state } = location; 
  const { documentoId } = state || {}; 
  const { getToken } = useContext(keycloakConf);
  const [demandaData, setDemandaData] = useState(null); 
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
 
  const isEditable = location.pathname === "/api/workflow/demanda/add"; 


useEffect(() => {
   const fetchDemanda = async () => {
     const token = getToken(); 
     if (documentoId && token) { 
       try {
         const data = await getDemandaById(token, documentoId);
         setDemandaData(data); 
       } catch (error) {
         console.error("Error al obtener la demanda:", error);
       }
     }
   };


   if (!demandaData && documentoId) {
     fetchDemanda();
   }
 }, [documentoId, getToken, demandaData]); 

  const handleEditDemanda = async (anexos, razonEdicion) => {
   const token = getToken(); 
   if (documentoId && token) {
     try {
      
       await updateAnexos(token, documentoId, anexos, razonEdicion);
       showUpdateSnackbar('Registro actualizado con éxito', 'success');
      
     } catch (error) {
      showSnackbar('¡Algo salió mal!', 'error');
    
     }
   }
 };
    return( 
        
        <FormDemanda isEditable={!isEditable} handleEditDemanda={handleEditDemanda}  demandaData={demandaData}   />
       
     ); 
}