import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import PaginationGeneric from "../../../../components/Pagination/PaginationGeneric";
import RefreshButton from "../../../../components/Button/RefreshButton";

const Historico = () => {
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [historico, setHistorico] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        loadHistorico();
    }, []);

    const loadHistorico = async () => {
        try {
            const historicoData = {
                content: [
                    { origen: "Oficialia comun", destino: "Oficialia Mayor", fechaEnvio: new Date().toISOString(), fechaRecepcion: new Date().toISOString(), conceptoEnvio: "Distribuir", conceptoRecepcion: "Distribuir", diasTermino: 1 }
                ],
                page: {
                    number: 0,
                    size: 25,
                    totalElements: 4,
                    totalPages: 1
                }
            }

            setHistorico(historicoData.content);
            setPageable(historicoData.page);
        } catch (error) {

        } finally {

        }
    }


    // Función para abrir el modal
    const handleOpen = () => {
        setOpen(true);
    };

    // Función para cerrar el modal
    const handleClose = () => {
        setOpen(false);
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return (

        <Box sx={{ width: '100%' }}>
            <Box mb={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <RefreshButton  onClick={loadHistorico} />
                </Box>
            </Box>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>Origen</TableCell>
                                <TableCell className='table-header'>Destino</TableCell>
                                <TableCell className='table-header'>Fecha de envío</TableCell>
                                <TableCell className='table-header'>Fecha de recepción</TableCell>
                                <TableCell className='table-header'>Concepto de envío</TableCell>
                                <TableCell className='table-header'>Concepto de recepción</TableCell>
                                <TableCell className='table-header'>Días término</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {historico.map((historico) => (
                                <StyledTableRow key={historico.id} hover style={{ cursor: 'pointer' }}>
                                    <TableCell>{historico.origen}</TableCell>
                                    <TableCell>{historico.destino}</TableCell>
                                    <TableCell>{historico.fechaEnvio}</TableCell>
                                    <TableCell>{historico.fechaRecepcion}</TableCell>
                                    <TableCell>{historico.conceptoEnvio}</TableCell>
                                    <TableCell>{historico.conceptoRecepcion}</TableCell>
                                    <TableCell>{historico.diasTermino}</TableCell>

                                </StyledTableRow>
                            ))}
                            {historico.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay Actores.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modal</DialogTitle>
                <DialogContent>
                    CONTENIDO POR CONFIRMAR
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Historico;