import axios from 'axios';

export const fetchAudienciasGenerales = async (token, page = 0, size = 20, sort = '', key = '') => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/bandeja/audienciasgenerales`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          sort,
          key,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al obtener audiencias generales:', error);
    throw error;
  }
};
