import axios from "axios";

// Función para generar un folio de un oficio y a su vez crearlo
export const generarFolio = async (token, data) => {
    try {
        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/oficio`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error al generar el folio:', error);
        throw error;
    }
};

export const obtenerDatosOficio = async (token, documentoId) => {

    try {

        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentoContenido/${documentoId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;

    } catch (error) {
        console.error("Error al obtener los datos del oficio: ", error)
    }
}

export const obtenerOficioCargado = async (token, documentoId, tamanio) => {

    
    let formato = tamanio === 'o' ? true : false;
    try {

        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/oficio/${documentoId}`, {
            responseType: 'arraybuffer',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;

    } catch (error) {
        console.error("Error al obtener el oficio: ", error)
    }
}

export const obtenerAcuseCargado = async (token, documentoId) => {
   
    try {

        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentoDetalle/digitalizar/acuse/${documentoId}`, {
            responseType: 'arraybuffer',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;

    } catch (error) {
        console.error("Error al obtener el oficio: ", error)
    }
}

export const actualizarOficio = async (token, data) => {
    try {
        const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentoContenido`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },

        });
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el oficio:', error);
        throw error;
    }
};


export const publicarOficio = async (token, documentoId, status) => {
    try {
        const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentoContenido/${documentoId}/status/${status}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },

        });
        return response.data;
    } catch (error) {
        console.error('Error al publicar el oficio:', error);
        throw error;
    }
};



export const cancelarOficio = async (token, documentoId) => {
    try {
        const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentoContenido/${documentoId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );

        return response.data;
    } catch (error) {
        console.log('Error al cancelar el oficio: ', error);

    }
}


export const digitalizarAcuse = async (token, data) => {

    try {
        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentoDetalle/digitalizar/acuse`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error al digitalizar el documento:', error);
        throw error;
    }
};

