import axios from "axios";

const urlExhorto = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/exhorto";

export const sendExhorto = async (token, formData) => {
    
    try {
    const response = await axios.post(urlExhorto, formData,{
      headers: {
        Authorization: `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error al enviar los datos del exhorto:", error);
    throw error; 
  }
};
