import { useContext, useEffect, useRef, useState } from "react";
import Acciones from "../../../components/Acciones/Acciones";
import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import keycloakConf from "../../../api/keycloakConf";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { useToastAlertUpdate } from "../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate";


import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { onlyAlphaNumericSpace } from "../../../components/Caracteres/caracteresEspeciales";
import { actualizarOficio, cancelarOficio, digitalizarAcuse, obtenerDatosOficio, publicarOficio } from "../RegistroOficiosService";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation";

import DigitalizarAcuseModal from "../../../components/Modal/ModalDigitalizacionAcuse";
import { validateFields } from "../../../components/Alert/ValidationRequired/validationRequired";
import General from "./general/General";
import Oficio from "./oficio/Oficio";
import { useLocation, useNavigate } from "react-router-dom";

const DigitalizacionOficios = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenUploadAcuse, setModalOpenUploadAcuse] = useState(false);
    const [tabvalue, setTabValue] = useState(0);
    const { getToken } = useContext(keycloakConf);
    const [errors, setErrors] = useState({});
    const { showSnackbar } = useToastAlert();
    const [modalOpenInstitucion, setModalOpenInstitucion] = useState(false);
    const [modalOpenPublicarOficio, setModalOpenPublicarOficio] = useState(false);
    const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const fileInputRefAcuse = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    
    const { documentoId:idDocument } = location.state || {};
    
    const formatos = { 'general.asunto': onlyAlphaNumericSpace }

    const [formData, setFormData] = useState({
        general: {
            numeroOficio: "",
            expediente: "",
            fechaEmision: "",
            idOficio: "",
            dependencia: "",
            fechaEntrega: "",
            estatus: "",
            asunto: ""
        },
        oficio: {
            tamanioPapel: "",
            oficioPublicado: "",
            estatusAcuse: "",
            fechaEntregaOficio: "",
            acuseFile: "",
            acuseComentario: "",
            textoEditor: "",
            rutaAcuse: ""
        }
    })

    useEffect(() => {
        loadOficio();
    }, []);

    const loadOficio = async () => {
        let documentoId = idDocument;
        try {
            const token = getToken();
            const oficio = await obtenerDatosOficio(token, documentoId);
            setFormData((prevState) => ({
                ...prevState,
                general: {
                    numeroOficio: oficio.numeroFolio || "",
                    expediente: oficio.expediente || "",
                    fechaEmision: oficio.fechaEmision || "",
                    idOficio: oficio.idOficio || "",
                    dependencia: oficio.dependencia || "",
                    fechaEntrega: oficio.fechaEntrega || "",
                    estatus: oficio.estatus || "",
                    asunto: oficio.asunto || ""
                },
                oficio: {
                    tamanioPapel: oficio.tamanioPapel === ' ' ? '' : oficio.tamanioPapel,
                    oficioPublicado: oficio.oficioPublicado,
                    estatusAcuse: oficio.estatusAcuse,
                    fechaEntregaOficio: oficio.fechaEntrega,
                    acuseFile: "",
                    acuseComentario: oficio.comentario,
                    textoEditor: oficio.textoEditor,
                    rutaAcuse: oficio.rutaAcuse
                }


            }));

        } catch (error) {
            console.log("Error al obtener los datos del oficio", error);
        }

    };

    const actualizarInformacion = async () => {
        const data = {
            idOficio: idDocument, //actualizar con el id del documento
            dependencia: formData.general.dependencia,
            asunto: formData.general.asunto,
            tamanioPapel: formData.oficio.tamanioPapel === '' ? ' ' : formData.oficio.tamanioPapel,
            nombreAcuse: "",
            comentario: formData.oficio.comentario,
            textoEditor: formData.oficio.textoEditor
        }

        try {
            const token = getToken();
            let response = await actualizarOficio(token, data);
            showAlertValidate('Información actualizada exitosamente.', 'success', '¡Excelente!');
        } catch (error) {

        }
    }

    const handleTabChange = (event, tabnewValue) => {
        setTabValue(tabnewValue);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "general.dependencia" && value === "otra") {
            setModalOpenInstitucion(true);
        }

        // Verificar si el campo es uno que necesita la función de limpieza
        const caracterValue = (formatos[name] || ((val) => val))(value);

        // Dividir el name para saber en qué parte del objeto anidado debe ir el valor
        const nameParts = name.split('.');  // Dividimos por el punto: ["general", "asunto"]

        setFormData((prevState) => {
            let newFormData = { ...prevState };  // Clonamos el estado actual

            // Navegamos hasta el nivel correcto del objeto anidado
            let currentLevel = newFormData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel = currentLevel[nameParts[i]];
            }

            // Actualizamos el campo correcto
            currentLevel[nameParts[nameParts.length - 1]] = caracterValue;

            return newFormData;
        });

    };

    const handlePublicarOficio = async () => {
        let documentoId = idDocument;
        try {
            const token = getToken();
            const oficio = await publicarOficio(token, documentoId, "S");

            if (oficio === 1) {
                setFormData((prevState) => ({
                    ...prevState,
                    oficio: {
                        ...prevState.oficio,
                        oficioPublicado: "S",
                    },
                }));


                showAlertValidate('Oficio publicado exitosamente.', 'success', '¡Excelente!');
            } else {
                showSnackbar('Ha ocurrido un error', 'error');
            }


        } catch (error) {
            console.log("Error al actualizar el oficio");
        }
    }

    const handleSaveAcuseFile = async (formTemp) => {
        const rules = {
            acuseFile: { required: true },
            estatusAcuse: { required: true },
            fechaEntregaOficio: { required: true },
            acuseComentario: { required: true }
        };


        let errors = validateFields(formTemp, rules, showAlertValidate);

        if (formTemp.estatusAcuse === "CREADO") {
            errors["estatusAcuse"] = "Este campo es obligatorio";
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setErrors({});

        // hacemos la petición al end point:
        const data = {
            documentoId: idDocument,
            file: formTemp.acuseFile,
            estado: formTemp.estatusAcuse,
            fechaEntrega: formTemp.fechaEntregaOficio,
            comentario: formTemp.acuseComentario
        }

        try {
            const digitalizacion = await digitalizarAcuse(getToken(), data);
            if (digitalizacion.rutaArchivo) {
                showAlertValidate('Acuse guardado exitosamente.', 'success', '¡Excelente!');
                await loadOficio();
            } else {
                showAlertValidate("Error al guardar la información", "error", "Error al guardar");
            }
          } catch (error) {
            showAlertValidate("Error al guardar la información", "error", "Error al guardar");
            console.log(error);
          }
          
          setModalOpenUploadAcuse(false);
    };



    const handleObtenerAcuseCargado = () => {

    }

    const handleCancelOficio = async () => {
        try {
            const token = getToken();
            let documentoId = idDocument;  
            let response = await cancelarOficio(token, documentoId);
            showAlertValidate('Oficio cancelado exitosamente.', 'success', '¡Excelente!');
            loadOficio();
        } catch (error) {

        }
    }

    const handleBackBandejaOficios = () => {
        navigate('/api/bandeja/oficios');
    }

    return <>
        <Acciones accion1={actualizarInformacion} accion2={ handleBackBandejaOficios } />
        <Box sx={{ marginTop: 2 }}>
            <Divider />
        </Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={8}>
                <Tabs value={tabvalue} onChange={handleTabChange}>
                    <Tab label="General" />
                    <Tab label="Oficio" />
                </Tabs>
            </Grid>
            <Grid item xs={4}></Grid>
        </Grid>

        {tabvalue === 0 && (
            <General
                formData={formData}
                handleInputChange={handleInputChange}
                setModalOpen={setModalOpen}
                modalOpenInstitucion={modalOpenInstitucion}
                setModalOpenInstitucion={setModalOpenInstitucion}
            />
        )}

        {tabvalue === 1 && (
            <Oficio
                formData={formData}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
                setModalOpenUploadAcuse={setModalOpenUploadAcuse}
                setModalOpenPublicarOficio={setModalOpenPublicarOficio}
                handleObtenerAcuseCargado={handleObtenerAcuseCargado}
                idDocumento={idDocument}
            />
        )}


        { /*  SECCIÓN DE MODALES */}
        <ConfirmationModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onConfirm={handleCancelOficio}
            title="¿Cancelar oficio?"
            message={"Se cancelará el oficio " + formData.general.numeroOficio + " de manera permanente."}
            confirmationButton="Sí, cancelar"
            closeButton="No, salir"
        />

        <ConfirmationModal
            open={modalOpenPublicarOficio}
            onClose={() => setModalOpenPublicarOficio(false)}
            onConfirm={handlePublicarOficio}
            title="¿Publicar Oficio?"
            message={"Se publicara el oficio."}
            confirmationButton="Sí, publicar"
            closeButton="No, salir"
        />

        <DigitalizarAcuseModal
            isOpen={modalOpenUploadAcuse}
            handleClose={() => { setModalOpenUploadAcuse(false); }}
            handleSaveAcuseFile={handleSaveAcuseFile}
            setFormData={setFormData}
            formData={formData}
            fileInputRefAcuse={fileInputRefAcuse}
            errors={errors}
        >

        </DigitalizarAcuseModal>
    </>
}

export default DigitalizacionOficios;