import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message, btnLeft, btnRight, widthGeneric="700px", autoClose=true }) => {
  const dialogTitleStyle = { textAlign: 'left', fontWeight: 'bold', fontSize: '22px', color: '#000000B3' };
  const dialogContentStyle = { textAlign: 'left', wordWrap: 'break-word', overflowWrap: 'break-word'};
  const dialogActionsStyle = { justifyContent: 'right' };
  const buttonStyle = { textTransform: 'none' };

  

  return (
    <Dialog 
    open={open} 
    onClose={onClose}
    PaperProps={{
      style: {
        width: widthGeneric,
        maxWidth: '90vw',
        padding: '5px',
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    }}
      disableEscapeKeyDown
    >
      <DialogTitle style={dialogTitleStyle}>{title}</DialogTitle>
      <DialogContent style={dialogContentStyle}>
        {message}
      </DialogContent>

      <DialogActions style={dialogActionsStyle}>
        <Button
          variant="outlined"
          className="button-close"
          onClick={onClose}
          style={{ ...buttonStyle, borderColor: '#636569', color: '#fff' }}
        >
          {btnLeft}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onConfirm();
            if(autoClose){
              onClose();
            }
          }}
          style={{ ...buttonStyle, borderColor: '#8c92bc', color: '#fff' }}
          className="button-save"
        >
          {btnRight}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
