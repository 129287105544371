import React, { useContext } from 'react';
import FormJuzgado from './Formulario/FormJuzgado';
import KeycloakConf from '../../../api/keycloakConf';

const CreateJuzgado = () => {
  const { getToken } = useContext(KeycloakConf);

  const handleAddJuzgado = async () => {
  };

  return (
    <div>
      <FormJuzgado
        token={getToken()}
        onAddJuzgado={handleAddJuzgado}
      />
    </div>
  );
};

export default CreateJuzgado;

