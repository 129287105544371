import { Box, Button, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import DeleteIcon from '@mui/icons-material/Delete';
import text from "../../../assets/Glosario";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const InstitucionesTable = ({
    instituciones,
    onDelete,
    onEdit,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
}) => {
    return (<>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} >
            <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>{text.Nombre}</TableCell>
                                <TableCell className='table-header'>{text.Direccion}</TableCell>
                                <TableCell className='table-header'>{text.Telefono}</TableCell>
                                <TableCell className='table-header'>{text.Acciones}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {instituciones.map((item) => (
                                <StyledTableRow
                                    key={item.id}
                                    hover
                                    onClick={() => onEdit(item)}
                                    style={{ cursor: 'pointer' }}>
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>{item.domicilio}</TableCell>
                                    <TableCell>{item.telefono}</TableCell>

                                    <TableCell align="left">
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Eliminar">
                                                <DeleteIcon className="icon-color"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        onDelete(item.id, item.nombre);
                                                    }}
                                                >
                                                </DeleteIcon>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>

                                </StyledTableRow>
                            ))}
                            {instituciones.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
        </Box>
    </>)
};

export default InstitucionesTable;