import React, { useState, useEffect } from 'react';
import {
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  Select, MenuItem, InputLabel, Grid, Button, Divider,
  Box, TextField,
  FormHelperText,
  Checkbox
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchSalasById, findAllBloques, findAllJueces, findAllJuzgados } from '../../../Salas/SalasService';
import { validateFields } from '../../../../components/Alert/ValidationRequired/validationRequired';
import { create, update } from '../../SalasService';
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchAllJueces } from '../../../Personas/PersonasService';
import { fetchJuzgados } from '../../../../shared/services/JuzgadosService';

const FormSala = ({ token, onAdd, onEdit }) => {
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [formData, setFormData] = useState({
    nombre: '',
    estado: '',
    juez: null,
    bloque: null,
    juzgado: null,
    version: null
  });

  const [bloques, setBloques] = useState([]);
  const [juzgados, setJuzgados] = useState([]);
  const [jueces, setJueces] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    loadBloques();
    loadJuzgados();

    if (onEdit) {
      getById();
    } else {
      resetForm();
      setFormData((prevData) => ({
        ...prevData,
        estado: 'ACTIVE',
      }));
    }
  }, [onEdit, token]);

  const getById = async () => {
    try {
      const sala = await fetchSalasById(token, onEdit.id);

      setFormData({
        id: sala.id,
        nombre: sala.nombre,
        estado: sala.estado,
        juez: sala.juez ? { id: sala.juez.id } : null,
        bloque: sala.bloque ? { id: sala.bloque.id } : null,
        juzgado: sala.juzgado ? { id: sala.juzgado.id } : null,
        version: sala.version
      });

    } catch (error) {
      console.error('Error al obtener la sala:', error);
    }
  };

  const resetForm = () => {
    setFormData({
      nombre: '',
      estado: 'ACTIVE',
      juez: null,
      bloque: null,
      juzgado: null
    });
    setErrorMessages({});
  };

  const loadBloques = async () => {
    try {
      const bloques = await findAllBloques(token);
      setBloques(bloques || []);
    } catch (error) {
      console.log("Error al obtener los bloques");
    }
  };

  const loadJuzgados = async () => {
    try {
      const juzgados = await fetchJuzgados(token, 0, 20);
      setJuzgados(juzgados.content || []);
    } catch (error) {
      console.log("Error al obtener los Juzgados");
    }
  };

  const handleSubmit = async (e) => {

    // Definir las reglas de validación
    const rules = {
      estado: { required: true },
      juez: { required: true },
      bloque: { required: true },
      juzgado: { required: true },
    };

    // Validar los campos del formulario
    const errors = validateFields(formData, rules, showAlertValidate);

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    let formDataToSubmit = {
      ...formData,
      audit: {}

    };

    try {
      if (formData.id) {
        await update(token, formDataToSubmit);
        showUpdateSnackbar('Registro actualizado con éxito', 'success');
      } else {
        await create(token, formDataToSubmit);
        showSnackbar('Registro guardado con éxito', 'success');
      }

      if (onAdd) {
        onAdd();
      }

      resetForm();
    } catch (error) {
      console.error('Error al guardar el registro:', error);
      showSnackbar('¡Algo salió mal!', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleBloqueChange = (event) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      bloque: { id: event.target.value }
    }));
  };

  const handleJuzgadoChange = (event) => {
    formData.juez = null;
    const loadJueces = async () => {
      try {
        const jueces = await fetchAllJueces(token, event.target.value);
        setJueces(jueces);
      } catch (error) {
        console.error('Error al obtener listado de jueces:', error);
      }
    }
    loadJueces();
    setFormData(prevFormData => ({
      ...prevFormData,
      juzgado: { id: event.target.value }
    }));
  };

  const handleJuecesChange = (event) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      juez: { id: event.target.value }
    }));
  };

  const handleEstadoChange = (event) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      estado: event.target.value
    }));
  };

  const handleCancel = () => {
    navigate('/api/core/salas');
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button type="button" variant="contained" onClick={handleSubmit} className="button-save">
            Guardar
          </Button>
          <Button
            type="button"
            variant="contained"
            className="button-close"
            onClick={handleCancel}
            style={{ marginLeft: '10px' }}
          >
            Cerrar
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 2 }}>
        <Divider />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 1 }}>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="nombre"
              inputProps={{}}
              label="Nombre"
              variant="outlined"
              fullWidth
              value={formData.nombre}
              onChange={handleChange}
              error={!!errorMessages.nombre}
              helperText={errorMessages.nombre}
              disabled={true}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined" error={!!errorMessages.juzgado}>
            <InputLabel id="select-juzgado-label">Juzgado</InputLabel>
            <Select
              labelId="select-juzgado-label"
              value={formData.juzgado?.id || ''}
              onChange={handleJuzgadoChange}
              label="Juzgado"
              required
            >
              {juzgados.map((juzgado) => (
                <MenuItem key={juzgado.id} value={juzgado.id}>
                  {juzgado.nombre}
                </MenuItem>
              ))}
            </Select>
            {errorMessages.juzgado && <FormHelperText>{errorMessages.juzgado}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined" error={!!errorMessages.juez}>
            <InputLabel id="select-jueces-label">Juez</InputLabel>
            <Select
              labelId="select-jueces-label"
              value={formData.juez?.id || ''}
              onChange={handleJuecesChange}
              required
              label="Juez"
            >
              {jueces.map((juez) => (
                <MenuItem key={juez.id} value={juez.id}>
                  {juez.nombreCompleto}
                </MenuItem>
              ))}
            </Select>
            {errorMessages.juez && <FormHelperText>{errorMessages.juez}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth component="fieldset" margin="normal" error={!!errorMessages.bloque}>
            <FormLabel component="legend">Horario</FormLabel>
            <RadioGroup value={formData.bloque?.id || ''} onChange={handleBloqueChange}>
              {bloques.map((bloque) => (
                <FormControlLabel
                  key={bloque.id}
                  value={bloque.id.toString()}
                  control={<Radio />}
                  label={`${bloque.horaInicial} - ${bloque.horaFinal}`}
                />
              ))}
            </RadioGroup>
            {errorMessages.bloque && <FormHelperText>{errorMessages.bloque}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.estado === "ACTIVE"}
                  onChange={(event) => handleChange({
                    target: {
                      name: 'estado',
                      value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                    }
                  })}
                  color="primary"
                />
              }
              label="Activar sala"
            />
            {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormSala;
