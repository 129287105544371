import React, { useState, useEffect, useContext } from 'react';
import {
  FormControl, Select, MenuItem, InputLabel, Grid, Button, Divider,
  Box, TextField, Typography, FormHelperText, Autocomplete
} from '@mui/material';
import keycloakConf from '../../api/keycloakConf';
import { useNavigate } from 'react-router-dom';
import { fetchJuzgadosAutocomplete } from '../../shared/services/JuzgadosService';

const FormExpedienteJuzgado = ({ handleSubmit, handleValidate, formData, setFormData, errorMessages}) => {
  const navigate = useNavigate();
  const [juzgados, setJuzgados] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const { getToken } = useContext(keycloakConf);


  useEffect(() => {
    loadJuzgados();
    
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentDateTime(now.toLocaleString());
    }, 1000);

    return () => clearInterval(interval);
  }, [getToken]);


  const loadJuzgados = async () => {
    try {
      const token = getToken();
      const juzgados = await fetchJuzgadosAutocomplete(token, 0, 20);
      setJuzgados(juzgados || []);
    } catch (error) {
      console.log("Error al obtener los Juzgados");
    }
  };

  const handleJuzgadoChange = (event, newValue) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      juzgado: newValue 
    }));
  };

  const handleCancel = () => {
    navigate('/api/bandeja/entrada');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const [date, time] = currentDateTime.split(', ');

  return (
    <form>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <Button 
            type="button" 
            variant="contained" 
            onClick={handleSubmit} 
            className="button-save"
          >
            Guardar
          </Button>
          <Button
            type="button"
            variant="contained"
            className="button-close"
            onClick={handleCancel}
            style={{ marginLeft: '10px' }}
          >
            Cerrar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6} container justifyContent="flex-end">
          <Typography variant="body1">Fecha: {date} | Hora: {time}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 2 }}>
        <Divider />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="expediente"
              label="Expediente"
              variant="outlined"
              fullWidth
              value={formData?.expediente || ''}
              onChange={handleChange}
              error={!!errorMessages.expediente}
              helperText={errorMessages.expediente}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, '');  // Solo permite números
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="año"
              label="Año"
              variant="outlined"
              fullWidth
              value={formData?.año || ''}
              onChange={handleChange}
              error={!!errorMessages.año}
              helperText={errorMessages.año}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');  // Solo permite números
              }}
              inputProps={{ maxLength: 4 }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            options={juzgados.sort((a, b) => a.materia.localeCompare(b.materia))}
            getOptionLabel={(option) => option.nombre}
            groupBy={(option) => option.materia}
            value={formData?.juzgado || null}
            onChange={handleJuzgadoChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Juzgado"
                variant="outlined"
                error={!!errorMessages.juzgado}
                helperText={errorMessages.juzgado}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2,  marginBottom: 6}}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleValidate}
          >
            Validar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormExpedienteJuzgado;
