// Función para limpiar caracteres especiales en campos de texto
export const onlyText = (value) => value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s.]/g, '');

// Función para limpiar caracteres especiales en campos de nombres y apellidos
export const onlyNames = (value) => value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, '');

// Función para limpiar caracteres no numericos
export const onlyNumbers = (value) => value.replace(/\D/g, '');

// Función para limpiar caracteres no alfanuméricos
export const onlyAlphaNumeric = (value) => value.replace(/[^a-zA-Z0-9]/g, '');

// Función para limpiar caracteres no alfanuméricos y permite espacios
export const onlyAlphaNumericSpace = (value) => value.replace(/[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s.]/g, '');

// Funciones para correos electronicos
export const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
export const onlyEmail = (email) => email.trim();