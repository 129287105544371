import React, { createContext, useContext, useState } from 'react';

const ExpedienteContext = createContext();

export const useExpediente = () => useContext(ExpedienteContext);

export const ExpedienteProvider = ({ children }) => {
  const [expedienteSeleccionado, setExpedienteSeleccionado] = useState(() => {
    const savedExpediente = localStorage.getItem('expedienteSeleccionado');
    return savedExpediente ? JSON.parse(savedExpediente) : null;
  });

  const clearExpedienteSeleccionado = () => {
    setExpedienteSeleccionado(null);
    localStorage.removeItem('expedienteSeleccionado');
  };

  return (
    <ExpedienteContext.Provider value={{ expedienteSeleccionado, setExpedienteSeleccionado, clearExpedienteSeleccionado }}>
      {children}
    </ExpedienteContext.Provider>
  );
};
