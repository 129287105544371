import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import keycloakConf from "../../../api/keycloakConf";
import FormInstitucion from "./FormInstitucion";


const EditInstitucion = () => {
    const location = useLocation();
    const { item } = location.state || {};
    const { getToken } = useContext(keycloakConf);
  
    const navigate = useNavigate();

    return (
        <>
        { item ? (
            <FormInstitucion
                token={getToken()}
                onAdd={async () => {
                    navigate('/api/core/instituciones')
                }}
                onEdit={item}
            />
        )  : ( <p>No se encontró el registro.</p>) }
        </>
    );
}

export default EditInstitucion;