import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';

const StyledSnackbarContent = styled(SnackbarContent)(({ type }) => ({
  backgroundColor: type === 'info' ? 'gray' : 'orange',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  borderRadius: 4,
  width: 'auto',
  maxWidth: 'calc(100% - 32px)',
}));

const ToastAlertDelete = ({ open, onClose, title, message, type }) => {
  
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      <StyledSnackbarContent
        type={type}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 8 }}>
              <InfoIcon style={{ color: 'white' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontWeight: 'bold' }}>
                {title}
              </div>
              <div>
                {message}
              </div>
            </div>
          </div>
        }
      />
    </Snackbar>
  );
};

export default ToastAlertDelete;
