import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import FormJuzgado from './Formulario/FormJuzgado'; 
import KeycloakConf from '../../../api/keycloakConf';

const EditJuzgado = () => {
  const location = useLocation();
  const { juzgado } = location.state || {};
  const { getToken } = useContext(KeycloakConf);
  
  const navigate = useNavigate(); // Inicializa useNavigate

  return (
    <div>
      {juzgado ? (
        <FormJuzgado
          token={getToken()}
          onAddJuzgado={async () => { // Maneja la finalización de la edición
            // Aquí, después de actualizar el juzgado, redireccionamos
            navigate('/api/core/juzgados'); // Redirigir a la ruta de juzgados
          }}
          juzgadoToEdit={juzgado}
        />
      ) : (
        <p>No se encontró el registro.</p>
      )}
    </div>
  );
};

export default EditJuzgado;
