import { Box, Button, Card, Checkbox, Divider, FormControlLabel, Grid, Typography, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import keycloakConf from "../../../api/keycloakConf";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { UpdateEstatusAnexos } from "../BandejaService";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchItemById } from "./BandejaService";

const Recepcion = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const { getToken } = useContext(keycloakConf);
    const { showSnackbar, showConfirmDialog } = useToastAlert();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [fechaHoraActual, setFechaHoraActual] = useState({ fecha: '', hora: '' });
    const location = useLocation();
    const { item } = location.state || {};
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            setFechaHoraActual({ fecha: now.toLocaleDateString(), hora: now.toLocaleTimeString() });
        }, 1000);
        loadData()
        return () => clearInterval(interval);
    }, []);


    const loadData = async () => {
        try {
            const data = await fetchItemById(getToken(), item.id);
            setFormData((prevFormData) => ({
                ...prevFormData,
                ...data,
            }));
        } catch (error) {

        }
    }

    const [formData, setFormData] = useState({
        folio: "",
        expediente: "",
        tipoEntrada: "",
        digitalizacion: "",
        anexos: [],
        observaciones: "",
        recomendaciones: ""
    })

    const handleSubmit = async () => {
        const token = await getToken();
        const bodyData= {
            "anexos": formData.anexos,
            "observaciones": formData.observaciones,
            "recomendaciones": formData.recomendaciones
        };

        try {
            await UpdateEstatusAnexos(token, item.id, bodyData );

            showSnackbar('Anexos actualizados con éxito', 'success');
            navigate('/api/bandeja/recepcion');

        } catch (error) {
            console.error('Error al actualizar el estatus de los anexos :', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }

    }


    const onCancel = () => {
        navigate('/api/bandeja/recepcion');
    }

    const setAnexoEstado = (e, anexoId) => {
        const estado = e.target.checked ? 1 : 0;

        setFormData(prevState => ({
            ...prevState,
            anexos: prevState.anexos.map(anexo =>
                anexo.id === anexoId ? { ...anexo, estado } : anexo
            )
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            {/* Sección de botones */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button type="button" variant="contained" className="button-save" onClick={() => setModalOpen(true)}>
                    Recibir
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    className="button-close"
                    onClick={onCancel}
                    style={{ marginLeft: "10px" }}
                >
                    Cerrar
                </Button>
            </Box>

            {/* Sección de fecha y hora en una sola línea */}
            <Typography variant="body1">
                Fecha: {fechaHoraActual.fecha} | Hora: {fechaHoraActual.hora}
            </Typography>
        </Box>


        <Box sx={{ marginTop: 2 }}>
            <Divider />
        </Box>

        <ConfirmationModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onConfirm={handleSubmit}
            title="¿Desea continuar ?"
            message="Se actualizara el estatus de los anexos en el sistema."
            confirmationButton="Sí, continuar"
            closeButton="No, cancelar"
        />

        <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1">{`Folio: ${formData.folio}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1">{`Expediente: ${formData.expediente}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1">{`Tipo: ${formData.tipoEntrada}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1">{`Digitalización: ${formData.digitalizacion}`}</Typography>
            </Grid>
        </Grid>

        <Box sx={{ marginTop: 2 }}>
            <Divider />
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 4 }}>

            <Grid item sm={4}>
                <Typography variant="body1">Anexos: </Typography>
                <Card variant="outlined">
                    {formData.anexos.map((anexo) => (

                        <Grid item xs={3} key={anexo.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={anexo.estado === 1}
                                        onChange={(e) => setAnexoEstado(e, anexo.id)}
                                        color="primary"
                                    />
                                }
                                label={anexo.nombre}
                            />
                        </Grid>
                    ))}
                </Card>
            </Grid>

            <Grid item sm={4}>
                <TextField
                    label="Observaciones"
                    name="observaciones"
                    fullWidth
                    variant="outlined"
                    value={formData.observaciones}
                    onChange={handleInputChange}
                    helperText={`${formData.observaciones.length}/300 caracteres`}
                    inputProps={{ maxLength: 300 }}
                    multiline
                    maxRows={4}
                    FormHelperTextProps={{
                        style: { textAlign: 'right' }
                    }}
                />
            </Grid>

            <Grid item sm={4}>
                <TextField
                    label="Recomendaciones"
                    name="recomendaciones"
                    fullWidth
                    variant="outlined"
                    value={formData.recomendaciones}
                    onChange={handleInputChange}
                    helperText={`${formData.recomendaciones.length}/60 caracteres`}
                    inputProps={{ maxLength: 60 }}
                    multiline
                    maxRows={2}
                    FormHelperTextProps={{
                        style: { textAlign: 'right' }
                    }}
                />
            </Grid>

        </Grid>



    </>
}

export default Recepcion;