import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import RefreshButton from "../../../../components/Button/RefreshButton";
import ButtonAdd from "../../../../components/Button/ButtonAdd";
import { Link, useNavigate } from "react-router-dom";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import TaskIcon from '@mui/icons-material/Task';
import { useContext, useEffect, useState } from "react";
import PaginationGeneric from "../../../../components/Pagination/PaginationGeneric";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { fetchGetAcuerdos } from "./Acuerdo/AcuerdoService";
import keycloakConf from "../../../../api/keycloakConf";
import texto from '../../../../assets/Glosario';
const Acuerdos = ({ item }) => {
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const { getToken } = useContext(keycloakConf);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [acuerdos, setAcuerdos] = useState([]);

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    useEffect(() => {
        loadAcuerdos();
    }, []);

    const loadAcuerdos = async () => {
        const token = getToken();
        try {
            const acuerdosData = await fetchGetAcuerdos(token, 0, 10, item.carpetaId);

            setAcuerdos(acuerdosData.content);
            setPageable(acuerdosData.page);
        } catch (error) {

        } finally {

        }
    }

    // Función para abrir el modal
    const handleOpen = () => {
        setOpen(true);
    };

    // Función para cerrar el modal
    const handleClose = () => {
        setOpen(false);
    };

    //funcion para dirigir a crear un acuerdo.
    const handleAddAcuerdos = () => {


        navigate('/api/bandeja/expediente/agregarAcuerdo', {
            state: { item }
        });
    }

    const handleShowRegistro = (acuerdo) => {
        if(acuerdo.estatus === "PUBLICADO"){
             navigate('/api/workflow/acuerdo/acuerdoNotificacion', {
                state: { item }
             });
        }
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return <>

        <Box mb={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">

                <ButtonAdd onClick={handleAddAcuerdos} />

                &nbsp;
                <RefreshButton onClick={loadAcuerdos} />
            </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>{texto.NumAcuerdo}</TableCell>
                                <TableCell className='table-header'>{texto.FechaPublicacion}</TableCell>
                                <TableCell className='table-header'>{texto.Resumen}</TableCell>
                                <TableCell className='table-header'>{texto.Estatus}</TableCell>
                                <TableCell className='table-header'>{texto.AccionesRapidas}</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(acuerdos ?? [])
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((acuerdo) => (
                                    <StyledTableRow key={acuerdo.id} hover style={{ cursor: 'pointer' }} onClick={() => handleShowRegistro(acuerdo)} >
                                        <TableCell>{acuerdo.numeroAcuerdo}</TableCell>
                                        <TableCell>{acuerdo.fechaPublicacion}</TableCell>
                                        <TableCell>{acuerdo.resumen}</TableCell>
                                        <TableCell>{acuerdo.estatus}</TableCell>

                                        <TableCell>


                                            <IconButton onClick={handleOpen}>
                                                <TaskIcon />
                                            </IconButton>

                                            <IconButton onClick={handleOpen}>
                                                <NotificationsNoneIcon />
                                            </IconButton>


                                            <IconButton>
                                                <LibraryBooksIcon />
                                            </IconButton>



                                        </TableCell>


                                    </StyledTableRow>
                                ))}
                            {acuerdos.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay Documentos.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modal</DialogTitle>
                <DialogContent>
                    CONTENIDO POR CONFIRMAR
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>
}

export default Acuerdos;