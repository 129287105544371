import axios from "axios";

export const fetchRubrosAutoComplete = async (token, page = 0, size = 10, idDocumento, nombre = '') => {
  
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/acuerdorubros/autocomplete/${idDocumento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          nombre
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al listar Instituciones:', error);
      throw error;
    }
  };

  export const fetchEtapasProcesaes = async(token, idTipoJuicio, idProcedimiento=0) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/etapaprocesal?IdTipoJuicio=${idTipoJuicio}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al descargar el documento: ', error);
      
      
    }
  
  }

  
  export const fetchTipoAcuerdos = async(token, idDocumento) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipoacuerdo/${idDocumento}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al descargar el documento: ', error);
      
      
    }
  
  }

  export const fetchPromocionesCarpeta = async(token, idCarpeta) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/obtenerPromociones/${idCarpeta}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al descargar el documento: ', error);
      
      
    }
  
  }

  
export const crearAcuerdo = async (token, acuerdoData) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL +'/api/workflow/documentos/crearAcuerdo', acuerdoData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al crear el acuerdo:', error);
      throw error;
  }
};


export const fetchPublicarAcuerdo = async (token, acuerdoData) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL +'/api/workflow/documentos/publicarAcuerdo', acuerdoData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al publicar el acuerdo:', error);
      throw error;
  }
};


export const fetchGetAcuerdos = async (token, page = 0, size = 10, carpetaId, key) => {
  
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/obtenerAcuerdos/${carpetaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        key
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener los Acuerdos:', error);
    throw error;
  }
};

export const fetchTipoPartesAcuerdo = async(token, carpetaId, tipoParte) =>{
 
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/obtenerTipoPartesAcuerdo/${carpetaId}/${tipoParte}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    
    return response.data;

  } catch (error) {
    console.log('Error al obtener el tipo parte: ', error);
    
    
  }

}