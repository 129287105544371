import {
  Box,
  Modal,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Carrito = ({ 
  isModalOpen, 
  setModalOpen,
  cartItems,
  handleRemoveFromCart,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflowY: "auto",
  };

  return (
    <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-carrito-title"
        aria-describedby="modal-carrito-description"
      >
        <Box sx={modalStyle}>
          <h3 id="modal-carrito-title">Carrito</h3>
          {cartItems.length === 0 ? (
            <p id="modal-carrito-description">No hay elementos en el carrito.</p>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Folio</TableCell>
                    <TableCell align="center">No. Expediente</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartItems.map((item) => (
                    <TableRow key={item.movid}>
                      <TableCell align="center">{item.folio}</TableCell>
                      <TableCell align="center">{item.expediente}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleRemoveFromCart(item.movid)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {cartItems.length === 0 ? (
            <></>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
                marginBottom: -4,
              }}
            >
              <p>Total de registros: {cartItems.length}</p>
            </Box>
          )}
        </Box>
      </Modal>
  );
};

export default Carrito;
