
import React from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';
import text from '../../../assets/Glosario';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PersonasTable = ({ 
  personas, 
  onEdit,
  pageable, 
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {

  const formatPhoneCelNumbers = (value) => {
    const numericValue = value.replace(/\D/g, '');
    
    if (numericValue.length === 0) {
      return '';
    } else if (numericValue.length <= 3) {
      return `(${numericValue}`;
    } else if (numericValue.length <= 6) {
      return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)} ${numericValue.slice(6, 10)}`;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <PaginationGeneric
          pageable = {pageable}
          page = {page}
          setPage = {setPage}
          rowsPerPage = {rowsPerPage}
          setRowsPerPage = {setRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>{text.Nombre}</TableCell>
                <TableCell className='table-header'>{text.Centrotrabajo}</TableCell>
                <TableCell className='table-header'>{text.CorreoElectronico}</TableCell>
                <TableCell className='table-header'>{text.Telefono}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {personas
                .map((item) => (
                  <StyledTableRow 
                    key={item.id}
                    onClick={() => onEdit(item)}
                    hover
                    style={{ cursor: 'pointer' }}>
                    <TableCell>{item.nombre}</TableCell>
                    <TableCell>{item.centroTrabajo}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{formatPhoneCelNumbers(item.celular)}</TableCell>
                  </StyledTableRow>
              ))}
              {personas.length === 0 && (
                <TableRow>
                  <TableCell colSpan={18} align="center">
                    No hay registros disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable = {pageable}
          page = {page}
          setPage = {setPage}
          rowsPerPage = {rowsPerPage}
          setRowsPerPage = {setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default PersonasTable;
