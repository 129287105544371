import React, { useState } from 'react';
import { Box, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import PaginationGeneric from "../../components/Pagination/PaginationGeneric";
import text from '../../assets/Glosario';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BandejaAudienciaTable = ({
    bandejas,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRefresh
}) => {

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
            <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>{text.Tipo}</TableCell>
                                <TableCell className='table-header'>{text.Juez}</TableCell>
                                <TableCell className='table-header'>{text.numCarpeta}</TableCell>
                                <TableCell className='table-header'>{text.lugar}</TableCell>
                                <TableCell className='table-header'>{text.FechaHora}</TableCell>
                                <TableCell className='table-header'>{text.Estatus}</TableCell>
                                <TableCell className='table-header'>{text.Acciones}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bandejas.map((item) => (
                                <StyledTableRow
                                    key={item.id}
                                    hover
                                    style={{ cursor: 'pointer' }}>
                                    <TableCell>{item.tipoAudiencia}</TableCell>
                                    <TableCell>{item.juez}</TableCell>
                                    <TableCell>{item.numCarpeta}</TableCell>
                                    <TableCell>{item.lugar}</TableCell>
                                    <TableCell>{new Date(item.fechaHora).toLocaleString()}</TableCell>
                                    <TableCell>{item.estatus}</TableCell>
                                    <TableCell>
                                    <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                                        <Tooltip title="Diferir o Reprogramar">
                                            <CalendarTodayIcon 
                                                className='icon-color' 
                                                onClick={() => {}} 
                                            />
                                        </Tooltip>
                                        <Tooltip title="Cancelar">
                                            <DoDisturbAltIcon 
                                                className='icon-color' 
                                                onClick={() => {}} 
                                            />
                                        </Tooltip>
                                    </Box>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                            {bandejas.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
        </Box>
    )
};

export default BandejaAudienciaTable;
