import { Box, Grid, Button, Typography, Divider, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../api/keycloakConf";
import { fetchAudienciasGenerales } from "../../shared/AudienciasService";
import BandejaAudienciaTable from "./BandejaAudienciaTable";
import RefreshButton from "../../components/Button/RefreshButton";
import ButtonAdd from '../../components/Button/ButtonAdd';
import InfoIcon from '@mui/icons-material/Info';

const BandejaAudienciasGenerales = () => {
    const navigate = useNavigate();
    const [bandejas, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { getToken } = useContext(keycloakConf);
    const [loading, setLoading] = useState(true);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        loadBandejaAudiencia();

        const interval = setInterval(() => {
            const now = new Date();
            setCurrentDateTime(now.toLocaleString());
        }, 1000);
      
        return () => clearInterval(interval);
    }, [getToken, page, rowsPerPage]);

    const loadBandejaAudiencia = async () => {
        setLoading(true);
        const token = getToken();
        try {
            const bandejaAudienciaData = await fetchAudienciasGenerales(token, page, rowsPerPage, '', searchQuery);
            setData(bandejaAudienciaData.content);
            setPageable(bandejaAudienciaData.page);
        } catch (error) {
            console.error('Error cargando registros:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = () => {
        setSearchQuery('');
        setPage(0);
        loadBandejaAudiencia();
    };

    const [date, time] = currentDateTime.split(', ');

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={6}>
                    <Button
                        type="button"
                        variant="contained"
                        className="button-close"
                        onClick={() => {}}
                        style={{ marginLeft: '10px' }}
                    >
                        Cerrar
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6} container justifyContent="flex-end" alignItems="center">
                    <Typography variant="body1" sx={{ mr: 2 }}>Fecha: {date} | Hora: {time}</Typography>
                    <Tooltip title="Audiencia: Acto procesal en el que las partes exponen sus argumentos ante el juez o tribunal para que este tome una desición sobre el caso.">
                        <InfoIcon color="action" />
                    </Tooltip>
                </Grid>
            </Grid>

            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                <Divider />
            </Box>
        

            <Box mb={2} display="flex" alignItems="center">
                <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                    <ButtonAdd/>
                </Box>
                <Box display="flex" alignItems="center">
                    <RefreshButton onClick={handleRefresh} />
                </Box>
            </Box>
            <Box>
                {loading ? <p>Cargando ... </p> : (

                    <BandejaAudienciaTable
                        bandejas={bandejas}
                        token={getToken()}
                        loadBandejaAudiencia={loadBandejaAudiencia}
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        handleRefresh={handleRefresh}
                    />

                )}
            </Box>
        </>
    )
};

export default BandejaAudienciasGenerales;