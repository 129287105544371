import HomeIcon from '@mui/icons-material/Home';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

const InicioMenuItem = () => {
  return (
    <Link to="/home" style={{ textDecoration: 'none' }}>
      <ListItemButton
        sx={{
          borderBottom: '1px solid hwb(0deg 26.67% 73.33% / 23%)',
          '&:hover': { backgroundColor: '#a6a6a6', color: '#fff !important' },
        }}
      >
        <ListItemIcon sx={{
          '&:hover': { color: '#fff' },
        }}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Inicio" sx={{ color: '#636569', '&:hover': { color: '#fff' }}} />
      </ListItemButton>
    </Link>
  );
};

export default InicioMenuItem;
