import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import KeycloakConf from '../../../api/keycloakConf';
import FormSede from './FormSede';

const EditSede = () => {
  const location = useLocation();
  const { item } = location.state || {};
  const { getToken } = useContext(KeycloakConf);

  const navigate = useNavigate();

  return (
    <div>
      {item ? (
        <FormSede
          token={getToken()}
          onAdd={async () => {
            navigate('/api/core/sedes');
          }}
          onEdit={item}
        />
      ) : (
        <p>No se encontró el registro.</p>
      )}
    </div>
  );
};

export default EditSede;
