const Glosario = {
  Nombre: "Nombre",
  Mate: "Materia",
  Juzgado: "Juzgado",
  Acciones: "Acciones",
  Folio: "Folio",
  NoExpediente: "No. Expediente",
  TipoEntrada: "Tipo de entrada",
  Origen: "Origen",  
  Concepto: "Concepto",
  FechaHoraEnvio: "Fecha y hora de envío", 
  FechaHora: "Fecha y hora",
  FechaTurnado: "Fecha turnado",
  FechaTermino: "Fecha término", 
  Estatus: "Estado",
  Tipo: "Tipo",
  NumeroOficio: "Número de oficio",
  Dependencia: "Dependencia",
  FechaEmision: "Fecha de emisión", 
  FechaEntrega: "Fecha de entrega", 
  Destino: "Destino",
  HoraInicial: "Hora inicial", 
  HoraFinal: "Hora final",
  Id: "ID",
  Calle: "Calle",
  NInterior: "N. Interior",
  Colonia: "Colonia",
  Direccion: "Dirección",
  Telefono: "Teléfono",
  FechaAlta: "Fecha de alta",
  Centrotrabajo: "Centro de trabajo",
  CorreoElectronico: "Correo electrónico",
  Sala: "Sala",
  Juez: "Juez",
  Horario: "Horario",
  NumAcuerdo: "Número de acuerdo",
  FechaResolucion: "Fecha de resolución",
  FechaPublicacion: "Fecha de publicación",
  Resumen: "Resumen",
  AccionesRapidas: "Acciones rapidas",
  TipoAcuerdo: "Tipo",
  Metodo: "Método",
  AccionesRapidas: "Acciones rapidas",
  numCarpeta: "Número de carpeta",
  lugar: "Lugar"
};

export default Glosario;
