import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';
import Menu from '../components/Menu/Menu';
import { Box } from '@mui/material';
import Breadcrumbs from '../components/Breadcrumb/breadcrumbs';

const Layout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Menu />
      <Box component="main" sx={{ flexGrow: 1, p: 3, ml: '240px', mt: '64px' }}>
        <Breadcrumbs /> 
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
