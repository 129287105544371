import axios from 'axios';

export const fetchList = async (token, page = 0, size = 10, sort = '', key = '') => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/recepcion`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
        key
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al listar bandeja recepcion:', error);
    throw error;
  }
};

export const fetchMotivosDevolucion = async (token) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/movimientos`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; 
  } catch (error) {
    console.error('Error al obtener los motivos de devolución:', error);
    throw error;
  }
};

export const createMotivoDevolucion = async (token, motivo, documentoId) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/movimientos/turnado`, 
      {
        motivo: motivo,           
        documentoId: documentoId, 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error al guardar el motivo de devolución:', error);
    throw error;
  }
};


export const fetchItemById = async (token, id) => {
  try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/recepcion/anexos/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      });
      if (!response.data) {
          throw new Error('No se encontró la información');
      }
      return response.data;
  } catch (error) {
      console.error('Error al obtener la información:', error);
      if (error.response) {
          console.error('Respuesta del servidor:', error.response.data);
          console.error('Código de estado:', error.response.status);
      }
      throw new Error('No se pudo obtener la información. Por favor, verifica la conexión e intenta de nuevo.');
  }
};
