import { useState, useContext } from "react";
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import text from "../../../../../assets/Glosario";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteParticipante } from "../../../../BandejaAsignados/BandejaAsignadosService";
import keycloakConf from "../../../../../api/keycloakConf";
import ConfirmationDialog from "../../../../../components/Alert/ConfirmationDialog";
import ToastAlertDelete from "../../../../../components/Alert/ToastAlertDelete/ToastAlertDelete";




const TablaActores = ({ data, tipo, onDelete }) => {
  const [open, setOpen] = useState(false);
  const { getToken } = useContext(keycloakConf);


  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [actorToDelete, setActorToDelete] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = (id, tipo) => {
    
    const message = `El ${tipo} seleccionado será permanentemente eliminado del sistema.`;
    setDialogMessage(message);
    setActorToDelete(id);
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const token = getToken();
    try {
      await deleteParticipante(token, actorToDelete);
      onDelete(actorToDelete);
      setToastMessage("Actor eliminado con éxito");
      setToastType("success");
    } catch (error) {
      setToastMessage("El actor no fue eliminado");
      setToastType("error");
    }
    setToastOpen(true);
    setDialogOpen(false);
  };


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
            <Table stickyHeader sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="table-header">{text.Nombre}</TableCell>
                  <TableCell className="table-header">{text.Tipo}</TableCell>
                  <TableCell className="table-header">{text.Acciones}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log('data', data)}
                {data.map((persona) => (
                  <StyledTableRow key={persona.id} hover style={{ cursor: "pointer" }}>
                    <TableCell>{persona.nombre}</TableCell>
                    <TableCell>{tipo}</TableCell>
                    <TableCell>
                      <IconButton onClick={handleOpen}>
                        <NotificationsNoneIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => handleDeleteClick(persona.id, tipo)}
                      >
                        <DeleteIcon className="icon-color" />
                      </IconButton>

                    </TableCell>
                  </StyledTableRow>
                ))}
                {data.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No hay participantes.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Modal</DialogTitle>
          <DialogContent>CONTENIDO POR CONFIRMAR</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={handleCloseToast}
        message={toastMessage}
        type={toastType}
      />


    </>


  );
};

export default TablaActores;
