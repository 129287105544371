import React, { useState } from 'react';
import { Box, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import AddTaskIcon from '@mui/icons-material/AddTask';
import UndoIcon from '@mui/icons-material/Undo';
import ChatIcon from '@mui/icons-material/Chat';
import text from '../../../assets/Glosario';
import IndicadoresComponent from "../../../components/Indicadores/Indicadores";
import ReturnTurnadoModal from "../../../components/Modal/ReturnTurnadoModal";
import { useNavigate } from "react-router-dom";
import RecepcionModal from '../../../components/ModalPersonalJuzgado/RecepcionModal';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));




const RecepcionTable = ({
    bandejas,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRefresh
}) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedExpediente, setSelectedExpediente] = useState(null);
    const [isRecepcionModalOpen, setIsRecepcionModalOpen] = useState(false);
    const [recepcionItem, setRecepcionItem] = useState(null);

    const handleOpenModal = (expediente, documentoId) => {
        setSelectedExpediente({expediente, documentoId}); 
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedExpediente(null);
    };

    const handleSave = (data) => {
        handleCloseModal();
    };

    const handleRecepcionAnexos = (item) => {   
        navigate('/api/bandeja/recepcion/anexos', { state: { item } });
    };

    const handleRecepcion = (item) => {  
        setRecepcionItem(item);
        setIsRecepcionModalOpen(true);
    };

    const handleCloseRecepcionModal = () => {
        setIsRecepcionModalOpen(false);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <IndicadoresComponent 
                isRecepcion={true} 
            />
            <Paper sx={{ width: '100%', mb: 2 }}>
            <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>{text.Folio}</TableCell>
                                <TableCell className='table-header'>{text.NoExpediente}</TableCell>
                                <TableCell className='table-header'>{text.TipoEntrada}</TableCell>
                                <TableCell className='table-header'>{text.Origin}</TableCell>
                                <TableCell className='table-header'>{text.Concepto}</TableCell>
                                <TableCell className='table-header'>{text.FechaHoraEnvio}</TableCell>
                                <TableCell className='table-header'>{text.Acciones}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bandejas.map((item) => (
                                <StyledTableRow
                                    key={item.id}
                                    hover
                                    style={{ cursor: 'pointer' }}>
                                    <TableCell>{item.folio}</TableCell>
                                    <TableCell>{item.expediente}</TableCell>
                                    <TableCell>{item.tipoEntrada}</TableCell>
                                    <TableCell>{item.origen}</TableCell>
                                    <TableCell>{item.concepto}</TableCell>
                                    <TableCell>{new Date(item.fechaHoraEnvio).toLocaleString()}</TableCell>
                                    <TableCell>
                                        {item.isInterno && (
                                            <Tooltip title="Devolver">
                                                <UndoIcon 
                                                    className='icon-color' 
                                                    onClick={() => handleOpenModal(item.expediente, item.id)} 
                                                />
                                            </Tooltip>
                                        )}
                                        <Tooltip title="Recepción">
                                            <AddTaskIcon 
                                                className='icon-color' 
                                                onClick={() => item.isInterno ? handleRecepcion(item) : handleRecepcionAnexos(item)} 
                                            />
                                        </Tooltip>
                                        <Tooltip title="Observaciones">
                                            <ChatIcon className='icon-color' />
                                        </Tooltip>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                            {bandejas.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
            <ReturnTurnadoModal
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                expediente={selectedExpediente}
                handleSave={handleSave}
                handleRefresh={handleRefresh}
            />
            <RecepcionModal
                open={isRecepcionModalOpen}
                onClose={handleCloseRecepcionModal}
                item={recepcionItem}
                handleRefresh={handleRefresh}
            />
        </Box>
    )
};

export default RecepcionTable;
