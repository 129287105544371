import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import { create, update } from '../ListaValorService';
import { validateFields } from '../../../components/Alert/ValidationRequired/validationRequired';
import { useNavigate } from 'react-router-dom';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';

const FormListaValor = ({ token, onAdd, onEdit }) => {
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert(); 
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [data, setData] = useState({
    id: '',
    nombre: '',
    estado: ''
  });

  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    if (onEdit) {
      setData(onEdit);
    } else {
      resetForm();
    }
  }, [onEdit]);

  const resetForm = () => {
    setData({
      id: '',
      nombre: '',
      estado: ''
    });
    setErrorMessages({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setErrorMessages({
      ...errorMessages,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const rules = {
      nombre: { required: true },
      estado: { required: true }
    };

    const errors = validateFields(data, rules, showAlertValidate);
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    let formData = {
      ...data,
      fechaEdita: new Date().toISOString(),
    };

    try {
      if (data.id) {
        await update(token, formData);
        showUpdateSnackbar('Registro actualizado con éxito', 'success');
      } else {
        await create(token, formData);
        showSnackbar('Registro guardado con éxito', 'success');
      }

      if (onAdd) {
        onAdd();
      }

      resetForm();
    } catch (error) {
      console.error('Error al guardar el registro:', error);
      showSnackbar('¡Algo salió mal!', 'error');
    }
  };

  const handleCancel = () => {
    navigate('/api/core/listavalor');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="nombre"
            label="Nombre *"
            variant="outlined"
            fullWidth
            value={data.nombre}
            onChange={handleChange}
            error={!!errorMessages.nombre}
          />
          {errorMessages.nombre && <FormHelperText error>{errorMessages.nombre}</FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined" error={!!errorMessages.estado}>
            <InputLabel id="estado-label">Estado *</InputLabel>
            <Select
              labelId="estado-label"
              name="estado"
              value={data.estado}
              onChange={handleChange}
              label="Estado"
            >
              <MenuItem value="A">Activo</MenuItem>
              <MenuItem value="I">Inactivo</MenuItem>
            </Select>
            {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <Button type="submit" variant="contained" className="button-save">
          Guardar
        </Button>
        <Button
          variant="outlined"
          className="button-close"
          onClick={handleCancel}
          style={{ marginLeft: '10px', backgroundColor: 'red', color: 'white' }}
        >
          Cancelar
        </Button>
      </Box>
    </form>
  );
};

export default FormListaValor; 
