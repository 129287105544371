
import React, { useEffect, useState, useContext } from 'react';
import PersonasTable from './CRUD/PersonasTable';
import { fetchPersonaByCurp, fetchPersonas, deletePersonas } from './PersonasService';
import KeycloakConf from '../../api/keycloakConf';
import { Box, Button, Grid, TextField } from '@mui/material';
import RefreshButton from '../../components/Button/RefreshButton';
import { useNavigate } from 'react-router-dom';
import ToastAlertDelete from '../../components/Alert/ToastAlertDelete/ToastAlertDelete';
import ConfirmationDialog from '../../components/Alert/ConfirmationDialog';
import ButtonAdd from '../../components/Button/ButtonAdd';
import GenericModal from '../../components/Modal/Modal';
import { validateCurp } from '../../components/CURP/validationCurp'
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import { useToastAlert } from '../../components/Alert/ToastAlertSave/ToastAlertSave';

const Personas = () => {
  const [personas, setPersonas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const { getToken } = useContext(KeycloakConf);
  const [isOpen, setIsOpen] = useState(false);
  const [curp, setCurp] = useState('');
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const [modalOpen, setModalOpen] = useState(false);
  const [curpData, setCurpData] = useState();


  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const loadPersonas = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const data = await fetchPersonas(token, page, rowsPerPage);
      setPersonas(data.content);
      setPageable(data.page);
    } catch (error) {
      console.error('Error cargando personas:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPersonas();
  }, [getToken, page, rowsPerPage]);

  const handleDelete = (id) => {
    setDialogMessage(`El registro seleccionado será permanentemente eliminado del sistema.`);
    setItemToDelete(id);
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const token = getToken();
    try {
      await deletePersonas(token, itemToDelete);
      setPersonas(personas.filter(persona => persona.id !== itemToDelete));
      setToastMessage('Registro eliminado con éxito');
      setToastType('success');
    } catch (error) {
      console.error('Error eliminando registro:', error);
      setToastMessage('El registro no fue eliminado');
      setToastType('error');
    }
    setToastOpen(true);
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleRefresh = () => {
    loadPersonas();
  };

  const updateCurp = (event) => {
    setCurp(event.target.value);
  };

  const handleEditPersona = (item) => {
    navigate('/api/core/personal/edit', { state: { item } });
  };

  const findByCurp = () => {
    if (!validateCurp(curp)) {
      setToastMessage('CURP con formato incorrecto');
      setToastType('error');
      setToastOpen(true);
      return;
    }

    const findByCurp = async () => {
      try {
        setCurpData(await fetchPersonaByCurp(getToken(), curp));
        setModalOpen(true);
      } catch (error) {
        navigate('/api/core/personal/edit', { state: { item: curp } });
        console.log('No se encontró la curp registrada', error);
      }
    };
    findByCurp();
  };

  const handleConfirm = async () => {
    setModalOpen(false);
    navigate('/api/core/personal/edit', { state: { item: curpData } });
  };

  return (
    <div>
      <Box mb={2}>
        <ButtonAdd onClick={handleOpen} />
        &nbsp;
        <RefreshButton onClick={handleRefresh} />
      </Box>
      <Box>
        <GenericModal isOpen={isOpen} handleClose={handleClose} title={"Buscar por CURP"} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="curp"
                label="CURP"
                variant="outlined"
                onChange={updateCurp}
                fullWidth
                value={curp}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="button"
                variant="contained"
                color="success"
                onClick={findByCurp}
              >
                Validar
              </Button>
            </Grid>
          </Grid>
        </GenericModal>
        {loading ? <p>Cargando...</p> : (
          <PersonasTable
            personas={personas}
            onDelete={handleDelete}
            onEdit={handleEditPersona} // Pasamos la función de editar
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />

      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="Usuario registrado previamente"
        message="La CURP proporcionada ya se encuentra registrada en el sistema, ¿Cargar los datos?"
        confirmationButton="Sí, cargar"
        closeButton="No, cancelar"
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={handleCloseToast}
        message={toastMessage}
        type={toastType}
      />
    </div>
  );
};

export default Personas;
