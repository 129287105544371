import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import Acciones from "../../../components/Acciones/Acciones";
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import General from "./Tabs/General/General";
import Historico from "./Tabs/Historico";
import Documentos from "./Tabs/Documentos";
import Acuerdos from "./Tabs/Acuerdos";
import Audiencias from "./Tabs/Audiencias";

const Asignado = () => {
    const location = useLocation();
    const { item } = location.state || {};  //USAR item PARA MAPEAR DATOS DEL SELECCIONADO

    
    const [tabvalue, setTabValue] = useState(0);
    const navigate = useNavigate();
    
    const accion1 = () => {
        alert("Accion 1 lista");
    }

    const accion2 = () => {
        alert("Accion 2 lista");
        navigate('/api/bandeja/asignados');
    }

    //Cambio a tabs
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return <>
        <Acciones accion1={accion1}
            accion2={accion2} />
        <Box sx={{ marginTop: 2 }}>
            <Divider />
        </Box>
        <Grid item xs={12} sx={{ marginBottom: 4 }}>
            <Tabs value={tabvalue} onChange={handleTabChange} variant="fullWidth">
                <Tab label="General" />
                <Tab label="Documentos" />
                <Tab label="Acuerdos" />
                <Tab label="Audiencias" />
                <Tab label="Histórico" />
            </Tabs>
        </Grid>

            {tabvalue === 0 && (<General item={item} />)}

            {tabvalue === 1 && (<Documentos  />)}

            {tabvalue === 2 && (<Acuerdos item={item} />)}

            {tabvalue === 3 && (<Audiencias />)}

            {tabvalue === 4 && (<Historico />)}

    </>
}

export default Asignado;