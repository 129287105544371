import React, { useState } from 'react';

import { Drawer, List, ListItemText, ListItemIcon, Toolbar, Divider, ListItemButton, Collapse, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import useMenuItems from '../../hooks/Menu/useMenuItems';
import Progress from '../Progress/Progress';
import InicioMenuItem from './InicioMenuItem';
import logoPJ from '../../assets/images/logo.png';

const drawerWidth = 240;

const Menu = () => {
  const { items, cargando } = useMenuItems();
  const location = useLocation();
  const [expandedSection, setExpandedSection] = useState(null);
  const [selectedItem, setSelectedItem] = useState(location.pathname);

  const handleSectionClick = (sectionName) => {
    setExpandedSection(sectionName === expandedSection ? null : sectionName);
  };

  const handleItemClick = (path) => {
    setSelectedItem(path);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{
        style: {
          width: drawerWidth,
          backgroundColor: '#ebe6d6',
          color: '#636569',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <img src={logoPJ} alt="Logo" style={{ maxWidth: '80%', height: 'auto' }} />
      </Box>
      {/* <Toolbar /> */}
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <List sx={{ p: 0, m: 0 }}>
          <InicioMenuItem />
        </List>

        <Box className='menu-box' sx={{ flex: 1, overflowY: 'auto' }}>
          {cargando ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Progress />
            </Box>
          ) : (
            <List sx={{ p: 0 }}>
              {items.length > 0 && items.map((section) => (
                <React.Fragment key={section.name}>
                  <ListItemButton
                    onClick={() => handleSectionClick(section.name)}
                    sx={{
                      borderBottom: '1px solid hwb(0deg 26.67% 73.33% / 23%)',
                      '&:hover': { backgroundColor: '#a6a6a6', color: '#fff' },
                    }}
                  >
                    <ListItemIcon>{section.icon}</ListItemIcon>
                    <ListItemText primary={section.displayName || section.name} />
                    {expandedSection === section.name ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={expandedSection === section.name} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ p: 0 }}>
                      {section.items.map((item) => (
                        <ListItemButton
                          key={item.name}
                          component={Link}
                          to={item.path}
                          selected={selectedItem === item.path}
                          onClick={() => handleItemClick(item.path)}
                          sx={{
                            pl: 4,
                            borderBottom: '1px solid hwb(0deg 26.67% 73.33% / 23%)',
                            backgroundColor: selectedItem === item.path ? '#2f3a5d' : 'transparent',
                            '&:hover': { backgroundColor: '#a6a6a6', color: '#fff' },
                          }}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.displayName || item.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default Menu;
