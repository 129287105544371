import { Grid, TextField } from "@mui/material";
import { validateCurp } from "../../../../components/CURP/validationCurp";



const ActorDemandadoForm = ({ data, handleInputChange, prefix, errors, formatPhoneCelNumbers }) => {

  return (
    <>
      <Grid item xs={4}>
        <TextField
          label="Nombre(s)"
          name={`${prefix}.nombre`}
          onChange={handleInputChange}
          fullWidth
          value={data.nombre}
          error={!!errors[`${prefix}.nombre`]}
          helperText={errors[`${prefix}.nombre`] || ''}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Apellido paterno"
          name={`${prefix}.apellidoPaterno`}
          onChange={handleInputChange}
          fullWidth
          value={data.apellidoPaterno}
          error={!!errors[`${prefix}.apellidoPaterno`]}
          helperText={errors[`${prefix}.apellidoPaterno`] || ''}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Apellido materno"
          name={`${prefix}.apellidoMaterno`}
          onChange={handleInputChange}
          fullWidth
          value={data.apellidoMaterno}
          error={!!errors[`${prefix}.apellidoMaterno`]}
          helperText={errors[`${prefix}.apellidoMaterno`] || ''}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="CURP"
          name={`${prefix}.curp`}
          onChange={handleInputChange}
          fullWidth
          value={data.curp}
          error={!!errors[`${prefix}.curp`]}
          helperText={errors[`${prefix}.curp`] || ''}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="INE"
          name={`${prefix}.ine`}
          onChange={handleInputChange}
          fullWidth
          value={data.ine}
          error={!!errors[`${prefix}.ine`]}
          helperText={errors[`${prefix}.ine`] || ''}
        />
      </Grid>

      {prefix === 'actor' && (
        <Grid item xs={4}>
          <TextField
            label="Celular"
            name={`${prefix}.celular`}
            onChange={handleInputChange}
            fullWidth
            value={formatPhoneCelNumbers(data.celular)}
            error={!!errors[`${prefix}.celular`]}
            helperText={errors[`${prefix}.celular`] || ''}
            inputProps={{ maxLength: 14 }}
          />
        </Grid>
      )}

      <Grid item xs={8}>
        <TextField
          label="Domicilio"
          name={`${prefix}.domicilio`}
          onChange={handleInputChange}
          fullWidth
          value={data.domicilio}
          error={!!errors[`${prefix}.domicilio`]}
          helperText={errors[`${prefix}.domicilio`] || ''}
        />
      </Grid>

      {prefix === 'actor' && (
        <Grid item xs={4}>
          <TextField
            label="Correo electrónico"
            name={`${prefix}.correoElectronico`}
            onChange={handleInputChange}
            fullWidth
            value={data.correoElectronico}
            error={!!errors[`${prefix}.correoElectronico`]}
            helperText={errors[`${prefix}.correoElectronico`] || ''}
          />
        </Grid>
      )}
    </>
  )


}


export default ActorDemandadoForm;