import React, { createContext, useContext, useState } from 'react';
import { Snackbar, SnackbarContent, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Close as CloseIcon, CheckCircle as CheckCircleIcon, Error as ErrorIcon, Info as InfoIcon } from '@mui/icons-material';

const ToastAlertUpdateContext = createContext();

export const ToastAlertUpdateProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogActions, setDialogActions] = useState({});

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showConfirmDialog = (title, actions) => {
    setDialogTitle(title);
    setDialogActions(actions);
    setDialogOpen(true);
  };

  const handleDialogClose = (action) => {
    setDialogOpen(false);
    if (action) {
      action();
    }
  };

  const getIcon = () => {
    switch (snackbarSeverity) {
      case 'success':
        return <CheckCircleIcon style={{ color: 'green' }} />;
      case 'error':
        return <ErrorIcon style={{ color: 'red' }} />;
      case 'info':
        return <InfoIcon style={{ color: 'white' }} />;
      default:
        return null;
    }
  };

  return (
    <ToastAlertUpdateContext.Provider value={{ showSnackbar, showConfirmDialog }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <Button color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon />
          </Button>
        }
      >
        <SnackbarContent
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            color: snackbarSeverity === 'success' ? 'green' : 'red',
            borderBottom: snackbarSeverity === 'success' ? '3px solid #66bb6a' : '3px solid #db5858',
          }}
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 12 }}>
                {getIcon()}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontWeight: 'bold' }}>
                  {snackbarSeverity === 'success' ? '¡Éxito!' : '¡Error!'}
                </div>
                <div style={{ color: 'black' }}>
                  {snackbarMessage}
                </div>
              </div>
            </div>
          }
        />
      </Snackbar>

      <Dialog open={dialogOpen} onClose={() => handleDialogClose()}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {/* Aquí puedes añadir contenido si es necesario */}
        </DialogContent>
        <DialogActions>
          {dialogActions.cancel && (
            <Button onClick={() => handleDialogClose(dialogActions.cancel)} color="error">
              Cancelar
            </Button>
          )}
          {dialogActions.deny && (
            <Button onClick={() => handleDialogClose(dialogActions.deny)} color="warning">
              No guardar
            </Button>
          )}
          {dialogActions.confirm && (
            <Button onClick={() => handleDialogClose(dialogActions.confirm)} color="success">
              Guardar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ToastAlertUpdateContext.Provider>
  );
};

export const useToastAlertUpdate = () => useContext(ToastAlertUpdateContext);
