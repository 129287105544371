import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import RefreshButton from "../../../components/Button/RefreshButton";
import SearchInput from "../../../components/Search/SearchInput";
import BlockIcon from "@mui/icons-material/Block";
import PrintIcon from "@mui/icons-material/Print";
import text from "../../../assets/Glosario";
import ApprovalIcon from "@mui/icons-material/Approval";
import { useNavigate } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableBandejaOficios = ({
  bandejas,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loadBandeja,
  loading,
  setModalOpen,
  setIdDoc,
  searchQuery,
  setSearchQuery,
}) => {
  const { showSnackbar } = useToastAlert();
  const navigate = useNavigate();

  //Metodo para mostrar oficio digitalizado
  const handleShowOficio = async (item) => {
    try {
      // TODO llenar handleShowOficio
      /*
       ** const sello = await getCaratula(token, item.id);
       ** let blob = new Blob([sello], { type: "application/pdf" });
       ** let link = document.createElement("a");
       ** link.href = window.URL.createObjectURL(blob);
       ** link.target = item.ruta;
       ** link.click();
       */
    } catch (error) {
      console.error("Error al generar el documento:", error);
      showSnackbar("¡Algo salió mal!", "error");
    }
  };

  //Metodo para mostrar Acuse
  const handleShowAcuse = async (item) => {
    try {
      // TODO llenar handleShowAcuse
    } catch (error) {
      console.error("Error al generar el documento:", error);
      showSnackbar("¡Algo salió mal!", "error");
    }
  };

  // Cancelar Oficio - muestra confirmación
  const handleCancelOficio = async (item) => {
    setModalOpen(true);
    setIdDoc({ folio: item.folio, id: item.docId });
  };

  // Mostrar campos del oficio editables:
  const handleEditOficio = (item) =>{
    navigate('/api/workflow/documentoContenido',{
      state: { documentoId: item }
    })
  }

  return (
    <>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <RefreshButton onClick={loadBandeja} />
        </Box>
        <Box flexBasis="300px" ml={2}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Buscar..."
            ariaLabel="Campo de busqueda para bandeja de oficios"
            tooltipTitle="Escriba para buscar"
            onKeyDown={(e) => e.key === "Enter" && loadBandeja()}
          />
        </Box>
      </Box>
      <Box>
        {loading ? (
          <p>Cargando ... </p>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
            <PaginationGeneric
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
              <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
                <Table stickyHeader sx={{ minWidth: 750 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-header">
                        {text.NumeroOficio}
                      </TableCell>
                      <TableCell className="table-header">
                        {text.Dependencia}
                      </TableCell>
                      <TableCell className="table-header">Asunto</TableCell>
                      <TableCell className="table-header">Estatus</TableCell>
                      <TableCell className="table-header">
                        {text.FechaEmision}
                      </TableCell>
                      <TableCell className="table-header">
                        {text.FechaEntrega}
                      </TableCell>
                      <TableCell className="table-header">
                        {text.Acciones}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bandejas.map((item) => {
                      return (
                        <StyledTableRow
                          onClick={ () => { handleEditOficio(item.docId) } }
                          key={item.docId}
                          hover
                          style={{
                            cursor:
                              item.estatus === "CANCELADO"
                                ? "default"
                                : "pointer",
                            pointerEvents:
                              item.estatus === "CANCELADO" ? "none" : "auto",
                            color:
                              item.estatus === "CANCELADO"
                                ? "#6e6e6e"
                                : "inherit",
                          }}
                        >
                          <TableCell
                            style={{
                              color:
                                item.estatus === "CANCELADO"
                                  ? "#6e6e6e"
                                  : "inherit",
                            }}
                          >
                            {item.folio}
                          </TableCell>
                          <TableCell
                            style={{
                              color:
                                item.estatus === "CANCELADO"
                                  ? "#6e6e6e"
                                  : "inherit",
                            }}
                          >
                            {item.dependencia}
                          </TableCell>
                          <TableCell
                            style={{
                              color:
                                item.estatus === "CANCELADO"
                                  ? "#6e6e6e"
                                  : "inherit",
                            }}
                          >
                            {item.asunto}
                          </TableCell>
                          <TableCell
                            style={{
                              color:
                                item.estatus === "CANCELADO"
                                  ? "#6e6e6e"
                                  : "inherit",
                            }}
                          >
                            {item.estatus}
                          </TableCell>
                          <TableCell
                            style={{
                              color:
                                item.estatus === "CANCELADO"
                                  ? "#6e6e6e"
                                  : "inherit",
                            }}
                          >
                            {item.fechaEmision}
                          </TableCell>
                          <TableCell
                            style={{
                              color:
                                item.estatus === "CANCELADO"
                                  ? "#6e6e6e"
                                  : "inherit",
                            }}
                          >
                            {item.fechaEntrega}
                          </TableCell>
                          <TableCell>
                            {item.estatus !== "CANCELADO" && item.bandAcuse ? (
                              <Tooltip title="Acuse cargado">
                                <PrintIcon
                                  className="icon-color"
                                  onClick={() => handleShowOficio(item)}
                                />
                              </Tooltip>
                            ) : null}
                            {item.estatus !== "CANCELADO" &&
                            item.bandDigitalizado ? (
                              <Tooltip title="Oficio cargado">
                                <ApprovalIcon
                                  className="icon-color"
                                  onClick={() => handleShowAcuse(item)}
                                />
                              </Tooltip>
                            ) : null}
                            {item.estatus !== "CANCELADO" && (
                              <Tooltip title="Cancelar oficio">
                                <BlockIcon
                                  className="icon-color"
                                  onClick={() => handleCancelOficio(item)}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                    {bandejas.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No hay registros disponibles.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationGeneric
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TableBandejaOficios;
