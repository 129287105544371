import React, { useContext } from 'react';
import KeycloakConf from '../../../api/keycloakConf';
import FormBloque from './FormBloque';

const CreateBloque = () => {
    const { getToken } = useContext(KeycloakConf);
    const handleAdd = async () => {
    };

    return (
        <div>
            <FormBloque
                token={getToken()}
                onAdd={handleAdd}
            />
        </div>
    );
};

export default CreateBloque;