import { Autocomplete, Box, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

const General = ({
    formData,
    setFormData,
    handleInputChange,
    errors,
    etapasProcesales,
    tiposAcuerdos,
    promociones,
    rubros }) => {

    const [inputValueAutoComplete, setInputValueAutoComplete] = useState('');
    const [rubroAux, setRubroAux] = useState();
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    const handleAddRubro = (value) => {

        let rubroSelected = value;

        if (rubroSelected) {
            let existeRubro = formData.general.rubros.some(element => element.id === rubroSelected.id);

            if (!existeRubro) {

                setFormData((prevState) => ({
                    ...prevState,
                    general: {
                        ...prevState.general,
                        rubros: [...prevState.general.rubros, rubroSelected.nombre]
                    }
                }))
            }
        }

    }

    const handleEliminarRubro = (rubro) => {
        let rubrosTemp = formData.general.rubros.filter(item => item !== rubro);

        setFormData((prevState) => ({
            ...prevState,
            general: {
                ...prevState.general,
                rubros: rubrosTemp
            }
        }))

    }

    const handleCheckPromocion = (promocion, isChecked) => {

        setFormData(prevFormData => {
            //verificamos si el elemento seleccionado existe en el arreglo de promociones relacionadas:
            const existePromocion = prevFormData.general.promocionesRelacionadas.some(
                item => item.id === promocion.id
            );

            if (existePromocion) {
                // Eliminar la promoción si ya está seleccionada y se desmarca el checkbox
                return {
                    ...prevFormData,
                    general: {
                        ...prevFormData.general,
                        promocionesRelacionadas: prevFormData.general.promocionesRelacionadas.filter(
                            item => item.id !== promocion.id
                        )
                    }
                };
            } else {
                // Agregar la promoción si no existe en el array y se marca el checkbox
                return {
                    ...prevFormData,
                    general: {
                        ...prevFormData.general,
                        promocionesRelacionadas: [
                            ...prevFormData.general.promocionesRelacionadas,
                            promocion
                        ]
                    }
                };
            }
        });
    };


    return (<>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={4}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-tipoAcuerdo-label">Tipos de acuerdo:</InputLabel>
                    <Select
                        labelId="select-acuerdo-label"
                        name="general.tipoAcuerdo"
                        value={formData?.general.tipoAcuerdo || ''}
                        onChange={handleInputChange}
                        label="Tipo de acuerdo"
                        error={!!errors["general.tipoAcuerdo"]}
                        required
                    >
                        {tiposAcuerdos.map((tipoAcuerdo) => (
                            <MenuItem key={tipoAcuerdo.id} value={tipoAcuerdo.nombreAcuerdo}>
                                {tipoAcuerdo.nombreAcuerdo}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText >{errors["general.tipoAcuerdo"] || ''}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item sm={4}>
                <TextField
                    label="Fecha de resolución: "
                    name="general.fechaResolucion"
                    onChange={handleInputChange}
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors["general.fechaResolucion"]}
                    helperText={errors["general.fechaResolucion"]}
                    value={formData.general.fechaResolucion}
                />
            </Grid>

            <Grid item sm={4}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-etapa-procesal-label">Etapas procesales:</InputLabel>
                    <Select
                        labelId="select-etapa-procesa-label"
                        name="general.etapaProcesal"
                        value={formData?.general.etapaProcesal || ''}
                        onChange={handleInputChange}
                        error={!!errors["general.etapaProcesal"]}
                        label="Etapas procesales:"
                        required
                    >
                        {etapasProcesales.map((etapaProcesal) => (
                            <MenuItem key={etapaProcesal.id} value={etapaProcesal.etapaProcesal}>
                                {etapaProcesal.etapaProcesal}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText >{errors["general.etapaProcesal"] || ''}</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={4}>
                <Autocomplete
                    options={inputValueAutoComplete ? rubros : rubros.slice(0, 20)}
                    getOptionLabel={(option) => option.nombre}
                    value={rubroAux}
                    onChange={(event, item) => {
                        handleAddRubro(item);
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValueAutoComplete(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Rubros"
                            name="general.rubros"
                            variant="outlined"
                            error={!!errors["general.rubros"]}
                            helperText={errors["general.rubros"]}
                        />
                    )}
                />
            </Grid>

            <Grid item sm={8}>

                <Box paddingBottom={2}>
                    <Typography variant="body1">Rubros</Typography>
                    <Paper
                        elevation={1}
                        sx={{ padding: 2, flex: 1, overflowY: "auto" }}

                    >
                        {formData.general.rubros.map((rubro) => (
                            <Chip
                                sx={{ mx: 1, mb: 1 }}
                                key={rubro}
                                id={rubro}
                                label={rubro}
                                onDelete={() =>
                                    handleEliminarRubro(rubro)
                                }
                            />
                        ))}
                    </Paper>
                </Box>

            </Grid>

        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>

            <Grid item sm={8}>
                <TextField
                    label="Resumen"
                    fullWidth
                    name="general.resumen"
                    onChange={(e) => handleInputChange(e)}
                    value={formData.general.resumen}
                    variant="outlined"
                    error={!!errors["general.resumen"]}
                    helperText={errors["general.resumen"]} />
            </Grid>
            <Grid item sm={4}>
                <Typography variant="body1">Recomendaciones:</Typography>
                <Paper elevation={1} sx={{ padding: 2, flex: 1, overflowY: "auto" }}>
                    {formData.general.promocionesRelacionadas.map((promocion) => (
                        <Typography variant="body1" key={promocion.recomendacion}>{promocion.recomendacion}</Typography>
                    ))}
                </Paper>
            </Grid>


        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>

            <Grid item sm={8}>
                <Typography variant="body1">Promociones relacionadas</Typography>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>

                        <TableBody>
                            {promociones.map((promocion) => (
                                <StyledTableRow
                                    key={promocion.id}
                                    hover
                                    style={{ cursor: 'pointer' }}>
                                    <TableCell> <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.general.promocionesRelacionadas.some(
                                                    item => item.id === promocion.id
                                                )}
                                                onChange={(e) => {
                                                    handleCheckPromocion(promocion, e.target.checked)
                                                }}
                                            />
                                        }
                                    /></TableCell>
                                    <TableCell>{promocion.nombre}</TableCell>
                                    <TableCell>{promocion.nombreArchivo}</TableCell>

                                </StyledTableRow>
                            ))}
                            {promociones.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>


        </Grid>

        

    </>)
}

export default General;