import React, { createContext, useContext, useState } from 'react';
import { Snackbar, SnackbarContent, Button } from '@mui/material';
import { Close as CloseIcon, CheckCircle as CheckCircleIcon, Error as ErrorIcon, Info as InfoIcon } from '@mui/icons-material';

const ToastAlertValidationContext = createContext();

export const ToastAlertValidation = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarTitle, setSnackbarTitle] = useState('');

  const showSnackbar = (message, severity = 'success', title = '') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
    
    if (severity === 'success') {
      setSnackbarTitle(title || '¡Éxito!');
    } else if (severity === 'error') {
      setSnackbarTitle(title || 'Error');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getIcon = () => {
    switch (snackbarSeverity) {
      case 'success':
        return <CheckCircleIcon style={{ color: 'green' }} />;
      case 'error':
        return <ErrorIcon style={{ color: 'red' }} />;
      case 'info':
        return <InfoIcon style={{ color: 'white' }} />;
      default:
        return null;
    }
  };

  return (
    <ToastAlertValidationContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <Button color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon />
          </Button>
        }
      >
        <SnackbarContent
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            color: snackbarSeverity === 'success' ? 'green' : 'red',
            borderBottom: snackbarSeverity === 'success' ? '3px solid #66bb6a' : '3px solid #db5858',
          }}
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 12 }}>
                {getIcon()}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontWeight: 'bold' }}>
                  {snackbarTitle}
                </div>
                <div style={{ color: 'black' }}>
                  {snackbarMessage}
                </div>
              </div>
            </div>
          }
        />
      </Snackbar>
    </ToastAlertValidationContext.Provider>
  );
};

export const useToastAlertValidation = () => useContext(ToastAlertValidationContext);