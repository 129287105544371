import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Button,
  Stack,
  Paper
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../components/Search/SearchInput";
import keycloakConf from "../../../api/keycloakConf";
import { fetchIndicadores } from '../../../shared/services/DocumentoService';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
  borderRadius: 0,
  boxShadow: 'none',
  width: '33%'
}));

const HeaderBandejaAsignados = ({
  loadBandejas,
  searchQuery,
  setSearchQuery,
  setDialogOpen
}) => {
  const navigate = useNavigate();
  const { getToken } = useContext(keycloakConf);
  const [indicadores, setIndicadores] = useState(null);

  const [formData, setFormData] = useState({
    expediente: "",
    año: "",
    respEntrega: null,
  });

  const loadIndicadores = async () => {
    try {
        const token = getToken(); 
        const data = await fetchIndicadores(token, false);
        setIndicadores(data);
    } catch (error) {
        console.error('Error al cargar indicadores:', error);
    }
  };

  useEffect(() => {
    loadIndicadores();
  }, [getToken]);

  const handleSubmitTurnado = async (e) => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    navigate("/");
  };
  
  const handleResponsableChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      respEntrega: { id: event.target.value },
    }));
  };

  if (!indicadores) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </Box>
    );
}

  return (
    <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={2}>
          <Button
            variant="contained"
            color="error"
            className="button-close"
            onClick={handleCancel}
            style={{ marginLeft: "10px" }}
          >
            Cerrar
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack direction="row" spacing={1}>
            <Item>Término rebasado<br/>{indicadores.indicador2}</Item>
            <Item>Término en 24 horas<br/>{indicadores.indicador3}</Item>
            <Item>Término en 3 días<br/>{indicadores.indicador4}</Item>
          </Stack>
        </Grid>


        <Grid item xs={12} sm={12} md={4}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Buscar..."
            ariaLabel="Campo de busqueda para bandeja de asignados"
            tooltipTitle="Escriba para buscar"
            onKeyDown={(e) => e.key === "Enter" && loadBandejas()}
          />
        </Grid>
      </Grid>
  );
};

export default HeaderBandejaAsignados;
