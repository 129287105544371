import React, { useContext } from 'react';
import KeycloakConf from '../../../api/keycloakConf';
import FormSede from './FormSede';

const CreateSede = () => {
    const { getToken } = useContext(KeycloakConf);
    const handleAdd = async () => {
    };

    return (
        <div>
            <FormSede
                token={getToken()}
                onAdd={handleAdd}
            />
        </div>
    );
};

export default CreateSede;

