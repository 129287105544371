import { Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Editor } from '@tinymce/tinymce-react';
import { useContext, useRef } from "react";
import {  obtenerOficioCargado, obtenerAcuseCargado } from "../../RegistroOficiosService";
import keycloakConf from "../../../../api/keycloakConf";

const Oficio = ({ formData, setFormData, handleInputChange, setModalOpenUploadAcuse,  setModalOpenPublicarOficio, idDocumento  }) => {
    const editorRef = useRef(null);
    const { getToken } = useContext(keycloakConf);

    const handleObtenerOficioCargado = async () => {
        let documentoId = idDocumento;
        try {
            const token = getToken();
            let documento = await obtenerOficioCargado(token, documentoId, formData.oficio.tamanioPapel);

            let blob = new Blob([documento], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob)
            link.target = "oficio.pdf"
            link.click()

        } catch (error) {
            console.log("Error al obtener el oficio");
        }
    }

    const handleObtenerAcuseCargado = async () => {
        let documentoId = idDocumento;
        try {
            const token = getToken();
            let documento = await obtenerAcuseCargado(token, documentoId);

            let blob = new Blob([documento], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob)
            link.target = "acuse.pdf"
            link.click()

        } catch (error) {
            console.log("Error al obtener el oficio");
        }
    }

    const preLoadAcuse = (event) => {
        setModalOpenUploadAcuse(true);
        return

    }

    return(
        <Grid container spacing={2} sx={{ mt: 2 }}>

            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="select-tamanio-papel-label">Tamaño de papel</InputLabel>
                    <Select
                        name="oficio.tamanioPapel"
                        labelId="select-tamanio-papel-label"
                        disabled={formData.general.estatus === "CANCELADO" || formData.oficio.estatusAcuse !== "CREADO" }
                        value={formData.oficio.tamanioPapel || ""}
                        onChange={handleInputChange}
                        label="Tamaño de papel"
                    >
                        <MenuItem value="c">Carta</MenuItem>
                        <MenuItem value="o">Oficio</MenuItem>
                    </Select>
                </FormControl>
            </Grid>


            <Grid item xs={3}>
                <InputLabel id="select-oficio-label">Oficio</InputLabel>


            </Grid>

            <Grid item xs={3}>
                <InputLabel id="select-oficio-label">Acuse</InputLabel>

                <Button
                    component="label"
                    disabled={ formData.oficio.rutaAcuse !== "" & formData.oficio.rutaAcuse !== null }
                    role={undefined}
                    variant="contained"
                    onClick={preLoadAcuse}
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                >
                    Cargar acuse pdf

                </Button>
            </Grid>

            <Grid item xs={3}>
                <TextField
                    label="Estado del oficio"
                    name="oficio.estatusAcuse"
                    disabled
                    fullWidth
                    value={formData.oficio.estatusAcuse}
                />
            </Grid>


            <Grid item xs={3}></Grid>

            { /*  CONTAINER OFICIO */}
            <Grid item xs={3}>
                {formData.oficio.oficioPublicado === "S" && (
                    <Card sx={{ maxWidth: 130, margin: "0 auto", position: "relative" }}>
                    
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 80 }}>
                            <PictureAsPdfIcon sx={{ fontSize: 75, color: "black" }} />
                        </Box>

                        <CardContent>
                            <a href="#" onClick={handleObtenerOficioCargado}>oficio.pdf</a>
                        </CardContent>
                    </Card>
                )}
            </Grid>

            { /*  CONTAINER ACUSE */}
            <Grid item xs={3}>
                {formData.oficio.rutaAcuse !== "" && formData.oficio.rutaAcuse !== null && (
                    <Card sx={{ maxWidth: 130, margin: "0 auto", position: "relative" }}>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 80 }}>
                            <PictureAsPdfIcon sx={{ fontSize: 75, color: "black" }} />
                        </Box>

                        <CardContent>
                            <a href="#" onClick={handleObtenerAcuseCargado}>Acuse.pdf</a>
                        </CardContent>
                    </Card>
                )}
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Comentario "
                    name="oficio.acuseComentario"
                    disabled
                    fullWidth
                    value={formData.oficio.acuseComentario}
                />
            </Grid>


            <Grid item xs={12}>
                <Editor
                    apiKey='yyx29rtycwcn6ah3hylli7g8kdg3fxwov5qmain2f9mn8rtz'
                    onInit={(_evt, editor) => editorRef.current = editor}
                    value={formData.oficio.textoEditor}
                    disabled={formData.general.estatus === "CANCELADO" || formData.oficio.estatusAcuse !== "CREADO" }
                    onEditorChange={(newValue) =>
                        setFormData((prevState) => ({
                            ...prevState,
                            oficio: {
                                ...prevState.oficio,
                                textoEditor: newValue
                            }
                        }))
                    }
                    init={{
                        height: 400,
                        menubar: 'edit insert view format table',
                        menu: {
                            edit: { title: 'Edit', items: 'cut copy paste pastetext | selectall | searchreplace' },
                            view: { title: 'View', items: 'visualaid visualchars visualblocks | spellchecker  | showcomments' },
                            insert: { title: 'Insert', items: 'image link  addcomment pageembed codesample inserttable | math | charmap emoticons hr' },
                            format: { title: 'Format', items: 'underline strikethrough superscript subscript codeformat | styles blocks fontsize align lineheight | forecolor backcolor | language | removeformat' },
                            tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
                            table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
                        },
                        language: 'es',
                        branding: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table', 'anchor'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | removeformat',
                        statusbar: false,
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
                <Button
                disabled={formData.general.estatus === "CANCELADO" || formData.oficio.estatusAcuse !== "CREADO" } 
                 type="submit" variant="contained" className="button-save" onClick={() => setModalOpenPublicarOficio(true)} sx={{ mt: 2 }}>
                    <Typography variant="button">Publicar</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}


export default Oficio;