import axios from 'axios';

export const fetchInstituciones = async (token, page = 0, size = 10, sort = '', nombre = '') => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/instituciones`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
        nombre
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al listar Instituciones:', error);
    throw error;
  }
};

export const fetchInstitucionesAutoComplete = async (token, page = 0, size = 10,  nombre = '') => {
  
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/instituciones/autocomplete`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        nombre
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al listar Instituciones:', error);
    throw error;
  }
};

export const create = async (token, data) => {

  try {
    const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/core/instituciones`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al agregar el registro:', error);
    throw error;
  }
};

export const update = async (token, data) => {
  try {
    const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/core/instituciones`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error al actualizar el registro:', error);
    throw error;
  }
};

export const remove = async (token, id) => {
  try {
    const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/core/instituciones/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al eliminar el registro:', error);
    throw error;
  }
};


export const fetchById = async (token, id) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/instituciones/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener el registro:', error);
    throw error;
  }
};